import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const InitialPage = () => {
  const { t } = useTranslation();

  return (
    <Stack>
      <Typography variant="h1" mb={3}>
        {t('initial.title')}
      </Typography>
      <Typography variant="body1" mb={2}>
        {t('initial.text')}
      </Typography>
      <Typography variant="body1" mb={2}>
        {t('initial.text2')}
      </Typography>
      <Typography variant="body1" mb={2}>
        {t('initial.text3')}
      </Typography>
      <Typography variant="body1" mb={2}>
        {t('initial.text4')}
      </Typography>
      <Typography variant="body1" mb={2}>
        {t('initial.text5')}
      </Typography>
    </Stack>
  );
};
