import isEmpty from 'lodash/isEmpty';

import { VictimInfoKeys } from '@bvi/api-interfaces/entity/case-property';
import { AdditionalFilterParameter } from '@bvi/api-interfaces/request/case';

import { ISearchFormDataSchema } from './types';

export const buildAgeCategoryQuery = (
  searchParameters: Record<keyof ISearchFormDataSchema, string>,
) => {
  const ageCategory =
    searchParameters[VictimInfoKeys.AGE_CATEGORY]?.split(',') ?? [];
  const refineAgeCategory =
    searchParameters[AdditionalFilterParameter.REFINE_AGE_CATEGORY]?.split(
      ',',
    ) ?? [];
  const ageCategorySummary = [...ageCategory, ...refineAgeCategory];
  const ageCategoryQuery = isEmpty(ageCategorySummary)
    ? null
    : ageCategorySummary.join(',');

  return {
    [AdditionalFilterParameter.REFINE_AGE_CATEGORY]: null,
    [VictimInfoKeys.AGE_CATEGORY]: ageCategoryQuery,
  };
};
