import { PropertyKey } from '@bvi/api-interfaces/entity/case-property';
import {
  Autocomplete,
  IAutocompleteProperties,
  IAutocompleteValue,
} from '@bvi/common-components';

import { useCasesFormContext } from '../../../lib/context';

export interface ICasesFormAutocompleteProperties<
  Multiple extends boolean | undefined,
> extends Omit<
    IAutocompleteProperties<IAutocompleteValue, Multiple>,
    'onOptionsLoad'
  > {
  field: PropertyKey;
}

export function CasesFormAutocomplete<
  Multiple extends boolean | undefined = false,
>(properties: ICasesFormAutocompleteProperties<Multiple>): JSX.Element {
  const { field, placeholder } = properties;
  const { onLoadMoreSettings } = useCasesFormContext();

  return (
    <Autocomplete
      {...properties}
      onOptionsLoad={async (data) => {
        return await onLoadMoreSettings(field, data);
      }}
      placeholder={placeholder ?? ''}
    />
  );
}
