import { Tooltip, Typography } from '@mui/material';
import { FC } from 'react';

import { IOverflowingTextProperties } from './types';

export const OverflowingText: FC<IOverflowingTextProperties> = ({
  text,
  ...properties
}) => {
  return (
    <Tooltip title={text} enterDelay={1000}>
      <Typography
        {...properties}
        overflow="hidden"
        textOverflow="ellipsis"
        width="fit-content"
        maxWidth="100%"
      >
        {text}
      </Typography>
    </Tooltip>
  );
};
