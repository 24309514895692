import { ComponentsOverrides, ComponentsProps } from '@mui/material';
import { Theme } from '@mui/system';

import { palette } from '../constants';

interface IMuiCheckboxType {
  defaultProps: ComponentsProps['MuiCheckbox'];
  styleOverrides: ComponentsOverrides<Theme>['MuiCheckbox'];
}

export const MuiCheckbox = {
  styleOverrides: {
    root: {
      '& svg': {
        fill: palette.grey[200],
      },
      '&.Mui-checked': {
        '& svg': {
          fill: palette.primary.main,
        },
      },
    },
  },
  defaultProps: {
    disableRipple: true,
  },
} satisfies IMuiCheckboxType;
