import { ComponentsOverrides, ComponentsProps } from '@mui/material';
import { Theme } from '@mui/system';

interface IMuiSelectType {
  defaultProps: ComponentsProps['MuiSelect'];
  styleOverrides: ComponentsOverrides<Theme>['MuiSelect'];
}

export const MuiSelect = {
  styleOverrides: {
    select: {
      border: '1px solid transparent',
      margin: '0',
    },
  },
  defaultProps: {},
} satisfies IMuiSelectType;
