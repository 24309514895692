import { useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';

import { isCurrentUserActive } from '@bvi/dashboard/entities/user-auth/selectors';
import {
  CasesPage,
  SearchPage,
  CreateCasePage,
  EditCasePage,
  SavedSearchesPage,
  IndexPage,
  CasesDetailsPage,
  SavedSearchDetailsPage,
  InitialPage,
} from '@bvi/dashboard/pages';
import { DashboardRoutesPaths } from '@bvi/dashboard/shared/constants/routes';
import { DashboardLayout } from '@bvi/dashboard/widgets/layouts';

export const DashboardRoutes = () => {
  const isUserActive = useSelector(isCurrentUserActive());

  if (!isUserActive) {
    return (
      <DashboardLayout>
        <Routes>
          <Route path={DashboardRoutesPaths.INDEX} element={<InitialPage />} />
          <Route
            path="*"
            element={<Navigate to={DashboardRoutesPaths.INDEX} replace />}
          />
        </Routes>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <Routes>
        <Route path={DashboardRoutesPaths.INDEX} element={<IndexPage />} />
        <Route path={DashboardRoutesPaths.SEARCH} element={<SearchPage />} />
        <Route
          path={DashboardRoutesPaths.SAVED_SEARCHES}
          element={<SavedSearchesPage />}
        />
        <Route
          path={DashboardRoutesPaths.SAVED_SEARCH_DETAILS}
          element={<SavedSearchDetailsPage />}
        />
        <Route path={DashboardRoutesPaths.CASE} element={<CasesPage />} />
        <Route
          path={DashboardRoutesPaths.CASES_DETAILS}
          element={<CasesDetailsPage />}
        />
        <Route
          path={DashboardRoutesPaths.CASES_CREATE}
          element={<CreateCasePage />}
        />
        <Route
          path={DashboardRoutesPaths.PROFILE}
          element={<div>PROFILE</div>}
        />
        <Route
          path={DashboardRoutesPaths.CASES_EDIT}
          element={<EditCasePage />}
        />
        <Route
          path="*"
          element={<Navigate to={DashboardRoutesPaths.INDEX} replace />}
        />
      </Routes>
    </DashboardLayout>
  );
};
