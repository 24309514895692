import {
  ICreateSavedSearchEntryRequest,
  IGetSavedSearchRequest,
} from '@bvi/api-interfaces/request/case';
import {
  ISavedSearchEntryResponse,
  ISavedSearchListResponse,
  ISavedSearchResponse,
} from '@bvi/api-interfaces/response/case';
import { routes } from '@bvi/dashboard/api-routes';
import {
  IFilterSearchesQuery,
  ISaveSearchBody,
} from '@bvi/dashboard/feature/saved-searches/lib/schema';
import { apiContainer, TagTypes } from '@bvi/dashboard/shared/api';
import {
  invalidateTagsList,
  provideTagsList,
} from '@bvi/dashboard/shared/api/tag-types';
import { WithId } from '@bvi/dashboard/shared/api/types';

export const savedSearchesApi = apiContainer.injectEndpoints({
  endpoints: (builder) => ({
    getSavedSearches: builder.query<
      ISavedSearchListResponse,
      IFilterSearchesQuery
    >({
      query: (parameters) => ({
        url: routes.savedSearches.find(),
        method: 'GET',
        params: parameters,
      }),
      providesTags: provideTagsList(TagTypes.SAVED_SEARCHES),
    }),
    getSavedSearch: builder.query<ISavedSearchResponse, IGetSavedSearchRequest>(
      {
        query: ({ id }) => ({
          url: routes.savedSearches.findById(id),
          method: 'GET',
        }),
      },
    ),
    createSavedSearch: builder.mutation<ISavedSearchResponse, ISaveSearchBody>({
      query: (data) => ({
        url: routes.savedSearches.create(),
        method: 'POST',
        data,
      }),
      invalidatesTags: invalidateTagsList(TagTypes.SAVED_SEARCHES),
    }),
    createSavedSearchEntry: builder.mutation<
      ISavedSearchEntryResponse,
      WithId<ICreateSavedSearchEntryRequest>
    >({
      query: ([id, data]) => ({
        url: routes.savedSearches.createEntry(id),
        method: 'POST',
        data,
      }),
      invalidatesTags: invalidateTagsList(TagTypes.SAVED_SEARCHES),
    }),
    deleteSavedSearch: builder.mutation<void, number>({
      query: (id) => ({
        url: routes.savedSearches.delete(id),
        method: 'DELETE',
      }),
      invalidatesTags: invalidateTagsList(TagTypes.SAVED_SEARCHES),
    }),
    deleteSavedSearchEntry: builder.mutation<
      void,
      { searchId: number; entryId: number }
    >({
      query: ({ searchId, entryId }) => ({
        url: routes.savedSearches.deleteEntry(searchId, entryId),
        method: 'DELETE',
      }),
      invalidatesTags: invalidateTagsList(TagTypes.SAVED_SEARCHES),
    }),
  }),
});

export const {
  useGetSavedSearchesQuery,
  useGetSavedSearchQuery,
  useCreateSavedSearchMutation,
  useCreateSavedSearchEntryMutation,
  useDeleteSavedSearchMutation,
  useDeleteSavedSearchEntryMutation,
} = savedSearchesApi;
