import { IGetSettingsQuery } from '@bvi/cases-search';

export const parametersBuilder = (data: IGetSettingsQuery) => {
  const { includes, pagination } = data;

  return {
    includes: includes.join(','),
    ...includes.reduce((accumulator, field) => {
      return {
        ...accumulator,
        ...(pagination
          ? {
              [field]: {
                ...pagination[field],
              },
            }
          : {}),
      };
    }, {}),
  };
};
