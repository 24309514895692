import { stringify } from 'qs';
import { useNavigate } from 'react-router-dom';

export const useNavigation = () => {
  const navigate = useNavigate();

  return (path: string, parameters?: object) =>
    navigate({
      pathname: path,
      ...(parameters && {
        search: stringify(parameters, {
          arrayFormat: 'comma',
          skipNulls: true,
        }),
      }),
    });
};
