import { theme } from '@bvi/mui-theme';

export const styles = {
  wrapper: {
    width: '400px',
  },
  check: {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    border: `2px solid ${theme.palette.primary.main}`,
    p: 3,
  },
  text: {
    textAlign: 'center',
    display: 'block',
    my: 1,
  },
  button: {
    my: 3,
  },
  email: {
    fontWeight: '700',
    textDecoration: 'none',
    display: 'block',
    ml: 1,
  },
};
