import { ComponentsOverrides, ComponentsProps } from '@mui/material';

import { palette } from '../constants';

interface IMuiFilledInput {
  defaultProps: ComponentsProps['MuiFilledInput'];
  styleOverrides: ComponentsOverrides['MuiFilledInput'];
}

export const MuiFilledInput = {
  styleOverrides: {
    root: {
      backgroundColor: palette.grey[50],
      border: `1px solid ${palette.grey[100]}`,
      borderRadius: '8px',
      marginTop: 8,
      padding: '0',
      '&:hover': {
        borderColor: palette.grey[300],
        backgroundColor: palette.grey[50],
      },
      '&.Mui-focused': {
        borderColor: palette.primary.main,
        backgroundColor: palette.grey[50],
      },
      '&.Mui-error': {
        borderColor: palette.error.main,
        backgroundColor: palette.grey[50],
        border: `1px solid ${palette.error.main}`,
      },
      '&:before, &:after': {
        display: 'none',
      },
      '&.Mui-disabled': {
        pointerEvents: 'none',
      },
    },
    input: {
      padding: '6px',
      border: '1px solid transparent',
      '&:focus': {
        border: '1px solid transparent',
      },
    },
  },
  defaultProps: {},
} satisfies IMuiFilledInput;
