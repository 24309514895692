import { Box, Link } from '@mui/material';
import msx from 'mui-sx';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { SxProperties } from '@bvi/mui-theme';

import { Logo } from '../Logo';

import { ProfileNav } from './ProfileNav';
import { styles } from './styles';

export interface IHeaderItem {
  label: string;
  path: string;
}
export interface INavItem {
  label: string;
  action: () => void;
}

export interface IHeaderProperties {
  items: Array<IHeaderItem>;
  navItems: Array<INavItem>;
  userName: string;
  sx?: SxProperties;
}

const getPathWithoutSearchParameters = (path: string) => {
  const hasSearchParameters = path.includes('?');
  if (!hasSearchParameters) {
    return path;
  }

  return path.slice(0, path.indexOf('?'));
};

export const Header: FC<IHeaderProperties> = (properties) => {
  const { items, navItems, userName, sx = {} } = properties;

  const location = useLocation();

  const path = getPathWithoutSearchParameters(location.pathname);

  const isActive = (item: IHeaderItem) =>
    path === getPathWithoutSearchParameters(item.path);

  return (
    <Box component="header" sx={msx(styles.container, sx)}>
      <Logo variant="white" />
      {items.map((item) => (
        <Link
          color="common.white"
          key={item.label}
          href={item.path}
          underline="none"
          sx={styles.item}
          fontWeight={isActive(item) ? 'bold' : 'normal'}
        >
          {item.label}
        </Link>
      ))}
      <ProfileNav items={navItems} userName={userName} />
    </Box>
  );
};
