import { Grid, Stack } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

import { Image } from '@bvi/common-components';
import loginFormBackground from '@bvi/dashboard/assets/loginFormBackground.jpg';

import { styles } from './styles';

export const AuthLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Grid container>
      <Grid item xs={6}>
        <Image src={loginFormBackground} sx={styles.backgroundImage} />
      </Grid>
      <Grid item xs={6}>
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          height="100vh"
        >
          {children}
        </Stack>
      </Grid>
    </Grid>
  );
};
