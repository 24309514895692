import { ButtonProps } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { ISavedSearchData } from '@bvi/api-interfaces/response/case';
import { ConfirmActionButton } from '@bvi/common-components';
import { useCreateSavedSearchEntryMutation } from '@bvi/dashboard/entities/saved-searches/api-slice';
import { selectSavedSearchEntryFromMutationResult } from '@bvi/dashboard/entities/saved-searches/selectors';
import { DashboardRoutesPaths } from '@bvi/dashboard/shared/constants/routes';
import { useNavigation } from '@bvi/navigation-utils';

export interface IRepeatSearchButtonParameters
  extends Omit<ButtonProps, 'onClick'> {
  savedSearch?: ISavedSearchData;
}

export const RepeatSearchButton: FC<IRepeatSearchButtonParameters> = ({
  savedSearch,
  disabled,
  ...properties
}) => {
  const { t } = useTranslation();

  const navigate = useNavigation();

  const [createSavedSearchEntry] = useCreateSavedSearchEntryMutation();
  const handleRepeatSearch = async () => {
    if (!savedSearch) {
      return;
    }

    const response = await createSavedSearchEntry([
      savedSearch.id,
      { savedSearchId: savedSearch.id },
    ]);
    const newSavedSearch = selectSavedSearchEntryFromMutationResult(response);
    navigate(
      generatePath(DashboardRoutesPaths.SAVED_SEARCH_DETAILS, {
        savedSearchId: String(savedSearch.id),
        entryId: String(newSavedSearch.id),
      }),
    );
  };

  const isDisabled = Boolean(disabled) || !savedSearch;

  return (
    <ConfirmActionButton
      question={t('savedSearches.buttons.repeatSearch.question')}
      confirm={t('dialog.confirm')}
      cancel={t('dialog.cancel')}
      onClick={handleRepeatSearch}
      disabled={isDisabled}
      {...properties}
    />
  );
};
