import { useSnackbar, SnackbarAction } from 'notistack';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export enum NotistackMessageVariants {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  DEFAULT = 'default',
}

interface IErrorMessage {
  type: string;
  payload?: Record<string, string>;
}

export const useNotistack = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const showNotificationMessage = useCallback(
    (
      message: IErrorMessage,
      variant: NotistackMessageVariants = NotistackMessageVariants.ERROR,
      action?: SnackbarAction,
    ) => {
      const { type, payload } = message;
      const translation = t(`error.api.${type}`, { ...payload });

      enqueueSnackbar(translation, {
        variant,
        action,
        persist: action ? true : false,
      });
    },
    [enqueueSnackbar, t],
  );

  return {
    showNotificationMessage,
    closeSnackbar,
  };
};
