import { ComponentsOverrides, ComponentsProps } from '@mui/material';

import { palette } from '../constants';

interface IMuiFormLabel {
  defaultProps: ComponentsProps['MuiFormLabel'];
  styleOverrides: ComponentsOverrides['MuiFormLabel'];
}

export const MuiFormLabel = {
  defaultProps: {},
  styleOverrides: {
    root: {
      color: palette.common.black,
      fontSize: '16px',
      fontWeight: '300',
    },
  },
} satisfies IMuiFormLabel;
