import { Button, Paper, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DashboardRoutesPaths } from '@bvi/dashboard/shared/constants/routes';
import { useNavigation } from '@bvi/navigation-utils';

import { styles } from './styles';

export const EmptyCasesBar = () => {
  const { t } = useTranslation();

  const navigate = useNavigation();

  const handleCreateCase = () => {
    navigate(DashboardRoutesPaths.CASES_CREATE);
  };

  return (
    <Paper>
      <Stack
        justifyContent="space-between"
        direction="row"
        alignItems="center"
        sx={styles.container}
      >
        <Stack>
          <Typography variant="h3" sx={styles.title}>
            {t('cases.list.empty.title')}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {t('cases.list.empty.description')}
          </Typography>
        </Stack>
        <Button variant="contained" onClick={handleCreateCase}>
          {t('cases.list.create')}
        </Button>
      </Stack>
    </Paper>
  );
};
