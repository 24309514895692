import { Link, Breadcrumbs as MuiBreadcrumbs, Typography } from '@mui/material';
import dropRight from 'lodash/dropRight';

import { styles } from './styles';

type BreadcrumbsProperties = {
  items: Array<{
    link?: string;
    label: string;
  }>;
};

export const Breadcrumbs: React.FC<BreadcrumbsProperties> = (properties) => {
  const { items } = properties;

  const lastElement = items.at(-1);
  const otherItems = dropRight(items, 1);

  return (
    <MuiBreadcrumbs separator="|" sx={styles.wrapper}>
      {otherItems.map((item) =>
        item.link ? (
          <Link underline="hover" href={item.link} key={item.label}>
            {item.label}
          </Link>
        ) : (
          <Typography>{item.label}</Typography>
        ),
      )}
      <Typography>{lastElement?.label}</Typography>
    </MuiBreadcrumbs>
  );
};
