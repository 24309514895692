import { theme } from '@bvi/mui-theme';

export const styles = {
  savedSearchRow: {
    backgroundColor: theme.palette.secondary.light,
  },
  updateAvailableChip: {
    fontWeight: 'bold',
    borderRadius: '4px',
  },
  buttonCell: {
    py: 0.5,
  },
};
