import { zodResolver } from '@hookform/resolvers/zod';
import { Typography, Button, TextField, Stack, Link } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { isServerError } from '@bvi/axios-query';
import { Div, Form, Logo, PasswordTextField } from '@bvi/common-components';
import { useLoginMutation } from '@bvi/dashboard/entities/user-auth/api-slice';
import {
  AuthRoutesPaths,
  DashboardRoutesPaths,
} from '@bvi/dashboard/shared/constants/routes';
import { useNavigation } from '@bvi/navigation-utils';
import { NotistackMessageVariants, useNotistack } from '@bvi/notistack';

import { buildValidationSchema } from './schema';
import { styles } from './styles';
import { ILoginFormData } from './types';

export const LoginForm = () => {
  const [loginRequest, { isLoading }] = useLoginMutation();
  const { t } = useTranslation();
  const navigate = useNavigation();
  const { showNotificationMessage } = useNotistack();

  const validationSchema = buildValidationSchema(t);
  const methods = useForm<ILoginFormData>({
    resolver: zodResolver(validationSchema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = async (data: ILoginFormData) => {
    try {
      await loginRequest(data).unwrap();
      navigate(DashboardRoutesPaths.SEARCH);
    } catch (error) {
      if (isServerError(error)) {
        showNotificationMessage(
          { type: error.type },
          NotistackMessageVariants.ERROR,
        );
      }
    }
  };

  return (
    <Stack direction="column" alignItems="center">
      <Logo />
      <Div sx={styles.formWrapper}>
        <Typography align="center" variant="h1" sx={styles.title}>
          {t('auth.signIn.title')}
        </Typography>
        <FormProvider {...methods}>
          <Form noValidate onSubmit={handleSubmit(onSubmit)}>
            <TextField
              {...register('email')}
              variant="outlined"
              label={t('auth.signIn.form.email.label')}
              placeholder={t('auth.signIn.form.email.placeholder')}
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
              sx={styles.input}
              fullWidth
            />
            <PasswordTextField
              field="password"
              variant="outlined"
              label={t('auth.signIn.form.password.label')}
              placeholder={t('auth.signIn.form.password.placeholder')}
              helperText={errors.password?.message}
              sx={styles.input}
              fullWidth
            />
            <Button
              fullWidth
              variant="text"
              LinkComponent={Link}
              href={AuthRoutesPaths.RESET_PASSWORD}
              sx={styles.forgotPasswordButton}
            >
              {t('auth.forgotPassword')}
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={isLoading}
              fullWidth
            >
              {t('auth.signIn.form.submit')}
            </Button>
          </Form>
          <Stack alignItems="center" sx={styles.registerWrapper}>
            <Typography>
              {t('auth.callToSignUp')}
              <Link href={AuthRoutesPaths.SIGN_UP} sx={styles.registerNowLink}>
                {t('auth.registerNow')}
              </Link>
            </Typography>
          </Stack>
        </FormProvider>
      </Div>
    </Stack>
  );
};
