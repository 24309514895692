import { z } from 'zod';

export const getFormDefaults = <
  Schema extends z.AnyZodObject | z.ZodUnion<[z.AnyZodObject, z.AnyZodObject]>,
>(
  schema: Schema,
) => {
  if (!schema) {
    return;
  }

  let currentSchema = {};

  if ('shape' in schema) {
    currentSchema = schema.shape;
  }

  if ('options' in schema) {
    currentSchema = schema.options[0].shape;
  }

  return Object.fromEntries(
    Object.entries(currentSchema).map(([key, value]) => {
      if (value instanceof z.ZodDefault) {
        return [key, value._def.defaultValue()];
      }
      return [key, undefined];
    }),
  );
};
