import { theme } from '@bvi/mui-theme';

export const styles = {
  formWrapper: {
    width: '400px',
  },
  input: {
    my: 2,
  },
  title: {
    mt: 4,
    mb: 2,
    textAlign: 'center',
  },
  subtitle: {
    color: theme.palette.grey[300],
    textAlign: 'center',
  },
  submitButton: {
    my: 2,
  },
};
