import { TableCell, TableHead, TableRow } from '@mui/material';

import { styles } from '../styles';

type HeaderProperties = {
  cells: Array<{ label: string }>;
};

export const ResultsTableHeader: React.FC<HeaderProperties> = (properties) => {
  const { cells } = properties;

  return (
    <TableHead>
      <TableRow>
        {cells.map((cell) => (
          <TableCell key={cell.label} sx={styles.headerCell}>
            {cell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
