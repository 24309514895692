export const styles = {
  wrapper: {
    position: 'absolute',
    width: '1792px',
    left: '-9999px',
    top: '-9999px',
    padding: '20px',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: '90px',
    marginTop: '60px',
  },
  logo: {
    width: '280px',
    '& img': {
      width: '100%',
      display: 'block',
    },
  },
  title: {
    color: 'primary.main',
    fontWeight: '700',
    fontSize: '28px',
    margin: '0',
  },
  label: {
    margin: '0',
    fontSize: '15px',
    opacity: '0.6',
  },
  value: {
    margin: '0',
    fontWeight: '400',
    marginLeft: '8px',
  },
  notes: {
    fontSize: '15px',
    textAlign: 'justify',
    fontWeight: '300',
    padding: '20px',
    borderRadius: '5px',
    border: '2px solid rgba(229, 176, 69, 0.50)',
    background: 'rgba(255, 235, 194, 0.50)',
    lineHeight: '20px',
  },
  notesPlaceholder: {
    fontWeight: '700',
    fontStyle: 'italic',
    marginRight: '8px',
  },
  notesLink: {
    fontWeight: '400',
  },
};
