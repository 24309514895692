import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  AbuseDetailsKeys,
  AbuseDetailsLevel1VariantKeys,
  AbuseDetailsLevel2VariantKeys,
  AbuseDetailsLevel3VariantKeys,
  AbuseDetailsLevel4VariantKeys,
  AbuseDetailsLevel5VariantKeys,
  LegalDetailsKeys,
  StaticPropertyKey,
  YesNoVariantsKey,
} from '@bvi/api-interfaces/entity/case-property';
import { Div } from '@bvi/common-components';
import { useFormOptions } from '@bvi/form-utils';

import { ICaseFormData } from '../../../lib/types';
import { CasesFormAutocomplete } from '../form-autocomplete';
import { styles } from '../styles';

import { LevelItem } from './level-item';
import { RangeItem } from './range-item';

export const AbuseType = () => {
  const { t } = useTranslation('translation');

  const {
    register,
    formState: { errors },
  } = useFormContext<ICaseFormData>();

  const level1Checkboxes = useFormOptions(
    AbuseDetailsLevel1VariantKeys,
    'abuseTypeItems',
  );

  const level2Checkboxes = useFormOptions(
    AbuseDetailsLevel2VariantKeys,
    'abuseTypeItems',
  );
  const level3Checkboxes = useFormOptions(
    AbuseDetailsLevel3VariantKeys,
    'abuseTypeItems',
  );
  const level4Checkboxes = useFormOptions(
    AbuseDetailsLevel4VariantKeys,
    'abuseTypeItems',
  );
  const level5Checkboxes = useFormOptions(
    AbuseDetailsLevel5VariantKeys,
    'abuseTypeItems',
  );

  const numberOfIncidentsHasError =
    Boolean(errors[AbuseDetailsKeys.NUMBER_OF_INCIDENTS_LOW]) ||
    Boolean(errors[AbuseDetailsKeys.NUMBER_OF_INCIDENTS_HIGH]);
  const numberOfIncidentsHelperText =
    errors[AbuseDetailsKeys.NUMBER_OF_INCIDENTS_LOW]?.message ??
    errors[AbuseDetailsKeys.NUMBER_OF_INCIDENTS_HIGH]?.message;
  const numberOfPerpetratorsHasError =
    Boolean(errors[AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_LOW]) ||
    Boolean(errors[AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_HIGH]);
  const numberOfPerpetratorsHelperText =
    errors[AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_LOW]?.message ??
    errors[AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_HIGH]?.message;

  return (
    <Div>
      <Typography variant="body1" sx={styles.title}>
        {t('cases.form.typeOfAbuse.title')}
      </Typography>
      <Grid container spacing={2} my={1}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>{t('cases.form.typeOfAbuse.details.label')}</InputLabel>
            <Controller
              name={AbuseDetailsKeys.DETAIL_AVAILABLE}
              render={({ field }) => (
                <RadioGroup {...field} sx={styles.radioGroup} row>
                  <FormControlLabel
                    value={YesNoVariantsKey.YES}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={YesNoVariantsKey.NO}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Div>
        <Stack mt={3}>
          <InputLabel>{t('cases.form.typeOfAbuse.subtitle')}</InputLabel>
        </Stack>
        <LevelItem
          label={t('cases.form.typeOfAbuse.level1.label')}
          field={AbuseDetailsKeys.LEVEL_1}
          items={level1Checkboxes}
        />
        <LevelItem
          label={t('cases.form.typeOfAbuse.level2.label')}
          field={AbuseDetailsKeys.LEVEL_2}
          items={level2Checkboxes}
        />
        <LevelItem
          label={t('cases.form.typeOfAbuse.level3.label')}
          field={AbuseDetailsKeys.LEVEL_3}
          items={level3Checkboxes}
        />
        <LevelItem
          label={t('cases.form.typeOfAbuse.level4.label')}
          field={AbuseDetailsKeys.LEVEL_4}
          items={level4Checkboxes}
        />
        <LevelItem
          label={t('cases.form.typeOfAbuse.level5.label')}
          field={AbuseDetailsKeys.LEVEL_5}
          items={level5Checkboxes}
        />
        <Grid container spacing={2} my={1}>
          <Grid item xs={6}>
            <RangeItem
              fromField={AbuseDetailsKeys.NUMBER_OF_INCIDENTS_LOW}
              toField={AbuseDetailsKeys.NUMBER_OF_INCIDENTS_HIGH}
              label={t('cases.form.typeOfAbuse.numberOfIncidents.label')}
              error={numberOfIncidentsHasError}
              helperText={numberOfIncidentsHelperText}
            />
          </Grid>
          <Grid item xs={6}>
            <RangeItem
              fromField={AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_LOW}
              toField={AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_HIGH}
              label={t('cases.form.typeOfAbuse.numberOfPerpetrators.label')}
              error={numberOfPerpetratorsHasError}
              helperText={numberOfPerpetratorsHelperText}
            />
          </Grid>
        </Grid>
        <Grid mt={3} container>
          <Grid item xs={6}>
            <CasesFormAutocomplete
              name={StaticPropertyKey.DAMAGE}
              field={StaticPropertyKey.DAMAGE}
              variant="filled"
              label={t(
                'cases.form.caseInformation.damagesConsiderations.label',
              )}
              placeholder={t(
                'cases.form.caseInformation.damagesConsiderations.placeholder',
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} my={1}>
          <Grid item xs={12}>
            <TextField
              {...register(LegalDetailsKeys.DAMAGES_COMMENT)}
              variant="filled"
              fullWidth
              multiline
              minRows={3}
              label={t('cases.form.legalDetails.damagesComment.label')}
              error={Boolean(errors[LegalDetailsKeys.DAMAGES_COMMENT])}
              helperText={
                <>{errors[LegalDetailsKeys.DAMAGES_COMMENT]?.message}</>
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} my={1}>
          <Grid item xs={12}>
            <TextField
              {...register(LegalDetailsKeys.OTHER_LIFE_STRESS)}
              variant="filled"
              fullWidth
              multiline
              minRows={3}
              label={t('cases.form.legalDetails.otherLifeStress.label')}
              error={Boolean(errors[LegalDetailsKeys.OTHER_LIFE_STRESS])}
              helperText={
                <>{errors[LegalDetailsKeys.OTHER_LIFE_STRESS]?.message}</>
              }
            />
          </Grid>
        </Grid>
      </Div>
    </Div>
  );
};
