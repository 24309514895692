export const styles = {
  item: {
    display: 'inline',
    marginRight: '15px',
    textWrap: 'wrap',
  },
  label: {
    display: 'inline-block',
    color: 'common.black',
    marginRight: '8px',
    fontWeight: '700',
    opacity: '0.6',
    verticalAlign: 'top',
  },
  value: {
    color: 'common.black',
    fontWeight: '300',
    textWrap: 'wrap',
    verticalAlign: 'top',
  },
  title: {
    marginBottom: '8px',
  },
};
