import {
  Avatar,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import msx from 'mui-sx';
import { useState } from 'react';

import { Div, Image } from '../..';
import ChevronIcon from '../../icons/chevron.svg';
import { INavItem } from '../Header';

import { styles } from './styles';

type ProfileNavProperties = {
  items: Array<INavItem>;
  userName: string;
};

export const ProfileNav: React.FC<ProfileNavProperties> = (properties) => {
  const { items, userName } = properties;
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorElement);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  return (
    <Div sx={styles.wrapper}>
      <IconButton disableRipple onClick={handleClick}>
        <Avatar sx={styles.avatar} />
        <Typography sx={styles.name}>{userName}</Typography>
        <Image
          alt="logo"
          src={ChevronIcon}
          sx={msx(styles.icon, {
            condition: isOpen,
            sx: styles.iconOpen,
          })}
        />
      </IconButton>
      <Menu
        anchorEl={anchorElement}
        open={isOpen}
        onClick={handleClose}
        slotProps={{
          paper: { sx: styles.popover },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {items.map((item) => (
          <Div key={item.label} sx={styles.navItemWrapper}>
            <MenuItem onClick={item.action} sx={styles.navItem}>
              {item.label}
            </MenuItem>
            <Divider />
          </Div>
        ))}
      </Menu>
    </Div>
  );
};
