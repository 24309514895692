export const styles = {
  tabs: {
    borderBottom: 1,
    borderColor: 'divider',
    mb: 3,
  },
  tabPanel: {
    px: 0,
  },
};
