import { Join } from 'type-fest';
import * as z from 'zod';

import { PropertyKey } from '@bvi/api-interfaces/entity/case-property';
import {
  ICreateCaseRequest,
  IUpdateCaseRequest,
} from '@bvi/api-interfaces/request/case';
import { IPaginationOptions } from '@bvi/api-interfaces/response/base';
import { ICaseData } from '@bvi/api-interfaces/response/case';
import { ISettingsData } from '@bvi/api-interfaces/response/case-settings';
import { PathsToStringProperties } from '@bvi/api-interfaces/utils';
import { IAutocompleteValue } from '@bvi/common-components';

import { buildValidationSchema } from './schema';

type Schema = ReturnType<typeof buildValidationSchema>;

export type ICaseFormData = z.infer<Schema>;

export type ICaseFormFields = Array<
  Join<PathsToStringProperties<ICaseFormData>, '.'>
>;

export interface IOnSettingsLoadQuery {
  page: number;
  search?: string;
  ids?: Array<number>;
  country?: ICaseFormData['country'];
  region?: ICaseFormData['region'];
}

export interface IOnSettingsLoadResponse {
  data: {
    payload: {
      data?: Array<IAutocompleteValue>;
      meta: IPaginationOptions;
    };
  };
}

export type OnSettingsLoad = (
  entity: PropertyKey,
  query: IOnSettingsLoadQuery,
) => Promise<IOnSettingsLoadResponse>;

export type ICaseFormRequest = ICreateCaseRequest | IUpdateCaseRequest;
export interface ICasesFormProperties<
  T extends ICaseFormRequest = ICaseFormRequest,
> {
  case?: ICaseData;
  settings?: ISettingsData;
  hiddenFields?: ICaseFormFields;
  onLoadMoreSettings: OnSettingsLoad;
  onSubmitRequest: (data: T, shouldSearch?: boolean) => Promise<void>;
}

export enum SearchButtonRole {
  SEARCH = 'search',
}
