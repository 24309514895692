import { theme } from '@bvi/mui-theme';

export const styles = {
  cellLabel: {
    color: theme.palette.grey[300],
  },
  cellValue: {
    fontWeight: '700',
  },
};
