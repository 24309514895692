import { Stack, Typography } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import { parse } from 'qs';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { ISettingsData } from '@bvi/api-interfaces/response/case-settings';
import { Div } from '@bvi/common-components';

import {
  getTagListByFormData,
  ISearchFormDataSchema,
  ISearchQuery,
} from '../../../lib';
import { i18nInstance } from '../../../locales';

import { styles } from './styles';

type SearchCriteriaProperties = {
  data: ISettingsData;
  searchParameters?: ISearchQuery;
};

export const SearchCriteria: React.FC<SearchCriteriaProperties> = ({
  data,
  searchParameters,
}) => {
  const { t } = useTranslation('', {
    i18n: i18nInstance,
  });

  const location = useLocation();
  const currentSearchParameters =
    searchParameters ??
    (parse(location.search, {
      comma: true,
      ignoreQueryPrefix: true,
    }) as Record<keyof ISearchFormDataSchema, string>);
  const searchParametersWithoutPagination = omit(currentSearchParameters, [
    'limit',
    'page',
  ]);

  const isFilterEmpty = isEmpty(searchParametersWithoutPagination);
  const tags = getTagListByFormData(currentSearchParameters, data, t);

  return (
    <Div>
      {!isFilterEmpty && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h3" sx={styles.title}>
            {t('searchReport.filter.title')}
          </Typography>
        </Stack>
      )}
      <Div>
        {tags.map((tag) => {
          if (isNil(tag.value) || isEmpty(String(tag.value))) {
            return null;
          }

          const value = Array.isArray(tag.value)
            ? tag.value?.join(', ')
            : tag.value?.toString();

          return (
            <Div key={tag.key} sx={styles.item}>
              <Typography component="span" sx={styles.label}>
                {tag.label}:
              </Typography>
              <Typography component="span" sx={styles.value}>
                {value}
              </Typography>
            </Div>
          );
        })}
      </Div>
    </Div>
  );
};
