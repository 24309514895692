import { Button } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Div, PasswordTextField, EmailTextField } from '@bvi/common-components';
import { selectIsEmailAvailable } from '@bvi/dashboard/entities/user-auth/selectors';

import { styles } from '../styles';

export const CredentialsStep: React.FC = () => {
  const { t } = useTranslation();
  const {
    formState: { errors },
    watch,
  } = useFormContext();

  const email = watch('email');
  const isEmailAvailable = useSelector(selectIsEmailAvailable(email));

  return (
    <Div>
      <EmailTextField
        field="email"
        variant="outlined"
        label={t('auth.signUp.form.email.label')}
        placeholder={t('auth.signUp.form.email.placeholder')}
        unavailableMessage={t('auth.signUp.form.email.emailTaken')}
        isAvailable={isEmailAvailable}
        sx={styles.input}
        fullWidth
      />
      <PasswordTextField
        field="password"
        variant="outlined"
        label={t('auth.signUp.form.password.label')}
        placeholder={t('auth.signUp.form.password.placeholder')}
        helperText={<>{errors.password?.message}</>}
        sx={styles.input}
        fullWidth
      />
      <PasswordTextField
        field="confirmPassword"
        variant="outlined"
        label={t('auth.signUp.form.confirmPassword.label')}
        placeholder={t('auth.signUp.form.confirmPassword.placeholder')}
        helperText={<>{errors.confirmPassword?.message}</>}
        sx={styles.input}
        fullWidth
      />
      <Button type="submit" variant="outlined" fullWidth sx={styles.button}>
        {t('auth.signUp.form.nextStep')}
      </Button>
    </Div>
  );
};
