import { Table, TableFooter, TablePagination, TableRow } from '@mui/material';
import { FC } from 'react';

import { DEFAULT_ROWS_PER_PAGE } from './constants';
import { IPaginationTableProperties } from './types';

export const PaginationTable: FC<IPaginationTableProperties> = ({
  children,
  count,
  page,
  rowsPerPage,
  onPageChange,
  ...properties
}) => {
  const currentPage = !count || count <= 0 ? 0 : page - 1;
  const hasItems = count > 0;
  rowsPerPage = rowsPerPage ?? DEFAULT_ROWS_PER_PAGE;

  const _onPageChange = (_: unknown, page: number) => {
    onPageChange(page + 1);
  };

  return (
    <Table {...properties}>
      {children}
      {hasItems && (
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[rowsPerPage]}
              count={count}
              page={currentPage}
              onPageChange={_onPageChange}
              showFirstButton
              showLastButton
            />
          </TableRow>
        </TableFooter>
      )}
    </Table>
  );
};
