import { ComponentsOverrides, ComponentsProps } from '@mui/material';

interface IMuiTooltip {
  defaultProps: ComponentsProps['MuiTooltip'];
  styleOverrides: ComponentsOverrides['MuiTooltip'];
}

export const MuiTooltip = {
  defaultProps: {},
  styleOverrides: {
    tooltip: {
      maxWidth: 'none',
      fontSize: '14px',
    },
  },
} satisfies IMuiTooltip;
