export const styles = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  avatar: {
    width: '40px',
    height: '40px',
    marginRight: '8px',
  },
  name: {
    color: 'common.white',
    fontSize: '14px',
    fontWeight: '700',
  },
  icon: {
    marginLeft: '16px',
    transition: 'transform 0.3s ease',
  },
  iconOpen: {
    transform: 'rotate(180deg)',
  },
  navItemWrapper: {
    '&:last-child .MuiDivider-root': {
      display: 'none',
    },
  },
  navItem: {
    fontSize: '18px',
    fontWeight: '700',
    '&+.MuiDivider-root': {
      margin: '0',
    },
  },
  popover: {
    minWidth: '170px',
    padding: '0',
    margin: '0',
    '& .MuiList-root': {
      padding: '0',
    },
  },
};
