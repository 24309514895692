import { BaseResponse } from './base';

export const getResponseData = <T>(response?: BaseResponse<T>) => {
  if (response?.success) {
    return response.payload.data;
  }

  throw new Error('Unsuccessful response');
};

export const getResponseDataOrEmptyArray = <T>(response?: BaseResponse<T>) => {
  if (response?.success) {
    return response.payload.data;
  }

  return [];
};
