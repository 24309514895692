import { Button } from '@mui/material';
import { FC, useState } from 'react';

import { ConfirmActionModal } from '../';

import { IConfirmActionButtonProperties } from './types';

export const ConfirmActionButton: FC<IConfirmActionButtonProperties> = ({
  question,
  confirm,
  cancel,
  onClick,
  ...properties
}) => {
  const [isOpen, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    await onClick?.(event);
    handleClose();
  };

  return (
    <>
      <Button {...properties} onClick={handleOpen} />
      <ConfirmActionModal
        question={question}
        confirm={confirm}
        cancel={cancel}
        open={isOpen}
        onSubmit={handleSubmit}
        onClose={handleClose}
      />
    </>
  );
};

// eslint-disable-next-line no-duplicate-imports
export { type IConfirmActionButtonProperties } from './types';
