import { useTranslation } from 'react-i18next';

export const useFormOptions = <T extends string>(
  data: Record<string, T>,
  translationKey: string,
  descriptionKey?: string,
) => {
  const { t } = useTranslation('translation');

  return Object.values(data).map((id) => ({
    id,
    name: t(`${translationKey}.${id}`),
    description:
      descriptionKey && t(`${descriptionKey}.${id}`, { defaultValue: '' }),
  }));
};
