import { TFunction } from 'i18next';
import { z } from 'zod';

import { SignUpFormStep } from './types';

export const buildValidationSchema = (t: TFunction, step: SignUpFormStep) => {
  const credentialStepSchema = {
    email: z
      .string()
      .min(1, t('error.form.required'))
      .email(t('error.form.email'))
      .default(''),
    password: z
      .string()
      .min(1, t('error.form.required'))
      .min(
        8,
        t('error.form.short', {
          field: t('auth.signUp.form.password.label'),
          count: 8,
        }),
      )
      .default(''),
    confirmPassword: z
      .string()
      .min(1, t('error.form.required'))
      .min(
        8,
        t('error.form.short', {
          field: t('auth.signUp.form.password.label'),
          count: 8,
        }),
      )
      .default(''),
  };
  const personalStepSchema = {
    firstName: z
      .string()
      .min(1, t('error.form.required'))
      .max(
        64,
        t('error.form.long', {
          field: t('auth.signUp.form.firstName.label'),
          count: 64,
        }),
      )
      .default(''),
    lastName: z
      .string()
      .min(1, t('error.form.required'))
      .max(
        64,
        t('error.form.long', {
          field: t('auth.signUp.form.lastName.label'),
          count: 64,
        }),
      )
      .default(''),
    phone: z
      .string()
      .default('')
      .refine((value) => value.trim().length >= 15, {
        message: t('error.form.phone.invalid'),
      }),
  };
  const companyStepSchema = {
    companyName: z
      .string()
      .min(1, t('error.form.required'))
      .max(
        256,
        t('error.form.long', {
          field: t('auth.signUp.form.companyName.label'),
          count: 256,
        }),
      )
      .default(''),
    companySizeId: z.number(),
    role: z
      .string()
      .min(1, t('error.form.required'))
      .max(
        64,
        t('error.form.long', {
          field: t('auth.signUp.form.role.label'),
          count: 64,
        }),
      )
      .default(''),
  };

  const schemas = {
    [SignUpFormStep.credential]: z
      .object(credentialStepSchema)
      .refine((data) => data.password === data.confirmPassword, {
        message: t('error.form.password.mismatching'),
        path: ['confirmPassword'],
      }),
    [SignUpFormStep.personal]: z.object(personalStepSchema),
    [SignUpFormStep.company]: z.object(companyStepSchema),
  };

  return schemas[step];
};
