import { BaseQueryApi, BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import createAuthRefreshInterceptor, {
  AxiosAuthRefreshOptions,
} from 'axios-auth-refresh';
import axiosRetry from 'axios-retry';
import identity from 'lodash/identity';

import { IServerError } from './types/error';

export const DEFAULT_SERVER_ERROR = 'UNKNOWN_SERVER_ERROR';

interface IAxiosBaseQueryOptions {
  baseURL: string;
  prepareHeaders?: (
    headers: Record<string, string>,
    options: Pick<BaseQueryApi, 'getState'>,
  ) => Record<string, string>;
  refreshTokenOptions?: AxiosAuthRefreshOptions;
  refreshTokenLogic?: (error: AxiosError) => Promise<unknown>;
  onError?: (error: AxiosError) => void;
}

export const axiosBaseQuery = ({
  baseURL,
  refreshTokenLogic = () => Promise.resolve(),
  refreshTokenOptions = {},
  prepareHeaders = identity,
  onError = identity,
}: IAxiosBaseQueryOptions): BaseQueryFn<
  {
    url: string;
    method: AxiosRequestConfig['method'];
    data?: AxiosRequestConfig['data'];
    params?: AxiosRequestConfig['params'];
    headers?: AxiosRequestConfig['headers'];
  },
  unknown,
  IServerError
> => {
  const instance = axios.create({ baseURL, withCredentials: true });
  createAuthRefreshInterceptor(
    instance,
    refreshTokenLogic,
    refreshTokenOptions,
  );
  axiosRetry(instance, { retryDelay: axiosRetry.exponentialDelay });

  instance.interceptors.response.use(identity, (error: AxiosError) => {
    onError(error);
    return Promise.reject(error);
  });

  return async ({ url, method, data, params }, api) => {
    const { getState } = api;
    const headers = prepareHeaders({}, { getState });

    try {
      const response = await instance.request({
        url,
        method,
        data,
        headers,
        params,
      });

      return {
        data: response.data,
      };
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        return {
          error: error.response?.data.error,
        };
      }

      return {
        error: {
          type: DEFAULT_SERVER_ERROR,
        },
      };
    }
  };
};
