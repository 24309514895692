import omit from 'lodash/omit';

import {
  AbuseDetailsKeys,
  LegalDetailsKeys,
  LegalDetailsPreparatorVariantsKey,
  StaticPropertyKey,
  VictimInfoKeys,
  YesNoVariantsKey,
} from '@bvi/api-interfaces/entity/case-property';
import { AdditionalFilterParameter } from '@bvi/api-interfaces/request/case';

import { searchFormDefaultValues, ISearchFormDataSchema } from '..';

export const mapParametersToSearchFormData = (
  searchParameters?: Record<keyof ISearchFormDataSchema, string>,
): ISearchFormDataSchema => {
  if (!searchParameters) {
    return searchFormDefaultValues;
  }

  const preparator =
    searchParameters[LegalDetailsKeys.PREPARATOR] &&
    (searchParameters[LegalDetailsKeys.PREPARATOR] as string)
      .split(',')
      .includes(LegalDetailsPreparatorVariantsKey.DENIED_ABUSE)
      ? YesNoVariantsKey.YES
      : YesNoVariantsKey.NO;

  const getMultipleValues = (
    data: typeof searchParameters,
    field: keyof ISearchFormDataSchema,
  ) => {
    return data[field] ? data[field].split(',') : [];
  };

  const multipleFieldsKeys: Array<keyof ISearchFormDataSchema> = [
    StaticPropertyKey.COUNTRY,
    StaticPropertyKey.REGION,
    StaticPropertyKey.STATE,
    VictimInfoKeys.AGE_CATEGORY,
    AdditionalFilterParameter.REFINE_AGE_CATEGORY,
    VictimInfoKeys.GENDER,
    StaticPropertyKey.ORGANIZATION_TYPE,
    StaticPropertyKey.JOB_TITLE,
    StaticPropertyKey.ORDER,
    StaticPropertyKey.LIABILITY_DEFENSES,
    StaticPropertyKey.EXCEPTION,
    StaticPropertyKey.RESOLUTION_TYPE,
    AbuseDetailsKeys.LEVEL_1,
    AbuseDetailsKeys.LEVEL_2,
    AbuseDetailsKeys.LEVEL_3,
    AbuseDetailsKeys.LEVEL_4,
    AbuseDetailsKeys.LEVEL_5,
    AdditionalFilterParameter.RESOLUTION_PUBLICITY,
  ];

  const multipleFields = multipleFieldsKeys.reduce((accumulator, item) => {
    return {
      ...accumulator,
      [item]: getMultipleValues(searchParameters, item),
    };
  }, {});

  const ommitedSearchParameters = omit(searchParameters, multipleFieldsKeys);

  return {
    ...searchFormDefaultValues,
    ...ommitedSearchParameters,
    ...multipleFields,
    [AdditionalFilterParameter.RESOLUTION_DATE_LOW]: searchParameters[
      AdditionalFilterParameter.RESOLUTION_DATE_LOW
    ]
      ? Number(searchParameters[AdditionalFilterParameter.RESOLUTION_DATE_LOW])
      : null,
    [AdditionalFilterParameter.RESOLUTION_DATE_HIGH]: searchParameters[
      AdditionalFilterParameter.RESOLUTION_DATE_HIGH
    ]
      ? Number(searchParameters[AdditionalFilterParameter.RESOLUTION_DATE_HIGH])
      : null,
    [LegalDetailsKeys.PREPARATOR]: searchParameters[LegalDetailsKeys.PREPARATOR]
      ? preparator
      : null,
  };
};
