import { CssBaseline, ThemeProvider } from '@mui/material';

import { theme } from '@bvi/mui-theme';

export const withTheme = (component: () => React.ReactNode) => () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {component()}
    </ThemeProvider>
  );
};
