import { Grid, Stack, Typography } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import { useTranslation } from 'react-i18next';

import { PropertyKey } from '@bvi/api-interfaces/entity/case-property';
import { IUpdateCaseRequest } from '@bvi/api-interfaces/request/case';
import { IUserCaseWithCaseData } from '@bvi/api-interfaces/response/case';
import { isServerError } from '@bvi/axios-query';
import {
  CasesForm,
  IOnSettingsLoadQuery,
  OnSettingsLoad,
} from '@bvi/cases-form';
import { BackButton, Breadcrumbs } from '@bvi/common-components';
import { useUpdateCaseMutation } from '@bvi/dashboard/entities/case/api-slice';
import { useLazyGetSettingsQuery } from '@bvi/dashboard/entities/search/api-slice';
import { DashboardRoutesPaths } from '@bvi/dashboard/shared/constants/routes';
import { formatDate } from '@bvi/date-utils';
import { useNotistack, NotistackMessageVariants } from '@bvi/notistack';

import { useNavigateAfterSubmit } from '../../lib/navigate-after-submit';

export interface ICasesEditFormProperties {
  userCase: IUserCaseWithCaseData;
}

export const CasesEditForm = (properties: ICasesEditFormProperties) => {
  const { userCase } = properties;
  const navigate = useNavigateAfterSubmit();
  const { showNotificationMessage } = useNotistack();
  const [getSettings] = useLazyGetSettingsQuery();
  const [updateCaseRequest] = useUpdateCaseMutation();
  const { t } = useTranslation('');

  const onSubmitRequest = async (
    data: IUpdateCaseRequest,
    shouldSearch?: boolean,
  ) => {
    try {
      await updateCaseRequest([userCase.id, data]).unwrap();

      navigate(userCase.id, data, shouldSearch);
    } catch (error) {
      if (isServerError(error)) {
        showNotificationMessage(
          { type: error.type },
          NotistackMessageVariants.ERROR,
        );
      }
    }
  };

  const onLoadMoreSettings = async (
    entity: PropertyKey,
    query: IOnSettingsLoadQuery,
  ) => {
    const result = await getSettings({
      includes: [entity],
      pagination: {
        [entity]: {
          page: query.page,
          limit: 100,
          countryIds: query.country?.id,
          regionIds: query.region?.id,
          ids: query.ids?.join(','),
          ...(query.search && {
            names: query.search,
          }),
        },
      },
    });

    const data = result.data?.success ? result.data.payload.data : {};

    return {
      data: {
        payload: {
          data: data[entity]?.items ?? [],
          meta: data[entity]?.meta,
        },
      },
    };
  };

  const createdDate = formatDate(userCase.createdAt);
  const title =
    isNull(userCase.case?.name) || isEmpty(userCase.case?.name)
      ? createdDate
      : userCase.case.name;

  const breadcrumbs = [
    {
      label: t('cases.form.breadcrumbs.cases'),
      link: DashboardRoutesPaths.CASE,
    },
    {
      label: title,
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Breadcrumbs items={breadcrumbs} />
      </Grid>
      <Grid item xs={12} mb={3}>
        <Stack direction="row" alignItems="center">
          <BackButton />
          <Typography variant="h1" ml={1}>
            {title}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <CasesForm
          onSubmitRequest={onSubmitRequest}
          case={userCase.case}
          onLoadMoreSettings={onLoadMoreSettings as OnSettingsLoad}
        />
      </Grid>
    </Grid>
  );
};
