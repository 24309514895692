import { Stack } from '@mui/material';

import { Loader } from '@bvi/common-components';
import { useMeQuery } from '@bvi/dashboard/entities/user-auth/api-slice';
import { useMinTimeToShowLoader } from '@bvi/dashboard/shared/hooks/useMinTimeToShowLoader';

import { AuthRoutes } from './AuthRoutes';
import { DashboardRoutes } from './DashboardRoutes';

export const AppRoutes = () => {
  const { isLoading, isError } = useMeQuery();
  const shouldShowLoader = useMinTimeToShowLoader(isLoading, 300);

  if (shouldShowLoader) {
    return (
      <Stack justifyContent="center" height="100vh">
        <Loader />
      </Stack>
    );
  }

  if (isError) {
    return <AuthRoutes />;
  }

  return <DashboardRoutes />;
};
