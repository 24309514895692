import { Skeleton } from '@mui/material';
import {
  Chart as ChartJS,
  BarElement,
  Title,
  CategoryScale,
  Tooltip,
  Legend,
  LogarithmicScale,
} from 'chart.js';
import annotationPlugin, { AnnotationOptions } from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import isEmpty from 'lodash/isEmpty';
import { memo } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import { ICaseStatisticsData } from '@bvi/api-interfaces/response/case';
import { Div, DataPopover } from '@bvi/common-components';

import { IRequiredStatistics } from '../../lib';
import {
  hasNanStatistics,
  hasZeroStatistics,
} from '../../lib/statistics-guards';
import { i18nInstance } from '../../locales';

import {
  buildAnnotations,
  buildData,
  buildOptions,
  prepareStatisticsData,
} from './constants';
import { styles } from './styles';

interface IResultsChartProperties {
  data: ICaseStatisticsData;
  isLoading?: boolean;
  shouldSticky?: boolean;
}

ChartJS.register(
  LogarithmicScale,
  CategoryScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin,
);

const StatisticsBar: React.FC<{
  data: IRequiredStatistics;
  annotations: Array<AnnotationOptions>;
}> = (properties) => {
  const { data, annotations } = properties;
  const { t } = useTranslation('', {
    i18n: i18nInstance,
    keyPrefix: 'results.chart',
  });

  return (
    <Bar
      id="bars"
      plugins={[ChartDataLabels]}
      options={buildOptions(data, annotations)}
      data={buildData(data, t)}
    />
  );
};

export const ResultsChart: React.FC<IResultsChartProperties> = memo(
  (properties) => {
    const { data, isLoading, shouldSticky = false } = properties;

    const { t } = useTranslation('', {
      i18n: i18nInstance,
      keyPrefix: 'results.chart',
    });

    if (
      hasZeroStatistics(data) ||
      hasNanStatistics(data) ||
      (isEmpty(data) && !isLoading)
    ) {
      return null;
    }

    const updatedData = prepareStatisticsData(data);
    const annotations = buildAnnotations(updatedData);

    const wrapperStyles = shouldSticky ? styles.stickyWrapper : {};

    return (
      <Div sx={wrapperStyles}>
        <DataPopover title={t('title')}>
          <Div sx={styles.canvasWrapper}>
            {isLoading ? (
              <Skeleton sx={styles.canvasSkeleton} />
            ) : (
              <StatisticsBar data={data} annotations={annotations} />
            )}
          </Div>
        </DataPopover>
      </Div>
    );
  },
);
