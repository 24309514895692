import { LoadingButton } from '@mui/lab';
import { Button, Grid, Typography } from '@mui/material';
import { FC, useState } from 'react';

import { PaperModal } from '../';

import { IConfirmActionModalProperties } from './types';

export const ConfirmActionModal: FC<IConfirmActionModalProperties> = ({
  question,
  confirm,
  cancel,
  open,
  onClose,
  onSubmit,
}) => {
  const [isLoading, setLoading] = useState(false);

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      setLoading(true);
      await onSubmit?.(event);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PaperModal open={open} onClose={onClose}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ mb: 1 }}>
            {question}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <LoadingButton
            loading={isLoading}
            onClick={handleClick}
            variant="contained"
            fullWidth
            aria-label="confirm"
            autoFocus
          >
            {confirm}
          </LoadingButton>
        </Grid>
        <Grid item xs={6}>
          <Button
            disabled={isLoading}
            variant="outlined"
            fullWidth
            onClick={onClose}
            aria-label="cancel"
          >
            {cancel}
          </Button>
        </Grid>
      </Grid>
    </PaperModal>
  );
};
