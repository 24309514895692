import { Skeleton, TableCell, TableRow } from '@mui/material';

import { formatCurrency } from '../../../lib';
import { styles } from '../styles';

type RowProperties = {
  label: string;
  cells: Array<{ value: string | number | null }>;
  isLoading?: boolean;
};

const formatAmount = (value: string | number | null) => {
  const isUnavailable =
    value === null ||
    value === undefined ||
    value === '' ||
    Number.isNaN(value);

  return isUnavailable ? '—' : formatCurrency(value);
};

export const ResultRow: React.FC<RowProperties> = (properties) => {
  const { label, cells, isLoading } = properties;

  return (
    <TableRow>
      <TableCell component="th" scope="row" sx={styles.headerCell}>
        {label}
      </TableCell>
      {cells.map((cell, index) => (
        <TableCell
          data-testid={`results_${label}_${index}`}
          key={`${label}_${cell.value}_${index}`}
          sx={styles.valueCell}
        >
          {isLoading ? (
            <Skeleton key={`${label}_${cell.value}_${index}`} />
          ) : (
            formatAmount(cell.value)
          )}
        </TableCell>
      ))}
    </TableRow>
  );
};
