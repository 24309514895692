import { Grid, FormControlLabel, Checkbox } from '@mui/material';
import get from 'lodash/get';
import { useCallback } from 'react';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';

export interface IFormCheckboxesItem<T> {
  value: T;
  label: string;
}

export interface IFormCheckboxesProperties<T> {
  name: string;
  items: Array<IFormCheckboxesItem<T>>;
  gridSize?: number;
}

export const FormCheckboxes = <T extends string | number>(
  properties: IFormCheckboxesProperties<T>,
) => {
  const { name, items, gridSize } = properties;
  const { getValues, control } = useFormContext();

  const handleCheck = (checkedValue: T) => {
    const values = getValues();
    const checkedValues = get(values, name, []);

    return checkedValues?.includes(checkedValue)
      ? checkedValues?.filter((_value: T) => checkedValue !== _value)
      : [...(checkedValues ?? []), checkedValue];
  };

  const render = useCallback<ControllerProps['render']>(
    ({ field }) => {
      return (
        <Grid container>
          {items.map((item) => {
            return (
              <Grid item xs={gridSize} key={item.value}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => field.onChange(handleCheck(item.value))}
                      checked={field.value?.includes(item.value)}
                    />
                  }
                  label={item.label}
                  value={item.value}
                />
              </Grid>
            );
          })}
        </Grid>
      );
    },
    [name, items, gridSize],
  );

  return <Controller name={name} render={render} control={control} />;
};
