import { ComponentsOverrides, ComponentsProps } from '@mui/material';
import { Theme } from '@mui/system';

interface IMuiFormControlLabelType {
  defaultProps: ComponentsProps['MuiFormControlLabel'];
  styleOverrides: ComponentsOverrides<Theme>['MuiFormControlLabel'];
}

export const MuiFormControlLabel = {
  defaultProps: {},
  styleOverrides: {
    label: {
      fontWeight: 300,
    },
  },
} satisfies IMuiFormControlLabelType;
