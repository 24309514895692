import {
  IChangeUserCaseStatusRequest,
  ICreateCaseNoteRequest,
  ICreateUserCaseRequest,
  IGetCaseNotesRequest,
  IGetCaseRequest,
  IUpdateUserCaseRequest,
  IUserCasesQuery,
} from '@bvi/api-interfaces/request/case';
import {
  IUserCaseListResponse,
  IUserCaseNoteData,
  IUserCaseNotesDataResponse,
  IUserCaseWithCaseResponse,
} from '@bvi/api-interfaces/response/case';
import { routes } from '@bvi/dashboard/api-routes';
import { TagTypes, apiContainer } from '@bvi/dashboard/shared/api';
import {
  invalidateTagsList,
  provideTagsList,
} from '@bvi/dashboard/shared/api/tag-types';
import { WithId } from '@bvi/dashboard/shared/api/types';

export const casesApi = apiContainer.injectEndpoints({
  endpoints: (builder) => ({
    casesList: builder.query<IUserCaseListResponse, IUserCasesQuery>({
      query: ({ statuses, page, limit }) => ({
        url: routes.userCases.findAll(),
        params: {
          statuses: statuses?.join(','),
          page,
          limit,
        },
        method: 'GET',
      }),
      providesTags: provideTagsList(TagTypes.USER_CASES_LIST),
    }),
    changeStatus: builder.mutation<
      IUserCaseWithCaseResponse,
      WithId<IChangeUserCaseStatusRequest>
    >({
      query: ([id, data]) => ({
        url: routes.userCases.updateStatus(id),
        method: 'PATCH',
        data,
      }),
      invalidatesTags: invalidateTagsList(
        TagTypes.USER_CASES_LIST,
        TagTypes.USER_CASE,
      ),
    }),
    removeCase: builder.mutation<void, number>({
      query: (id: number) => ({
        url: routes.userCases.delete(id),
        method: 'DELETE',
      }),
      invalidatesTags: invalidateTagsList(TagTypes.USER_CASES_LIST),
    }),
    createCase: builder.mutation<
      IUserCaseWithCaseResponse,
      ICreateUserCaseRequest
    >({
      query: (data) => ({
        url: routes.userCases.create(),
        method: 'POST',
        data,
      }),
      invalidatesTags: invalidateTagsList(TagTypes.USER_CASES_LIST),
    }),
    updateCase: builder.mutation<
      IUserCaseWithCaseResponse,
      WithId<IUpdateUserCaseRequest>
    >({
      query: ([id, data]) => ({
        url: routes.userCases.update(id),
        method: 'PATCH',
        data,
      }),
      invalidatesTags: invalidateTagsList(
        TagTypes.USER_CASES_LIST,
        TagTypes.USER_CASE,
      ),
    }),
    userCase: builder.query<IUserCaseWithCaseResponse, IGetCaseRequest>({
      query: ({ id }) => ({
        url: routes.userCases.findOne(id),
        method: 'GET',
      }),
      providesTags: provideTagsList(TagTypes.USER_CASE),
    }),
    getCaseNotes: builder.query<
      IUserCaseNotesDataResponse,
      IGetCaseNotesRequest
    >({
      query: ({ id }) => ({
        url: routes.userCases.getNotes(id),
        method: 'GET',
      }),
      providesTags: provideTagsList(TagTypes.CASE_NOTES),
    }),
    createCaseNote: builder.mutation<IUserCaseNoteData, ICreateCaseNoteRequest>(
      {
        query: ({ id, data }) => {
          return {
            url: routes.userCases.createNote(id),
            method: 'POST',
            data,
          };
        },
        invalidatesTags: invalidateTagsList(TagTypes.CASE_NOTES),
      },
    ),
  }),
});

export const {
  useCasesListQuery,
  useLazyCasesListQuery,
  useChangeStatusMutation,
  useRemoveCaseMutation,
  useCreateCaseMutation,
  useUpdateCaseMutation,
  useUserCaseQuery,
  useGetCaseNotesQuery,
  useCreateCaseNoteMutation,
} = casesApi;
