import { FormLabel, Stack } from '@mui/material';

import { Div, FormCheckboxes } from '@bvi/common-components';

import { styles } from './styles';

export interface ILevelItemProperties {
  field: string;
  label: string;
  items: Array<{ id: string; name: string }>;
}

export const LevelItem: React.FC<ILevelItemProperties> = (properties) => {
  const { field, label, items } = properties;

  const _items = items.map((item) => ({ value: item.id, label: item.name }));

  return (
    <Div>
      <Stack direction="row" alignItems="center" my={1}>
        <FormLabel sx={styles.levelLabel}>{label}</FormLabel>
      </Stack>
      <FormCheckboxes name={field} items={_items} gridSize={6} />
    </Div>
  );
};
