import {
  IResetPasswordRequest,
  ISendResetPasswordEmailRequest,
  ISignInRequest,
} from '@bvi/api-interfaces/request/auth';
import { ICreateUserRequest } from '@bvi/api-interfaces/request/user';
import { IAdminResponse } from '@bvi/api-interfaces/response/admin';
import {
  IResetPasswordResponse,
  ISentResetPasswordEmailResponse,
} from '@bvi/api-interfaces/response/auth';
import {
  IIsEmailAvailableResponse,
  IUserMeResponse,
} from '@bvi/api-interfaces/response/user';
import { routes } from '@bvi/dashboard/api-routes';
import { apiContainer, TagTypes } from '@bvi/dashboard/shared/api';

export const authApi = apiContainer.injectEndpoints({
  endpoints: (builder) => ({
    me: builder.query<IUserMeResponse, void>({
      query: () => ({
        url: routes.users.me(),
        method: 'GET',
      }),
      providesTags: [TagTypes.CURRENT_USER],
    }),
    login: builder.mutation<IAdminResponse, ISignInRequest>({
      query: (data) => ({
        url: routes.usersAuth.signIn(),
        method: 'POST',
        data,
      }),
      invalidatesTags: (_result, error) =>
        error ? [] : [TagTypes.CURRENT_USER],
    }),
    signUp: builder.mutation<IAdminResponse, ICreateUserRequest>({
      query: (data) => ({
        url: routes.usersAuth.signUp(),
        method: 'POST',
        data,
      }),
    }),
    signOut: builder.mutation<unknown, void>({
      query: () => ({
        url: routes.usersAuth.signOut(),
        method: 'POST',
      }),
      invalidatesTags: (_result, error) =>
        error ? [] : [TagTypes.CURRENT_USER],
    }),
    sendResetPasswordEmail: builder.mutation<
      ISentResetPasswordEmailResponse,
      ISendResetPasswordEmailRequest
    >({
      query: (data) => ({
        url: routes.usersAuth.sendResetPasswordEmail(),
        method: 'POST',
        data,
      }),
    }),
    resetPassword: builder.mutation<
      IResetPasswordResponse,
      IResetPasswordRequest
    >({
      query: (data) => ({
        url: routes.usersAuth.resetPassword(),
        method: 'POST',
        data,
      }),
    }),
    checkEmail: builder.query<IIsEmailAvailableResponse, string>({
      query: (email) => ({
        url: routes.usersAuth.isEmailAvailable(),
        method: 'GET',
        params: { email },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useSignUpMutation,
  useMeQuery,
  useCheckEmailQuery,
  useLazyCheckEmailQuery,
  useSendResetPasswordEmailMutation,
  useResetPasswordMutation,
  useSignOutMutation,
} = authApi;
