import dayjs from 'dayjs';

import { DateFormat, FormatDateResult } from '../types';

export const formatDate = <T extends string | number | Date | null | undefined>(
  date: T,
  format: DateFormat = DateFormat.default,
) => {
  if (date === null || date === undefined) {
    return undefined as FormatDateResult<T>;
  }

  return dayjs(date).format(format) as FormatDateResult<T>;
};
