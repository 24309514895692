import isEmpty from 'lodash/isEmpty';
import { FC, useEffect } from 'react';

import {
  buildParametersForSettings,
  ISearchQuery,
  SearchCriteria,
} from '@bvi/cases-search';
import { Div } from '@bvi/common-components';
import { useLazyGetSettingsQuery } from '@bvi/dashboard/entities/search/api-slice';
import { selectSettings } from '@bvi/dashboard/entities/search/selectors';
import { styles } from '@bvi/dashboard/feature/saved-searches/ui/saved-search-tags/styles';

export interface ISavedSearchFilterParameters {
  searchParameters: ISearchQuery;
}

export const SavedSearchTags: FC<ISavedSearchFilterParameters> = ({
  searchParameters,
}) => {
  const [getSettings, { data: settings }] = useLazyGetSettingsQuery();

  useEffect(() => {
    const settingsParameters = buildParametersForSettings(searchParameters);
    if (isEmpty(settingsParameters.includes)) {
      return;
    }

    getSettings(settingsParameters);
  }, []);

  const settingsData = selectSettings(settings);

  return (
    <Div sx={styles.filter}>
      <SearchCriteria data={settingsData} searchParameters={searchParameters} />
    </Div>
  );
};
