import { Typography } from '@mui/material';
import { Fragment } from 'react';

import { styles } from './styles';

type HightlightProperties = {
  filter: string;
  value: string;
};

export function Hightlight(properties: HightlightProperties): JSX.Element {
  const { filter, value } = properties;

  if (!filter) {
    return <>{value}</>;
  }

  const regexp = new RegExp(filter, 'ig');
  const matchValue = value.match(regexp);

  const highlightedString = value
    .split(regexp)
    .map((valueItem: string, index: number, array: Array<string>) => {
      const key = `${index}-${valueItem}`;

      if (index < array.length - 1) {
        const matchedValue = matchValue?.shift();

        return (
          <Fragment key={key}>
            {valueItem}
            <Typography sx={styles.hightlight}>{matchedValue}</Typography>
          </Fragment>
        );
      }

      return <Fragment key={key}>{valueItem}</Fragment>;
    });

  return <>{matchValue ? highlightedString : value}</>;
}
