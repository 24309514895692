import omit from 'lodash/omit';

import { ICaseStatisticsData } from '@bvi/api-interfaces/response/case';

import { IRequiredStatistics } from './types';

const DESIRED_CASES_NUMBER = 10;

export const hasZeroStatistics = (
  data: ICaseStatisticsData | IRequiredStatistics,
): data is ICaseStatisticsData => {
  return [
    data.overallMinimumPublic,
    data.overallMaximumPublic,
    data.overallMedianNoZeros,
    data.lowestQuartileAverageNoZeros,
    data.highestQuartileAverageNoZeros,
  ].includes(null);
};

export const hasNanStatistics = (
  data: ICaseStatisticsData | IRequiredStatistics,
): data is ICaseStatisticsData => {
  return [
    data.overallMinimumPublic,
    data.overallMaximumPublic,
    data.overallMedianNoZeros,
    data.lowestQuartileAverageNoZeros,
    data.highestQuartileAverageNoZeros,
  ].some((value) => Number.isNaN(value));
};

export const hasAllStatistics = (
  data: ICaseStatisticsData,
): data is IRequiredStatistics => {
  const notRequiredStatistics = ['resolutionDateFrom', 'resolutionDateTo'];
  const statistic = omit(data, notRequiredStatistics);

  return !Object.values(statistic).includes(null);
};

export const isEnoughData = (data: ICaseStatisticsData) => {
  return data.total >= DESIRED_CASES_NUMBER;
};
