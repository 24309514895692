import CloseIcon from '@mui/icons-material/Close';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
} from '@mui/material';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs, { Dayjs } from 'dayjs';
import { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';

import { styles } from './styles';
import {
  DateValueFormat,
  IFormDateRangePickerProperties,
  RangePosition,
} from './types';

type PickerValues = [from: Dayjs | null, to: Dayjs | null];
const dateFormatter = (dates: PickerValues, format: DateValueFormat) => {
  const formatters = {
    utc: dates.map(
      (date) =>
        date?.hour(0).minute(0).second(0).millisecond(0).toDate().getTime(),
    ),
    jsDate: dates.map((date) => date?.utc(true).toDate()),
    jsUtcDate: dates.map(
      (date) =>
        date
          ?.hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .utc(true)
          .toDate()
          .getTime(),
    ),
  };

  return formatters[format];
};

export const FormDateRangePicker = (
  properties: IFormDateRangePickerProperties,
) => {
  const {
    fields: names,
    label,
    labels = ['', ''],
    fullWidth,
    placeholders = ['', ''],
    error,
    helperText,
    variant = 'filled',
    valueFormat = 'utc',
  } = properties;

  const [start, end] = names;
  const [startLabel, endLabel] = labels;
  const [startPlaceholder, endPlaceholder] = placeholders;

  const { getValues, setValue, reset } = useFormContext();
  const currentValues = getValues(names);

  const pickerValue = currentValues.map((date: number) =>
    date ? dayjs(new Date(date)) : null,
  ) as PickerValues;

  const onChange = (dates: PickerValues) => {
    const [startDate, endDate] = dateFormatter(dates, valueFormat);
    setValue(start, startDate);
    setValue(end, endDate);
  };

  const handleResetField = (
    event: React.MouseEvent<HTMLButtonElement>,
    position?: RangePosition,
  ) => {
    event.stopPropagation();
    const formValues = getValues();
    const fieldName = position === 'start' ? start : end;

    reset({ ...formValues, [fieldName]: null });
  };

  const textFieldProperties = (position?: RangePosition) => {
    const currentValue =
      position === 'start' ? getValues(start) : getValues(end);

    return {
      fullWidth,
      error,
      variant,
      label: position === 'start' ? startLabel : endLabel,
      placeholder: position === 'start' ? startPlaceholder : endPlaceholder,
      sx: {
        ...styles.textField,
        ...(currentValue && styles.textFieldWithClearButton),
      },
      InputProps: {
        endAdornment: (
          <InputAdornment position="end" sx={styles.endAdornment}>
            <IconButton
              size="small"
              sx={styles.clearButton}
              onClick={(event) => handleResetField(event, position)}
            >
              <CloseIcon sx={styles.clearIcon} />
            </IconButton>
          </InputAdornment>
        ),
      },
    };
  };

  return (
    <FormControl fullWidth={fullWidth} sx={styles.wrapper}>
      {label && (
        <InputLabel variant={variant} error={Boolean(error)}>
          {label}
        </InputLabel>
      )}
      <DateRangePicker
        value={pickerValue}
        onChange={onChange}
        slots={{
          fieldSeparator: Fragment,
        }}
        slotProps={{
          textField: ({ position }) => textFieldProperties(position),
          fieldRoot: {
            sx: styles.fieldRoot,
          },
        }}
      />
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};
