import { createTheme, responsiveFontSizes } from '@mui/material/styles';

import * as components from './components';
import { palette, typography } from './constants';

import type { SxProps, Theme } from '@mui/system';

export const theme = responsiveFontSizes(
  createTheme({
    palette,
    typography,
    components,
  }),
);

export type SxProperties = SxProps<Theme>;
export type SxStyles = Record<string, SxProperties>;
