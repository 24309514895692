import {
  Stack,
  Typography,
  Skeleton,
  Paper,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { TFunction } from 'i18next';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';

import { CaseStatus } from '@bvi/api-interfaces/entity/case';
import { IUserCaseWithCaseData } from '@bvi/api-interfaces/response/case';
import {
  LoadingTableBody,
  OverflowingText,
  PaginationTable,
  Span,
  info,
  Image,
} from '@bvi/common-components';
import { useCasesListQuery } from '@bvi/dashboard/entities/case/api-slice';
import {
  selectCasesList,
  selectCasesTotalCount,
} from '@bvi/dashboard/entities/case/selectors';
import { DashboardRoutesPaths } from '@bvi/dashboard/shared/constants/routes';
import { useNavigation } from '@bvi/navigation-utils';

import { ManageCaseMenu } from './manage-case-menu';
import { styles } from './styles';

interface IColumn {
  width: string;
  title?: string;
  accessor?: (userCase: IUserCaseWithCaseData) => string;
  format?: (t: TFunction, value: string) => string;
  isSpecial?: boolean;
}

const CLOSED_CASES_TABLE_COLUMNS: Array<IColumn> = [
  {
    title: 'claimant',
    width: '20%',
    accessor: (userCase: IUserCaseWithCaseData) =>
      userCase.case.claimantName ?? '',
  },
  {
    title: 'caseName',
    width: '15%',
    accessor: (userCase: IUserCaseWithCaseData) => userCase.case.name ?? '',
  },
  {
    title: 'description',
    width: '35%',
    accessor: (userCase: IUserCaseWithCaseData) =>
      userCase.case.description ?? '',
  },
  {
    title: 'isSubmitted',
    width: '10%',
    accessor: (userCase: IUserCaseWithCaseData) =>
      [CaseStatus.APPROVED, CaseStatus.PENDING].includes(userCase.case.status)
        ? 'yes'
        : 'no',
    format: (t: TFunction, submitted: string) => t(`cases.common.${submitted}`),
  },
  {
    title: 'status',
    width: '15%',
    accessor: (userCase: IUserCaseWithCaseData) => userCase.case.status,
    format: (t: TFunction, status: string) =>
      t(`cases.common.status.${status}`),
  },
  {
    width: '5%',
    isSpecial: true,
  },
];

const CLOSED_STATUSES = [
  CaseStatus.APPROVED,
  CaseStatus.PENDING,
  CaseStatus.DENIED,
  CaseStatus.CLOSED,
];
const PAGE_LIMIT = 10;

export const ClosedCasesTable = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);

  const query = {
    page,
    limit: PAGE_LIMIT,
    statuses: CLOSED_STATUSES,
  };

  const { isLoading } = useCasesListQuery(query);
  const navigate = useNavigation();

  const userCases = useSelector(selectCasesList(query));
  const casesNumber = useSelector(selectCasesTotalCount(query));

  const handleRowClick = (id: number) => {
    const path = generatePath(DashboardRoutesPaths.CASES_DETAILS, {
      id: String(id),
    });

    navigate(path);
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  return (
    <Stack>
      <Typography variant="caption" sx={styles.title}>
        <Span>{t('cases.list.closed.caption')}</Span>
        {isLoading ? (
          <Skeleton sx={styles.skeleton} width="1em" />
        ) : (
          <Span data-testid="cases.list.closed.count" sx={styles.count}>
            {casesNumber}
          </Span>
        )}
      </Typography>
      <TableContainer component={Paper}>
        <PaginationTable
          stickyHeader
          count={casesNumber}
          page={page}
          onPageChange={handlePageChange}
        >
          <TableHead>
            <TableRow>
              {CLOSED_CASES_TABLE_COLUMNS.map((column) => (
                <TableCell key={nanoid()} align="left" width={column.width}>
                  {column.title && t(`cases.list.closed.table.${column.title}`)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <LoadingTableBody
            rows={2}
            cols={CLOSED_CASES_TABLE_COLUMNS.length + 1}
            isLoading={isLoading}
            rowSx={styles.row}
          >
            {userCases.map((userCase) => (
              <TableRow
                hover
                key={userCase.id}
                aria-label={`go-to-case-${userCase.id}-details`}
              >
                {CLOSED_CASES_TABLE_COLUMNS.filter(
                  (column) => !column.isSpecial,
                ).map((column) => {
                  const showInfoIcon =
                    userCase.case.status === CaseStatus.DENIED &&
                    userCase.declineReason &&
                    column.title === 'status';

                  const text = column.accessor?.(userCase) ?? '';
                  const formattedText = column.format
                    ? column.format(t, text)
                    : text;

                  return (
                    <TableCell
                      key={column.title}
                      sx={styles.activeCell}
                      onClick={() => handleRowClick(userCase.id)}
                    >
                      <OverflowingText
                        text={formattedText}
                        sx={styles.statusText}
                      />
                      {showInfoIcon && (
                        <Tooltip title={userCase.declineReason}>
                          <Image
                            src={info}
                            alt="info"
                            sx={styles.declineReasonIcon}
                          />
                        </Tooltip>
                      )}
                    </TableCell>
                  );
                })}
                <TableCell>
                  <ManageCaseMenu case={userCase} />
                </TableCell>
              </TableRow>
            ))}
          </LoadingTableBody>
        </PaginationTable>
      </TableContainer>
    </Stack>
  );
};
