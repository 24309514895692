import { Typography } from '@mui/material';
import { FC, MouseEventHandler } from 'react';

import { ConfirmActionButton } from '@bvi/common-components';

import { styles } from '../styles';

export interface ISavedSearchActionButtonProperties {
  caption: string;
  question: string;
  confirm: string;
  cancel: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const SavedSearchActionButton: FC<
  ISavedSearchActionButtonProperties
> = ({ caption, question, confirm, cancel, onClick }) => {
  return (
    <ConfirmActionButton
      question={question}
      confirm={confirm}
      cancel={cancel}
      size="small"
      sx={styles.searchActionButton}
      onClick={onClick}
    >
      <Typography variant="smallButton">{caption}</Typography>
    </ConfirmActionButton>
  );
};
