import { Chip, TableCell, TableRow, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ISavedSearchData } from '@bvi/api-interfaces/response/case';
import { useDeleteSavedSearchMutation } from '@bvi/dashboard/entities/saved-searches/api-slice';
import { RepeatSavedSearchActionButton } from '@bvi/dashboard/feature/saved-searches/ui/saved-searches-table/repeat-saved-search-action-button';
import { SavedSearchActionButton } from '@bvi/dashboard/feature/saved-searches/ui/saved-searches-table/saved-search-action-button';
import { SavedSearchEntryRow } from '@bvi/dashboard/feature/saved-searches/ui/saved-searches-table/saved-search-entry-row';

import { styles } from './styles';

export interface ISavedSearchRowProperties {
  savedSearch: ISavedSearchData;
}

export const SavedSearchRow: FC<ISavedSearchRowProperties> = ({
  savedSearch,
}) => {
  const { t } = useTranslation();

  const [deleteSavedSearch] = useDeleteSavedSearchMutation();
  const handleDeleteSearch = () => deleteSavedSearch(savedSearch.id);

  return (
    <>
      <TableRow sx={styles.savedSearchRow}>
        <TableCell>
          <Typography variant="caption">{savedSearch.name}</Typography>
        </TableCell>
        <TableCell />
        <TableCell>
          {savedSearch.isUpdatesAvailable && (
            <Chip
              label="Update Available"
              sx={styles.updateAvailableChip}
              color="success"
              size="small"
            />
          )}
        </TableCell>
        <TableCell sx={styles.buttonCell} align="right">
          <RepeatSavedSearchActionButton savedSearch={savedSearch} />
        </TableCell>
        <TableCell sx={styles.buttonCell} align="right">
          <SavedSearchActionButton
            caption={t('savedSearches.buttons.deleteEntireSearch.caption')}
            question={t('savedSearches.buttons.deleteEntireSearch.question')}
            confirm={t('dialog.confirm')}
            cancel={t('dialog.cancel')}
            onClick={handleDeleteSearch}
          />
        </TableCell>
      </TableRow>
      {savedSearch.entries.map((entry) => (
        <SavedSearchEntryRow
          key={`saved-search-entry-${entry.id}`}
          savedSearchId={savedSearch.id}
          entry={entry}
        />
      ))}
    </>
  );
};
