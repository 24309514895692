import { theme } from '@bvi/mui-theme';

export const styles = {
  formWrapper: {
    width: '400px',
  },
  input: {
    my: 4,
  },
  title: {
    mt: 4,
    mb: 2,
    textAlign: 'center',
  },
  subtitle: {
    color: theme.palette.grey[300],
    textAlign: 'center',
  },
  registerWrapper: {
    mt: 3,
  },
  backButton: {
    my: 1,
  },
  registerNowLink: {
    fontWeight: 700,
    textDecoration: 'none',
    ml: 1,
  },
};
