import { ComponentsOverrides, ComponentsProps } from '@mui/material';
import { Theme } from '@mui/system';

interface IMuiFormHelperTextType {
  defaultProps: ComponentsProps['MuiFormHelperText'];
  styleOverrides: ComponentsOverrides<Theme>['MuiFormHelperText'];
}

export const MuiFormHelperText = {
  defaultProps: {},
  styleOverrides: {
    root: {
      marginLeft: 0,
    },
  },
} satisfies IMuiFormHelperTextType;
