import { z } from 'zod';

import { searchFormPropertySchemas } from '@bvi/cases-search';

export const buildSaveSearchValidationSchema = () => {
  const parameters = Object.fromEntries(
    Object.keys(searchFormPropertySchemas).map((key) => [key, z.string()]),
  ) as Record<keyof typeof searchFormPropertySchemas, z.ZodString>;

  return z.object({
    name: z.string().min(1).max(100),
    parameters: z.object(parameters).partial(),
    userCase: z
      .object({
        id: z.number(),
        name: z.string(),
      })
      .nullable(),
  });
};

export type ISaveSearchFormData = z.infer<
  ReturnType<typeof buildSaveSearchValidationSchema>
>;

export interface ISaveSearchBody extends Omit<ISaveSearchFormData, 'userCase'> {
  userCaseId?: number;
}

export const buildFilterSearchesValidationSchema = () => {
  return z.object({
    date: z.date().nullable(),
  });
};

export type IFilterSearchesFormData = z.infer<
  ReturnType<typeof buildFilterSearchesValidationSchema>
>;

export type IFilterSearchesQuery =
  | {
      dates?: string;
      userCaseIds?: string;
    }
  | undefined;
