import { generatePath } from 'react-router-dom';

import {
  ICreateCaseRequest,
  IUpdateCaseRequest,
} from '@bvi/api-interfaces/request/case';
import { mapCaseFormDataToSearch } from '@bvi/cases-form';
import { DashboardRoutesPaths } from '@bvi/dashboard/shared/constants/routes';
import { useNavigation } from '@bvi/navigation-utils';

export const useNavigateAfterSubmit = () => {
  const navigate = useNavigation();

  return (
    caseId: number,
    data: ICreateCaseRequest | IUpdateCaseRequest,
    shouldSearch?: boolean,
  ) => {
    if (shouldSearch) {
      const mappedData = mapCaseFormDataToSearch(data);
      navigate(DashboardRoutesPaths.SEARCH, mappedData);
    } else {
      const detailsPath = generatePath(DashboardRoutesPaths.CASES_DETAILS, {
        id: String(caseId),
      });

      navigate(detailsPath);
    }
  };
};
