import * as i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './en';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const i18nInstance = i18n.createInstance() as any;

i18nInstance.use(initReactI18next).init({
  resources: { en },
  fallbackLng: 'en',
  returnNull: false,
  debug: false,
});
