export enum AuthRoutesPaths {
  RESET_PASSWORD = '/reset-password',
  RESTORE_PASSWORD = '/restore-password',
  SIGN_IN = '/login',
  SIGN_UP = '/sign-up',
}

export enum DashboardRoutesPaths {
  INDEX = '/',
  SEARCH = '/search',
  SAVED_SEARCHES = '/saved-searches',
  SAVED_SEARCH_DETAILS = '/saved-searches/:savedSearchId/entries/:entryId',
  CASE = '/case',
  CASES_CREATE = '/create-case',
  CASES_EDIT = '/cases/:id/edit',
  CASES_DETAILS = '/cases/:id',
  CASES_LIST = '/cases',
  PROFILE = '/profile',
}
