import differenceWith from 'lodash/differenceWith';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';

type LocationItem = {
  id: number;
  name: string;
};

export const getUniqueLocations = (
  selectedLocations: Array<LocationItem>,
  locations: Array<LocationItem>,
) => {
  const difference = differenceWith(
    locations,
    selectedLocations,
    (location1, location2) => location1.id === location2.id,
  );

  const uniqLocations = uniqBy(
    [...locations, ...difference],
    (location) => location?.id,
  );

  const shouldUpdate = isEmpty(selectedLocations) || !isEmpty(difference);

  return {
    shouldUpdate,
    list: uniqLocations,
  };
};
