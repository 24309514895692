import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { FC, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { Image } from '../Image';
import { eyeClosed, eyeOpen } from '../icons';

export interface IPasswordTextFieldProperties
  extends Omit<TextFieldProps, 'type'> {
  field: string;
}

export const PasswordTextField: FC<IPasswordTextFieldProperties> = (
  properties,
) => {
  const [showPassword, setShowPassword] = useState(false);
  const { field: fieldName } = properties;

  const {
    formState: { errors },
    control,
  } = useFormContext();

  const { field } = useController({
    name: fieldName,
    control,
  });

  const hasError = Boolean(errors[fieldName]);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <TextField
      {...field}
      value={field.value ?? ''}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={toggleShowPassword}
            >
              {showPassword ? (
                <Image src={eyeClosed} alt="show password" />
              ) : (
                <Image src={eyeOpen} alt="hide password" />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      error={hasError}
      {...properties}
    />
  );
};
