import { Button, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Div, PhoneTextField } from '@bvi/common-components';

import { styles } from '../styles';

export const PersonalStep: React.FC = () => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Div>
      <TextField
        {...register('firstName')}
        variant="outlined"
        label={t('auth.signUp.form.firstName.label')}
        placeholder={t('auth.signUp.form.firstName.placeholder')}
        error={Boolean(errors.firstName)}
        helperText={<>{errors.firstName?.message}</>}
        sx={styles.input}
        fullWidth
      />
      <TextField
        {...register('lastName')}
        variant="outlined"
        label={t('auth.signUp.form.lastName.label')}
        placeholder={t('auth.signUp.form.lastName.placeholder')}
        error={Boolean(errors.lastName)}
        helperText={<>{errors.lastName?.message}</>}
        sx={styles.input}
        fullWidth
      />
      <PhoneTextField
        field="phone"
        variant="outlined"
        format="+1 ###-###-####"
        label={t('auth.signUp.form.phone.label')}
        placeholder={t('auth.signUp.form.phone.placeholder')}
        error={Boolean(errors.phone)}
        helperText={<>{errors.phone?.message}</>}
        sx={styles.input}
        fullWidth
      />
      <Button type="submit" variant="outlined" fullWidth sx={styles.button}>
        {t('auth.signUp.form.nextStep')}
      </Button>
    </Div>
  );
};
