import { createInstance } from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';

import locales from '@bvi/dashboard/locales';

export const withI18n = (component: () => React.ReactNode) => () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const i18nInstance = createInstance() as any;

  i18nInstance.use(initReactI18next).init({
    resources: locales,
    fallbackLng: 'en',
    returnNull: false,
    debug: false,
  });

  return <I18nextProvider i18n={i18nInstance}>{component()}</I18nextProvider>;
};
