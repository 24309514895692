export const styles = {
  textField: {
    '& input[value]': {
      textTransform: 'lowercase',
    },
    '& input::placeholder': {
      textTransform: 'capitalize',
    },
  },
  textFieldWithClearButton: {
    '&:hover button': {
      visibility: 'visible',
    },
  },
  openPickerButton: {
    mr: 0,
    p: '4px',
    ml: '-8px',
  },
  endAdornment: {
    position: 'absolute',
    height: '100%',
    maxHeight: '100%',
    right: '5px',
    top: 0,
  },
  clearButton: {
    width: '28px',
    height: '28px',
    visibility: 'hidden',
    marginRight: '4px',
  },
  clearIcon: {
    color: 'grey.300',
    width: '20px',
    height: '20px',
  },
};
