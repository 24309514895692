import { ComponentsOverrides, ComponentsProps } from '@mui/material';
import { Theme } from '@mui/system';

import { palette } from '../constants';

interface IMuiSwitchType {
  defaultProps: ComponentsProps['MuiSwitch'];
  styleOverrides: ComponentsOverrides<Theme>['MuiSwitch'];
}

export const MuiSwitch = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      padding: '0',
      width: '48px',
      height: '24px',
      marginRight: '8px',
      marginLeft: '12px',
      '& .MuiSwitch-switchBase': {
        color: palette.common.white,
        padding: '0',
        '&.Mui-checked': {
          color: palette.common.white,
          transform: 'translateX(24px)',
          '&+.MuiSwitch-track': {
            opacity: 1,
            backgroundColor: palette.primary.main,
          },
        },
        '&+.MuiSwitch-track': {
          padding: 0,
          opacity: 1,
          borderRadius: '24px',
          backgroundColor: palette.grey[200],
        },
      },
    },
    input: {
      padding: 0,
    },
    thumb: {
      width: '16px',
      height: '16px',
      margin: '4px',
      boxShadow: 'none',
    },
  },
} satisfies IMuiSwitchType;
