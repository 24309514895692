import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Header as BaseHeader, IHeaderItem } from '@bvi/common-components';
import { useSignOutMutation } from '@bvi/dashboard/entities/user-auth/api-slice';
import {
  isCurrentUserActive,
  selectCurrentUser,
} from '@bvi/dashboard/entities/user-auth/selectors';
import { DashboardRoutesPaths } from '@bvi/dashboard/shared/constants/routes';

import { styles } from './styles';

export const Header = () => {
  const { t } = useTranslation();
  const [signOutRequest] = useSignOutMutation();

  const currentUser = useSelector(selectCurrentUser());
  const isUserActive = useSelector(isCurrentUserActive());

  const headerItems: Array<IHeaderItem> = [
    {
      label: t('header.index'),
      path: DashboardRoutesPaths.INDEX,
    },
  ];

  if (isUserActive) {
    headerItems.push(
      {
        label: t('header.search'),
        path: DashboardRoutesPaths.SEARCH,
      },
      {
        label: t('header.savedSearches'),
        path: DashboardRoutesPaths.SAVED_SEARCHES,
      },
      {
        label: t('header.case'),
        path: DashboardRoutesPaths.CASE,
      },
      {
        label: t('header.submitCase'),
        path: DashboardRoutesPaths.CASES_CREATE,
      },
    );
  }

  const navItems = [
    {
      label: t('header.logout'),
      action: () => signOutRequest(),
    },
  ];

  const userName = `${currentUser.profile.firstName} ${currentUser.profile.lastName}`;

  return (
    <BaseHeader
      items={headerItems}
      navItems={navItems}
      userName={userName}
      sx={styles.container}
    />
  );
};
