export const styles = {
  autocomplete: {},
  placeholder: {
    position: 'absolute',
    top: '17px',
    left: '10px',
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'grey.300',
  },
  textFieldWrapper: {
    position: 'relative',
  },
  singleTextField: {
    '& .MuiInputBase-root': {
      paddingTop: '7px',
      paddingBottom: '7px',
      paddingLeft: '4px',
    },
    '& .MuiAutocomplete-input.MuiInputBase-input': {
      padding: '0 4px',
    },
  },
  multipleTextField: {
    '& .Mui-focused': {
      '& .MuiAutocomplete-input.MuiInputBase-input': {
        maxWidth: '100%',
        minWidth: '30px',
        padding: '0 4px',
      },
      '& .MuiChip-root': {
        maxWidth: '100%',
      },
    },
    '& .MuiInputBase-root': {
      paddingTop: '7px',
      paddingBottom: '7px',
      paddingLeft: '4px',
    },
    '& .MuiAutocomplete-input.MuiInputBase-input': {
      minWidth: '0px',
      maxWidth: '0px',
      padding: '0',
    },
  },
  chip: {
    '&&': {
      marginTop: '1px',
      marginBottom: '1px',
      height: '26px',
      marginRight: '3px',
      maxWidth: '40%',
    },
  },
  listItem: {
    position: 'relative',
  },
  descriptionIcon: {
    position: 'absolute',
    right: '15px',
    top: '6px',
  },
};
