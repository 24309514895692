export const styles = {
  form: {
    padding: '24px',
  },
  row: {
    width: '392px',
    mb: 3,
  },
  button: {
    width: '180px',
  },
};
