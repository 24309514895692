import { ComponentsOverrides, ComponentsProps } from '@mui/material';
import { LinkProps } from '@mui/material/Link';

import { LinkBehavior } from './LinkBehavior';

interface IMuiLink {
  defaultProps: ComponentsProps['MuiLink'];
  styleOverrides: ComponentsOverrides['MuiLink'];
}

export const MuiLink: IMuiLink = {
  defaultProps: {
    component: LinkBehavior,
  } as LinkProps,
  styleOverrides: {},
};
