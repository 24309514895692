import { TFunction } from 'i18next';
import { z } from 'zod';

import {
  VictimInfoGenderVariantKeys,
  VictimInfoAgeCategoryVariantKeys,
  AbuseDetailsLevel1VariantKeys,
  AbuseDetailsLevel2VariantKeys,
  AbuseDetailsLevel3VariantKeys,
  AbuseDetailsLevel4VariantKeys,
  AbuseDetailsLevel5VariantKeys,
  YesNoVariantsKey,
  LegalDetailsPreparatorVariantsKey,
  ClaimantTypeVariantKeys,
  CaseInformation,
  VictimInfoKeys,
  AbuseDetailsKeys,
  LegalDetailsKeys,
  StaticPropertyKey,
} from '@bvi/api-interfaces/entity/case-property';
import { ICaseData } from '@bvi/api-interfaces/response/case';

import { mapCaseToFormData } from './mappers';

const relation = z
  .object({
    id: z.number(),
  })
  .nullable();

const resolutionType = (t: TFunction) =>
  z
    .object(
      {
        id: z.number(),
      },
      {
        required_error: t('error.form.required'),
        invalid_type_error: t('error.form.required'),
      },
    )
    .nullable();

const cost = z
  .object({
    amount: z.number().min(0).nullable(),
    currency: relation.optional(),
  })
  .nullable();

export const buildValidationSchema = (t: TFunction) => {
  const resolutionFields = {
    resolutionDate: z.date().optional().nullable(),
    total: cost.optional(),
    claimantsCount: z
      .number({
        required_error: t('error.form.required'),
        invalid_type_error: t('error.form.required'),
      })
      .min(0),
    perClaimant: cost.optional(),
    type: resolutionType(t),
    defenseCosts: cost.optional(),
    isPublic: z.boolean().nullable().optional(),
  };

  const validationFields = {
    //case information
    [CaseInformation.CLAIMANT_TYPE]: z
      .nativeEnum(ClaimantTypeVariantKeys)
      .nullable()
      .optional(),
    [CaseInformation.CLAIMANT_COUNT]: z.number().nullable(),
    [CaseInformation.CLAIMANT_NAME]: z.string().nullable(),
    fileName: z.string().nullable(), //name
    [StaticPropertyKey.COUNTRY]: relation,
    [StaticPropertyKey.REGION]: relation,
    [StaticPropertyKey.STATE]: relation,
    //claimant information
    [VictimInfoKeys.GENDER]: z
      .nativeEnum(VictimInfoGenderVariantKeys)
      .nullable()
      .or(z.string()),
    [VictimInfoKeys.BIRTH_DATE]: z.date().nullable(),
    [VictimInfoKeys.AGE_AT_START_OF_ABUSE]: z
      .number()
      .min(1)
      .max(999)
      .nullable(),
    [VictimInfoKeys.AGE_AT_END_OF_ABUSE]: z.number().min(1).max(999).nullable(),
    [VictimInfoKeys.AGE_CATEGORY]: z
      .nativeEnum(VictimInfoAgeCategoryVariantKeys)
      .nullable()
      .optional()
      .or(z.string()),
    [VictimInfoKeys.STARTED_ABUSE_AT]: z.date().nullable(),
    [VictimInfoKeys.END_OF_ABUSE_AT]: z.date().nullable(),
    //perpetrator information
    perpetratorName: z.string().nullable().optional(), //properties.name
    [StaticPropertyKey.JOB_TITLE]: relation,
    [StaticPropertyKey.ORDER]: relation,
    [StaticPropertyKey.ORGANIZATION]: relation,
    [StaticPropertyKey.DIOCESE]: relation,
    // types of abuse
    [AbuseDetailsKeys.DETAIL_AVAILABLE]: z
      .nativeEnum(YesNoVariantsKey)
      .nullable(),
    [AbuseDetailsKeys.LEVEL_1]: z
      .nativeEnum(AbuseDetailsLevel1VariantKeys)
      .array(),
    [AbuseDetailsKeys.LEVEL_2]: z
      .nativeEnum(AbuseDetailsLevel2VariantKeys)
      .array(),
    [AbuseDetailsKeys.LEVEL_3]: z
      .nativeEnum(AbuseDetailsLevel3VariantKeys)
      .array(),
    [AbuseDetailsKeys.LEVEL_4]: z
      .nativeEnum(AbuseDetailsLevel4VariantKeys)
      .array(),
    [AbuseDetailsKeys.LEVEL_5]: z
      .nativeEnum(AbuseDetailsLevel5VariantKeys)
      .array(),
    [AbuseDetailsKeys.NUMBER_OF_INCIDENTS_LOW]: z
      .number()
      .min(1)
      .max(99)
      .nullable(),
    [AbuseDetailsKeys.NUMBER_OF_INCIDENTS_HIGH]: z
      .number()
      .min(1)
      .max(99)
      .nullable(),
    [AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_LOW]: z
      .number()
      .min(1)
      .max(99)
      .nullable(),
    [AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_HIGH]: z
      .number()
      .min(1)
      .max(99)
      .nullable(),
    [StaticPropertyKey.DAMAGE]: relation,
    [LegalDetailsKeys.DAMAGES_COMMENT]: z.string(),
    [LegalDetailsKeys.OTHER_LIFE_STRESS]: z.string(),
    //legal
    [LegalDetailsKeys.LAWSUIT_FILED]: z.nativeEnum(YesNoVariantsKey).nullable(),
    [LegalDetailsKeys.HAS_ATTORNEY]: z.nativeEnum(YesNoVariantsKey).nullable(),
    [StaticPropertyKey.LIABILITY_DEFENSES]: z.array(
      z.object({
        id: z.number(),
      }),
    ),
    [StaticPropertyKey.EXCEPTION]: relation,
    [StaticPropertyKey.GROUP]: relation.optional(),
    [StaticPropertyKey.ORGANIZATION_TYPE]: relation,
    [LegalDetailsKeys.PREPARATOR]: z
      .nativeEnum(LegalDetailsPreparatorVariantsKey)
      .nullable(),
    isResolved: z.boolean(),
    description: z.string().nullable(),
  };

  return z.discriminatedUnion('isResolved', [
    z.object({
      ...validationFields,
      isResolved: z.literal(false),
      resolution: z
        .object({
          ...resolutionFields,
          type: resolutionType(t).nullable().optional(),
          claimantsCount: z
            .number({
              required_error: t('error.form.required'),
              invalid_type_error: t('error.form.required'),
            })
            .min(0)
            .optional(),
        })
        .nullable(),
    }),
    z.object({
      ...validationFields,
      isResolved: z.literal(true),
      resolution: z.object(resolutionFields),
    }),
  ]);
};

export const defaultValues = {
  [CaseInformation.CLAIMANT_TYPE]: ClaimantTypeVariantKeys.SINGLE,
  [CaseInformation.CLAIMANT_COUNT]: null,
  [CaseInformation.CLAIMANT_NAME]: '',
  fileName: '',
  [StaticPropertyKey.COUNTRY]: null,
  [StaticPropertyKey.REGION]: null,
  [StaticPropertyKey.STATE]: null,

  [VictimInfoKeys.GENDER]: '',
  [VictimInfoKeys.BIRTH_DATE]: null,
  [VictimInfoKeys.AGE_AT_START_OF_ABUSE]: null,
  [VictimInfoKeys.AGE_AT_END_OF_ABUSE]: null,
  [VictimInfoKeys.AGE_CATEGORY]: '',
  [VictimInfoKeys.STARTED_ABUSE_AT]: null,
  [VictimInfoKeys.END_OF_ABUSE_AT]: null,

  perpetratorName: '',
  [StaticPropertyKey.JOB_TITLE]: null,
  [StaticPropertyKey.ORDER]: null,
  [StaticPropertyKey.ORGANIZATION]: null,
  [StaticPropertyKey.ORGANIZATION_TYPE]: null,
  [StaticPropertyKey.DIOCESE]: null,

  [AbuseDetailsKeys.DETAIL_AVAILABLE]: YesNoVariantsKey.YES,
  [AbuseDetailsKeys.LEVEL_1]: [],
  [AbuseDetailsKeys.LEVEL_2]: [],
  [AbuseDetailsKeys.LEVEL_3]: [],
  [AbuseDetailsKeys.LEVEL_4]: [],
  [AbuseDetailsKeys.LEVEL_5]: [],
  [AbuseDetailsKeys.NUMBER_OF_INCIDENTS_LOW]: null,
  [AbuseDetailsKeys.NUMBER_OF_INCIDENTS_HIGH]: null,
  [AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_LOW]: null,
  [AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_HIGH]: null,
  [StaticPropertyKey.DAMAGE]: null,
  [LegalDetailsKeys.DAMAGES_COMMENT]: '',
  [LegalDetailsKeys.OTHER_LIFE_STRESS]: '',
  [LegalDetailsKeys.LAWSUIT_FILED]: null,
  [LegalDetailsKeys.HAS_ATTORNEY]: null,
  [StaticPropertyKey.LIABILITY_DEFENSES]: [],
  [StaticPropertyKey.EXCEPTION]: null,
  [LegalDetailsKeys.PREPARATOR]: null,
  isResolved: true,
  description: '',
  resolution: {
    resolutionDate: null,
    total: null,
    claimantsCount: 1,
    perClaimant: null,
    defenseCosts: null,
    type: null,
    isPublic: true,
    group: null,
  },
};

export const initialValues = (_case?: ICaseData) => mapCaseToFormData(_case);
