import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Grid, Paper } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Form } from '@bvi/common-components';

import { mapFormDataToSubmit } from '../../lib';
import { CasesFormContext } from '../../lib/context';
import { buildValidationSchema, initialValues } from '../../lib/schema';
import {
  ICaseFormData,
  ICaseFormRequest,
  ICasesFormProperties,
  SearchButtonRole,
} from '../../lib/types';

import { AbuseType } from './abuse-type';
import { CaseInformation } from './case-information';
import { ClaimantInformation } from './claimant-information';
import { LegalDetails } from './legal-details';
import { PerpetratorInformation } from './perpetrator-information';
import { ResolutionDetails } from './resolution-details';
import { styles } from './styles';

export const CasesForm = <T extends ICaseFormRequest>(
  properties: ICasesFormProperties<T>,
) => {
  const { case: _case, onSubmitRequest } = properties;
  const { t } = useTranslation('translation');

  const validationSchema = buildValidationSchema(t);

  const defaultValues = initialValues(_case);

  const methods = useForm<ICaseFormData>({
    resolver: zodResolver(validationSchema),
    defaultValues,
  });

  const onSubmit = async (
    data: ICaseFormData,
    event?: React.BaseSyntheticEvent,
  ) => {
    const preparedData = mapFormDataToSubmit(data) as unknown as T;

    const nativeEvent = event?.nativeEvent as SubmitEvent;
    const shouldSearch =
      nativeEvent?.submitter?.role === SearchButtonRole.SEARCH;

    await onSubmitRequest(preparedData, shouldSearch);
  };

  return (
    <CasesFormContext.Provider value={properties as ICasesFormProperties}>
      <FormProvider {...methods}>
        <Form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={9}>
              <Paper sx={styles.wrapper}>
                <CaseInformation />
                <ClaimantInformation />
                <PerpetratorInformation />
                <AbuseType />
                <LegalDetails />
                <ResolutionDetails />
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={3} my={0} pb={2}>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => methods.reset(defaultValues)}
              >
                {t('cases.form.reset')}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" type="submit">
                {t('cases.form.submit')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                type="submit"
                role={SearchButtonRole.SEARCH}
              >
                {t('cases.form.submitAndSearch')}
              </Button>
            </Grid>
          </Grid>
        </Form>
      </FormProvider>
    </CasesFormContext.Provider>
  );
};
