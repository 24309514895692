import { Button, Skeleton, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Span } from '@bvi/common-components';
import { DashboardRoutesPaths } from '@bvi/dashboard/shared/constants/routes';
import { useNavigation } from '@bvi/navigation-utils';

import { styles } from './styles';

export interface ISavedSearchesHeaderProperties {
  count: number;
  isLoading: boolean;
}

export const SavedSearchesHeader: FC<ISavedSearchesHeaderProperties> = ({
  count,
  isLoading,
}) => {
  const { t } = useTranslation();

  const navigate = useNavigation();
  const handleStartNewSearch = () => navigate(DashboardRoutesPaths.SEARCH);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      mb={3}
    >
      <Typography variant="h1">
        <Span>{t('savedSearches.title')}</Span>
        {isLoading ? (
          <Skeleton sx={styles.skeleton} width="2em" />
        ) : (
          <Span data-testid="cases.list.count" sx={styles.count}>
            {count}
          </Span>
        )}
      </Typography>
      <Button variant="outlined" onClick={handleStartNewSearch}>
        {t('savedSearches.buttons.startNewSearch.caption')}
      </Button>
    </Stack>
  );
};
