import { FC } from 'react';

import { Image } from '../Image';

import logoNavy from './logo-navy.svg';
import logoWhite from './logo-white.svg';
import { styles } from './styles';

interface ILogoProperties {
  variant?: 'navy' | 'white';
}

export const Logo: FC<ILogoProperties> = ({ variant = 'navy' }) => {
  if (variant === 'white') {
    return <Image src={logoWhite} alt="logo" sx={styles.whiteLogo} />;
  }

  return <Image src={logoNavy} alt="Logo" sx={styles.logo} />;
};
