import { useTranslation } from 'react-i18next';

import { IUserCaseWithCaseData } from '@bvi/api-interfaces/response/case';
import { DataGrid, DataPopover, Div } from '@bvi/common-components';
import {
  useFormatByType,
  CASE_INFORMATION,
  CLAIMANT_INFORMATION,
  LEGAL_DETAILS,
  PERPETRATOR_INFORMATION,
  TYPE_OF_ABUSE,
  getCaseValue,
} from '@bvi/dashboard/feature/cases-details/lib';

type CasesDetailsInformationProperties = {
  caseData: IUserCaseWithCaseData;
};

export const CasesDetailsInformation: React.FC<
  CasesDetailsInformationProperties
> = (properties) => {
  const {
    caseData: { case: _case },
  } = properties;
  const { t } = useTranslation();
  const formatByType = useFormatByType();

  const caseInformation = CASE_INFORMATION.map((field) => {
    const value = getCaseValue(_case, field.valuePath);

    const format = formatByType({
      type: field.valueType,
      tKey: field.tKey,
      boolValues: field.boolValues,
    });

    return {
      ...field,
      label: t(field.label),
      value: format(value),
    };
  });

  const claimantInformation = CLAIMANT_INFORMATION.map((field) => {
    const value = getCaseValue(_case, field.valuePath);

    const format = formatByType({
      type: field.valueType,
      tKey: field.tKey,
      boolValues: field.boolValues,
    });

    return {
      ...field,
      label: t(field.label),
      value: format(value),
    };
  });

  const perpetratorInformation = PERPETRATOR_INFORMATION.map((field) => {
    const value = getCaseValue(_case, field.valuePath);

    const format = formatByType({
      type: field.valueType,
      tKey: field.tKey,
      boolValues: field.boolValues,
    });

    return {
      ...field,
      label: t(field.label),
      value: format(value),
    };
  });

  const typeOfAbuse = TYPE_OF_ABUSE.map((field) => {
    const value = getCaseValue(_case, field.valuePath);

    const format = formatByType({
      type: field.valueType,
      tKey: field.tKey,
      boolValues: field.boolValues,
    });

    return {
      ...field,
      label: t(field.label),
      value: format(value),
    };
  });

  const legalDetails = LEGAL_DETAILS.map((field) => {
    const value = getCaseValue(_case, field.valuePath);

    const format = formatByType({
      type: field.valueType,
      tKey: field.tKey,
      boolValues: field.boolValues,
      arrayItemValuePath: field.arrayItemValuePath,
    });

    return {
      ...field,
      label: t(field.label),
      value: format(value),
    };
  });

  return (
    <Div>
      <DataPopover title={t('cases.details.caseInformation.title')}>
        <DataGrid
          items={caseInformation}
          containerProps={{ my: 1, py: 1, px: 3 }}
          itemsProps={{ py: 2 }}
        />
      </DataPopover>
      <DataPopover title={t('cases.details.claimantInformation.title')}>
        <DataGrid
          items={claimantInformation}
          containerProps={{ my: 1, py: 1, px: 3 }}
          itemsProps={{ py: 2 }}
        />
      </DataPopover>
      <DataPopover title={t('cases.details.perpetratorInformation.title')}>
        <DataGrid
          items={perpetratorInformation}
          containerProps={{ my: 1, py: 1, px: 3 }}
          itemsProps={{ py: 2 }}
        />
      </DataPopover>
      <DataPopover title={t('cases.details.typeOfAbuse.title')}>
        <DataGrid
          items={typeOfAbuse}
          containerProps={{ my: 1, py: 1, px: 3 }}
          itemsProps={{ py: 2 }}
        />
      </DataPopover>
      <DataPopover title={t('cases.details.legalDetails.title')}>
        <DataGrid
          items={legalDetails}
          containerProps={{ my: 1, py: 1, px: 3 }}
          itemsProps={{ py: 2 }}
        />
      </DataPopover>
    </Div>
  );
};
