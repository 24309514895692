import { Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CaseEvents, CaseStatus } from '@bvi/api-interfaces/entity/case';
import { ICaseData } from '@bvi/api-interfaces/response/case';
import { isServerError } from '@bvi/axios-query';
import { ConfirmActionMenuItem, Div } from '@bvi/common-components';
import {
  useChangeStatusMutation,
  useRemoveCaseMutation,
} from '@bvi/dashboard/entities/case/api-slice';
import { mapCaseDataToSearch } from '@bvi/dashboard/feature/cases-details/lib/mapCaseDataToSearch';
import { DashboardRoutesPaths } from '@bvi/dashboard/shared/constants/routes';
import { useNavigation } from '@bvi/navigation-utils';
import { NotistackMessageVariants, useNotistack } from '@bvi/notistack';

import { styles } from './styles';

type HeaderActionsProperties = {
  caseId: number;
  _case: ICaseData;
};

export const HeaderActions: React.FC<HeaderActionsProperties> = (
  properties,
) => {
  const { caseId, _case } = properties;
  const { t } = useTranslation();
  const navigate = useNavigation();
  const { showNotificationMessage } = useNotistack();

  const [changeStatus, { isLoading: isChangeStatusPending }] =
    useChangeStatusMutation();
  const [removeCase, { isLoading: isRemoveCasePending }] =
    useRemoveCaseMutation();

  const isPending = _case.status === CaseStatus.PENDING;
  const isClosed = _case.status === CaseStatus.CLOSED;
  const isPendingOrClosed = isPending || isClosed;

  const isDisabled = isChangeStatusPending || isRemoveCasePending;

  const handleChangeStatus = async (event: CaseEvents) => {
    try {
      await changeStatus([caseId, { event }]).unwrap();
    } catch (error) {
      if (isServerError(error)) {
        showNotificationMessage(
          { type: error.type },
          NotistackMessageVariants.ERROR,
        );
      }
    }
  };

  const handleRemoveCase = async () => {
    try {
      await removeCase(caseId).unwrap();
      navigate(DashboardRoutesPaths.CASE);
    } catch (error) {
      if (isServerError(error)) {
        showNotificationMessage(
          { type: error.type },
          NotistackMessageVariants.ERROR,
        );
      }
    }
  };

  const handleSearch = () => {
    const preparedData = mapCaseDataToSearch(_case);

    navigate(DashboardRoutesPaths.SEARCH, preparedData);
  };

  if (isPendingOrClosed) {
    return (
      <Div>
        <Button variant="outlined" sx={styles.button} onClick={handleSearch}>
          {t('cases.details.actions.runSearch')}
        </Button>
        <Button
          variant="outlined"
          sx={styles.button}
          onClick={() => handleChangeStatus(CaseEvents.Reopen)}
          disabled={isDisabled}
        >
          {t('cases.details.actions.reopen')}
        </Button>
      </Div>
    );
  }

  return (
    <Stack direction="row" alignItems="center">
      <Button variant="outlined" sx={styles.button} onClick={handleSearch}>
        {t('cases.details.actions.runSearch')}
      </Button>
      <Button
        variant="outlined"
        sx={styles.button}
        onClick={() => handleChangeStatus(CaseEvents.Close)}
        disabled={isDisabled}
      >
        {t('cases.details.actions.close')}
      </Button>
      <Button
        variant="outlined"
        sx={styles.button}
        onClick={() => handleChangeStatus(CaseEvents.Submit)}
        disabled={isDisabled}
      >
        {t('cases.details.actions.submit')}
      </Button>
      <ConfirmActionMenuItem
        question={t('cases.details.actions.delete.question')}
        confirm={t('dialog.confirm')}
        cancel={t('dialog.cancel')}
        onClick={handleRemoveCase}
        component={Button}
        componentProperties={{
          color: 'error',
          variant: 'outlined',
          sx: styles.button,
          disabled: isDisabled,
        }}
      >
        {t('cases.details.actions.delete.label')}
      </ConfirmActionMenuItem>
    </Stack>
  );
};
