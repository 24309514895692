import { IResolutionPrice } from '@bvi/api-interfaces/entity/resolution';

export enum CasesDataType {
  string = 'string',
  date = 'date',
  translation = 'translation',
  array = 'array',
  list = 'list',
  boolean = 'boolean',
  range = 'range',
  currency = 'currency',
}

export type FormatByTypeOptions<T extends CasesDataType> = {
  type: T;
  tKey: string;
  boolValues: Readonly<[trueValue: string, falseValue: string] | []>;
  arrayItemValuePath?: string;
};

export type ValueTypeByCaseDetailsType = {
  [CasesDataType.string]: string;
  [CasesDataType.translation]: string;
  [CasesDataType.array]: Array<object>;
  [CasesDataType.date]: Date;
  [CasesDataType.boolean]: boolean;
  [CasesDataType.range]: [string, string];
  [CasesDataType.list]: Array<string>;
  [CasesDataType.currency]: IResolutionPrice;
};

export type FormatterType = {
  [T in CasesDataType]: (
    value: ValueTypeByCaseDetailsType[T],
  ) => React.ReactNode;
};
