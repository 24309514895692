import { Alert } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ICaseStatisticsData } from '@bvi/api-interfaces/response/case';

import { hasAllStatistics, isEnoughData } from '../../lib/statistics-guards';
import { i18nInstance } from '../../locales';

export interface IStatisticsAlertParameters {
  data: ICaseStatisticsData;
  isLoading: boolean;
}

export const StatisticsAlert: FC<IStatisticsAlertParameters> = ({
  data,
  isLoading,
}) => {
  const { t } = useTranslation('', {
    i18n: i18nInstance,
    keyPrefix: 'results.table',
  });

  if (isLoading || isEmpty(data)) {
    return null;
  }

  if (!hasAllStatistics(data)) {
    return (
      <Alert severity="warning" sx={{ mt: 3 }}>
        {t('notEnoughDataAlert')}
      </Alert>
    );
  }

  if (!isEnoughData(data)) {
    return (
      <Alert severity="warning" sx={{ mt: 3 }}>
        {t('tooSmallSample')}
      </Alert>
    );
  }

  return null;
};
