import { TextField, TextFieldProps } from '@mui/material';
import { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';

export interface IEmailTextFieldProperties
  extends Omit<TextFieldProps, 'type'> {
  field: string;
  unavailableMessage?: string;
  isAvailable?: boolean;
}

export const EmailTextField: FC<IEmailTextFieldProperties> = (properties) => {
  const {
    field: fieldName,
    isAvailable = true,
    unavailableMessage,
  } = properties;

  const {
    formState: { errors },
    control,
  } = useFormContext();

  const { field } = useController({
    name: fieldName,
    control,
  });

  const hasError = Boolean(errors[fieldName]) || !isAvailable;
  const helperText = isAvailable ? errors.email?.message : unavailableMessage;

  return (
    <TextField
      {...field}
      value={field.value ?? ''}
      type="email"
      {...properties}
      error={hasError}
      helperText={<>{helperText}</>}
    />
  );
};
