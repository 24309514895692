import CircularProgress from '@mui/material/CircularProgress';
import { FC } from 'react';

import { Div } from '../Div';

import { styles } from './styles';

export const Loader: FC = () => {
  return (
    <Div sx={styles.container}>
      <CircularProgress />
    </Div>
  );
};
