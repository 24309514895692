import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  TextField,
  RadioGroup,
  Typography,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  ClaimantTypeVariantKeys,
  StaticPropertyKey,
  CaseInformation as CaseInformationType,
} from '@bvi/api-interfaces/entity/case-property';
import { ICountryData, IRegionData } from '@bvi/api-interfaces/response/case';
import { Autocomplete, Div, AutocompleteValue } from '@bvi/common-components';

import { useCasesFormContext } from '../../../lib/context';
import { ICaseFormData, ICaseFormFields } from '../../../lib/types';
import { styles } from '../styles';

interface ITerritoryValue extends AutocompleteValue {
  country?: ICountryData;
  region?: IRegionData;
}

export interface ICaseInformationProperties {
  hiddenFields?: ICaseFormFields;
}

export const CaseInformation = () => {
  const { t } = useTranslation('translation');
  const { hiddenFields, onLoadMoreSettings } = useCasesFormContext();
  const showClaimantName = !hiddenFields?.includes(
    CaseInformationType.CLAIMANT_NAME,
  );
  const showFileName = !hiddenFields?.includes('fileName');

  const {
    register,
    control,
    resetField,
    getValues,
    watch,
    formState: { errors },
  } = useFormContext<ICaseFormData>();

  const claimantType = watch(CaseInformationType.CLAIMANT_TYPE);
  const showClaimantCount = claimantType === ClaimantTypeVariantKeys.GROUP;

  return (
    <Div>
      <Typography variant="body1" sx={styles.title}>
        {t('cases.form.caseInformation.title')}
      </Typography>
      <Grid container spacing={2} my={1}>
        <Grid item xs={12}>
          <FormControl>
            <InputLabel variant="filled">
              {t('cases.form.caseInformation.subtitle')}
            </InputLabel>
            <Controller
              name={CaseInformationType.CLAIMANT_TYPE}
              control={control}
              render={({ field }) => (
                <RadioGroup {...field} sx={styles.radioGroup} row>
                  <FormControlLabel
                    value={ClaimantTypeVariantKeys.SINGLE}
                    control={<Radio />}
                    label={t('claimantTypeItems.single')}
                  />
                  <FormControlLabel
                    value={ClaimantTypeVariantKeys.GROUP}
                    control={<Radio />}
                    label={t('claimantTypeItems.group')}
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
          <TextField
            {...register(CaseInformationType.CLAIMANT_COUNT, {
              setValueAs: (value) => (value ? Number(value) : null),
            })}
            style={{
              visibility: showClaimantCount ? 'visible' : 'hidden',
            }}
            type="number"
            variant="filled"
            placeholder={t('cases.form.caseInformation.claimantCount')}
            helperText={errors[CaseInformationType.CLAIMANT_COUNT]?.message}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        {showClaimantName && (
          <Grid item xs={6}>
            <TextField
              {...register(CaseInformationType.CLAIMANT_NAME)}
              variant="filled"
              fullWidth
              label={t('cases.form.caseInformation.claimantName.label')}
              placeholder={t(
                'cases.form.caseInformation.claimantName.placeholder',
              )}
              error={Boolean(errors[CaseInformationType.CLAIMANT_NAME])}
              helperText={
                <>{errors[CaseInformationType.CLAIMANT_NAME]?.message}</>
              }
            />
          </Grid>
        )}
        {showFileName && (
          <Grid item xs={6}>
            <TextField
              {...register('fileName')}
              variant="filled"
              fullWidth
              label={t('cases.form.caseInformation.name.label')}
              placeholder={t('cases.form.caseInformation.name.placeholder')}
              helperText={<>{errors.fileName?.message}</>}
            />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={4}>
          <Autocomplete
            name={StaticPropertyKey.COUNTRY}
            onOptionsLoad={async (data) =>
              await onLoadMoreSettings(StaticPropertyKey.COUNTRY, data)
            }
            variant="filled"
            label={t('cases.form.caseInformation.country.label')}
            placeholder={t('cases.form.caseInformation.country.placeholder')}
            resetOnChange={() => {
              resetField(StaticPropertyKey.REGION, { defaultValue: null });
              resetField(StaticPropertyKey.STATE, { defaultValue: null });
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete<ITerritoryValue>
            name={StaticPropertyKey.REGION}
            onOptionsLoad={async (data) => {
              const country = getValues('country');

              return await onLoadMoreSettings(StaticPropertyKey.REGION, {
                ...data,
                country,
              });
            }}
            variant="filled"
            label={t('cases.form.caseInformation.region.label')}
            placeholder={t('cases.form.caseInformation.region.placeholder')}
            resetOnChange={(value) => {
              const currentCountry = value?.country ?? null;
              resetField(StaticPropertyKey.COUNTRY, {
                defaultValue: currentCountry,
              });
              resetField(StaticPropertyKey.STATE);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete<ITerritoryValue>
            name={StaticPropertyKey.STATE}
            onOptionsLoad={async (data) => {
              const [country, region] = getValues(['country', 'region']);

              return await onLoadMoreSettings(StaticPropertyKey.STATE, {
                ...data,
                country,
                region,
              });
            }}
            variant="filled"
            label={t('cases.form.caseInformation.state.label')}
            placeholder={t('cases.form.caseInformation.state.placeholder')}
            resetOnChange={(value) => {
              const currentCountry = value?.country ?? null;
              const currentRegion = value?.region ?? null;
              resetField(StaticPropertyKey.COUNTRY, {
                defaultValue: currentCountry,
              });
              resetField(StaticPropertyKey.REGION, {
                defaultValue: currentRegion,
              });
            }}
          />
        </Grid>
      </Grid>
    </Div>
  );
};
