import { Grid } from '@mui/material';
import { parse } from 'qs';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useGetSavedSearchesQuery } from '@bvi/dashboard/entities/saved-searches/api-slice';
import { selectSavedSearches } from '@bvi/dashboard/entities/saved-searches/selectors';
import { IFilterSearchesQuery } from '@bvi/dashboard/feature/saved-searches/lib/schema';
import { SavedSearchFilter } from '@bvi/dashboard/feature/saved-searches/ui/saved-search-filter';
import { SavedSearchesHeader } from '@bvi/dashboard/feature/saved-searches/ui/saved-searches-header';
import { SavedSearchesTable } from '@bvi/dashboard/feature/saved-searches/ui/saved-searches-table';

export const SavedSearches: FC = () => {
  const location = useLocation();
  const searchParameters = parse(location.search, {
    ignoreQueryPrefix: true,
  }) as IFilterSearchesQuery;

  const { isFetching } = useGetSavedSearchesQuery(searchParameters);
  const savedSearches = useSelector(selectSavedSearches(searchParameters));

  return (
    <Grid>
      <SavedSearchesHeader
        count={savedSearches.length}
        isLoading={isFetching}
      />
      <SavedSearchFilter isDisabled={isFetching} />
      <SavedSearchesTable
        savedSearches={savedSearches}
        isLoading={isFetching}
      />
    </Grid>
  );
};
