import { ComponentsOverrides, ComponentsProps } from '@mui/material';
import { Theme } from '@mui/system';

import { palette } from '../constants';

interface IMuiAccordionType {
  defaultProps: ComponentsProps['MuiAccordion'];
  styleOverrides: ComponentsOverrides<Theme>['MuiAccordion'];
}

export const MuiAccordion = {
  defaultProps: {
    disableGutters: true,
  },
  styleOverrides: {
    root: {
      '&:before': {
        display: 'none',
      },
      border: 'none',
      '& .MuiAccordionSummary-root': {
        color: palette.primary.main,
        border: 'none',
        padding: '0',
        '& .MuiSvgIcon-root': {
          color: palette.primary.main,
        },
      },
      '& .MuiAccordionDetails-root': {
        border: 'none',
        padding: '0',
      },
    },
  },
} satisfies IMuiAccordionType;
