import { ComponentsOverrides, ComponentsProps } from '@mui/material';

interface IMuiTableRow {
  defaultProps: ComponentsProps['MuiTableRow'];
  styleOverrides: ComponentsOverrides['MuiTableRow'];
}

export const MuiTableRow = {
  defaultProps: {},
  styleOverrides: {
    root: {
      maxWidth: '0px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
} satisfies IMuiTableRow;
