import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Link,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import omit from 'lodash/omit';
import { closeSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { isServerError } from '@bvi/axios-query';
import { Div, Form, Logo } from '@bvi/common-components';
import {
  useLazyCheckEmailQuery,
  useSignUpMutation,
} from '@bvi/dashboard/entities/user-auth/api-slice';
import { AuthRoutesPaths } from '@bvi/dashboard/shared/constants/routes';
import { NotistackMessageVariants, useNotistack } from '@bvi/notistack';

import { buildValidationSchema } from './schema';
import { SignupSteps } from './signup-steps';
import { styles } from './styles';
import { SuccessMessage } from './success-message';
import { ISignupFormData, SignUpFormStep } from './types';

const steps: Array<SignUpFormStep> = [
  SignUpFormStep.credential,
  SignUpFormStep.personal,
  SignUpFormStep.company,
];

export const SignupForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [signUpRequest, { error }] = useSignUpMutation();
  const { showNotificationMessage } = useNotistack();
  const [searchParameters, setSearchParameters] = useSearchParams();
  const { t } = useTranslation();
  const shouldRenderSuccessMessage = searchParameters.get('email');

  const currentStep = useMemo(() => steps[activeStep], [activeStep]);
  const validationSchema = buildValidationSchema(t, currentStep);
  const methods = useForm<ISignupFormData>({
    resolver: zodResolver(validationSchema),
  });
  const [checkEmail] = useLazyCheckEmailQuery();

  const { getValues, handleSubmit } = methods;

  const handleNextStep = () => {
    setActiveStep((previousActiveStep) => previousActiveStep + 1);
  };

  const handleStep = (step: number) => {
    if (step < activeStep) {
      setActiveStep(step);
    }
  };

  const onSubmit = async () => {
    const isLastStep = currentStep === SignUpFormStep.company;
    const isCredentialStep = currentStep === SignUpFormStep.credential;

    if (isCredentialStep) {
      try {
        const email = getValues('email');
        const result = await checkEmail(email);

        const isAvailable = result.data?.success
          ? result.data.payload.data.isAvailable
          : false;

        if (!isAvailable) {
          return;
        }
      } catch {
        return;
      }
    }

    if (isLastStep) {
      try {
        const data = getValues();
        await signUpRequest(omit(data, 'confirmPassword')).unwrap();
        setSearchParameters({ email: data.email });
      } catch {
        return;
      }
    }

    handleNextStep();
  };

  useEffect(() => {
    if (isServerError(error)) {
      showNotificationMessage(
        { type: error.type },
        NotistackMessageVariants.ERROR,
        (key) => (
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              setActiveStep(0);
              closeSnackbar(key);
            }}
            sx={styles.backButton}
          >
            {t('auth.signUp.form.goToFirstStep')}
          </Button>
        ),
      );
    }
  }, [error, showNotificationMessage, t]);

  if (shouldRenderSuccessMessage) {
    return <SuccessMessage />;
  }

  return (
    <Stack direction="column" alignItems="center">
      <Logo />
      <Div sx={styles.wrapper}>
        <Typography align="center" variant="h1" sx={styles.title}>
          {t('auth.signUp.title')}
        </Typography>
        <Stepper connector={null} activeStep={activeStep} sx={styles.stepsList}>
          {Object.keys(SignUpFormStep).map((label, index) => (
            <Step key={label} onClick={() => handleStep(index)}>
              <StepLabel />
            </Step>
          ))}
        </Stepper>
        <FormProvider {...methods}>
          <Form noValidate onSubmit={handleSubmit(onSubmit)}>
            <SignupSteps step={currentStep} />
          </Form>
        </FormProvider>
        <Stack alignItems="center">
          <Typography>
            {t('auth.callToSignIn')}
            <Link href={AuthRoutesPaths.SIGN_IN} sx={styles.signInNowLink}>
              {t('auth.signInNow')}
            </Link>
          </Typography>
        </Stack>
      </Div>
    </Stack>
  );
};
