import {
  Autocomplete as MuiAutocomplete,
  TextField,
  FormHelperText,
  InputLabel,
  ListItem,
  Chip,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
  FormControl,
  AutocompleteChangeReason,
  AutocompleteValue as MuiAutocompleteValue,
  Tooltip,
  Skeleton,
} from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { useEffect, useState, ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useDebouncedCallback } from 'use-debounce';

import { IPaginationOptions } from '@bvi/api-interfaces/response/base';

import { Div } from '../Div';
import { Hightlight } from '../Hightlight';

import { styles } from './styles';
import {
  IAutocompleteValue,
  IAutocompleteProperties,
  AutocompleteValue,
} from './types';

const LIMIT_TEXT_LENGTH = 9;

const getLimitTagsText = (more: number) =>
  more > LIMIT_TEXT_LENGTH ? `${LIMIT_TEXT_LENGTH}+` : `+${more}`;

const defaultSetCustomOptionFields = (entity: IAutocompleteValue) => ({
  id: entity.id,
  name: entity?.name,
});

const defaultGetOptionLabel = (entity: IAutocompleteValue) => entity.name;

export function Autocomplete<
  T extends IAutocompleteValue,
  Multiple extends boolean | undefined = false,
>(properties: IAutocompleteProperties<T, Multiple>): JSX.Element {
  const {
    name,
    label,
    error,
    helperText,
    loadingText = 'Loading...',
    noOptionsText = 'No options',
    onOptionsLoad,
    getOptionLabel = defaultGetOptionLabel,
    placeholder = 'Start typing...',
    multiple,
    renderOption,
    setCustomOptionFields = defaultSetCustomOptionFields,
    variant = 'outlined',
    fullWidth = true,
    resetOnChange,
    required,
    disabled,
    isPrefetching,
    items = [],
  } = properties;
  const [open, setOpen] = useState<boolean>(false);
  const { control, setFocus } = useFormContext();
  const [options, setOptions] = useState<Array<AutocompleteValue>>(items);
  const [meta, setMeta] = useState<IPaginationOptions | null>(null);
  const [page, setPage] = useState<number>(1);
  const [searchString, setSearchString] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const hasNextPage = meta?.totalPages ? meta.totalPages >= page : true;

  const handleGetOptionLabel = (option: T) => {
    const optionLabel = getOptionLabel(option);

    return optionLabel ?? '';
  };

  const handleResetSearchString = () => {
    setSearchString('');
  };

  const handleResetState = () => {
    setOptions([]);
    setPage(1);
    setMeta(null);
  };

  const handleOptionsLoad = async () => {
    setIsLoading(true);

    const result = await onOptionsLoad({ search: searchString, page });

    const response = result?.data?.payload || {};
    setMeta(response?.meta);
    setPage(
      response?.meta?.currentPage ? response?.meta.currentPage + 1 : page,
    );

    setOptions([...options, ...(response?.data ?? [])]);
    setIsLoading(false);
  };

  const debouncedOptionsLoad = useDebouncedCallback(handleOptionsLoad, 300);

  useEffect(() => {
    if (!open) {
      handleResetState();
    }
  }, [open]);

  const [sentryReference, { rootRef }] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage,
    disabled: !open,
    onLoadMore: () => debouncedOptionsLoad(),
  });

  useEffect(() => {
    handleResetState();
  }, [searchString]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = event?.target;

    setSearchString(inputValue);
  };

  const renderSentry = (): ReactNode => {
    return hasNextPage || isLoading ? (
      <ListItem ref={sentryReference} key="sentry">
        {loadingText}
      </ListItem>
    ) : null;
  };

  const defaultRenderOption = (
    optionProperties: React.HTMLAttributes<HTMLLIElement>,
    option: T,
  ) => {
    const { id, name: optionLabel = '' } = setCustomOptionFields(option);

    if (id === 0) {
      return renderSentry();
    }

    return (
      <ListItem {...optionProperties} key={option.id}>
        <Hightlight value={optionLabel ?? ''} filter={searchString} />
      </ListItem>
    );
  };

  const handleRenderOption = (
    optionProperties: React.HTMLAttributes<HTMLLIElement>,
    option: T,
  ) => {
    const { id } = setCustomOptionFields(option);

    if (id === 0) {
      return renderSentry();
    }

    if (isNil(renderOption)) {
      return defaultRenderOption(optionProperties, option);
    }

    return renderOption?.(optionProperties, option);
  };

  const renderTags = (
    tagValues: Array<T>,
    getTagProperties: AutocompleteRenderGetTagProps,
  ) => {
    return tagValues.map((tag, index: number) => {
      const { name: tagLabel } = setCustomOptionFields(tag);

      if (isPrefetching) {
        return <Skeleton sx={styles.chipSkeleton} />;
      }

      return (
        <Tooltip title={tagLabel}>
          <Chip
            {...getTagProperties({ index })}
            variant="filled"
            label={tagLabel}
            sx={styles.chip}
          />
        </Tooltip>
      );
    });
  };

  const handleOnChange = async (
    value: MuiAutocompleteValue<T, Multiple, false, false>,
    reason: AutocompleteChangeReason,
    onChange: (...event: Array<unknown>) => void,
  ) => {
    const isClearReason = reason === 'clear';

    handleResetSearchString();
    await resetOnChange?.(value);
    onChange(value);

    if (isClearReason) {
      setFocus(name);
      setOpen(true);
    }
  };

  const renderTextField = (
    inputParameters: AutocompleteRenderInputParams,
    textFieldPlaceholder: string,
  ) => {
    if (multiple) {
      return (
        <Div sx={styles.textFieldWrapper}>
          <TextField
            {...inputParameters}
            value={searchString}
            size="medium"
            variant={variant}
            error={error}
            onChange={handleInputChange}
            sx={styles.multipleTextField}
          />
          {!searchString && textFieldPlaceholder && (
            <Div sx={styles.placeholder}>{textFieldPlaceholder}</Div>
          )}
        </Div>
      );
    }

    return (
      <TextField
        {...inputParameters}
        value={searchString}
        size="medium"
        variant={variant}
        error={error}
        placeholder={textFieldPlaceholder}
        onChange={handleInputChange}
        sx={styles.singleTextField}
      />
    );
  };

  return (
    <FormControl fullWidth={fullWidth}>
      {label && (
        <InputLabel variant={variant} required={required}>
          {label}
        </InputLabel>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => {
          const isValueEmpty = isNil(value) || isEmpty(value);
          const currentPlaceholder = isValueEmpty ? placeholder : '';

          const _value = multiple ? value ?? [] : value;

          return (
            <MuiAutocomplete<T, Multiple>
              open={open}
              ListboxProps={{ ref: rootRef }}
              onOpen={() => {
                setOpen(true);
              }}
              value={_value}
              onClose={() => {
                handleResetSearchString();
                setOpen(false);
              }}
              filterOptions={(filterOptions) => {
                const shouldRenderSentry =
                  !meta || (meta?.totalPages ? meta.totalPages >= page : false);

                const sentryOption = { id: 0, name: loadingText } as T;

                if (shouldRenderSentry) {
                  filterOptions.push(sentryOption);
                }

                return filterOptions;
              }}
              limitTags={2}
              disabled={disabled}
              getLimitTagsText={getLimitTagsText}
              isOptionEqualToValue={(option: T, optionValue: T) =>
                option.id === optionValue?.id
              }
              getOptionLabel={handleGetOptionLabel}
              options={options as Array<T>}
              loading={isLoading}
              onChange={(event, value, reason) =>
                handleOnChange(value, reason, onChange)
              }
              renderTags={renderTags}
              multiple={multiple}
              disableCloseOnSelect={multiple}
              renderOption={handleRenderOption}
              noOptionsText={noOptionsText}
              renderInput={(inputParameters: AutocompleteRenderInputParams) =>
                renderTextField(inputParameters, currentPlaceholder)
              }
            />
          );
        }}
      />

      {error && <FormHelperText error>{helperText}</FormHelperText>}
    </FormControl>
  );
}
