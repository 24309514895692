import { Grid, Link, Stack, Table, TableBody, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { forwardRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ICaseStatisticsData } from '@bvi/api-interfaces/response/case';
import { ISettingsData } from '@bvi/api-interfaces/response/case-settings';
import { Div, DataPopover, Logo } from '@bvi/common-components';
import { DateFormat } from '@bvi/date-utils';

import { ResultsChart, StatisticsAlert } from '..';
import { ISearchQuery } from '../../lib';
import { i18nInstance } from '../../locales';
import { ResultRow } from '../ResultsTable/ResultRow';
import { ResultsTableHeader } from '../ResultsTable/ResultsTableHeader';

import { SearchCriteria } from './SearchCriteria';
import { styles } from './styles';

export interface ISearchReportParameters {
  statisticsData: ICaseStatisticsData;
  settingsData: ISettingsData;
  searchDate?: dayjs.ConfigType;
  searchParameters?: ISearchQuery;
}

export const SearchReport = forwardRef<HTMLDivElement, ISearchReportParameters>(
  (
    { statisticsData, settingsData, searchDate, searchParameters },
    reference,
  ) => {
    const { t } = useTranslation('', {
      i18n: i18nInstance,
    });

    const currentDate = searchDate ? dayjs(searchDate) : dayjs();
    const formattedDate = currentDate.format(DateFormat.long);

    const headerCells = [
      {
        label: '',
      },
      {
        label: t('results.table.overallAverage'),
      },
      {
        label: t('results.table.median'),
      },
      {
        label: t('results.table.mode'),
      },
      {
        label: t('results.table.middleHalfMean'),
      },
      {
        label: t('results.table.lowestQuartileAverage'),
      },
      {
        label: t('results.table.highestQuartileAverage'),
      },
    ];

    const results = {
      firstRow: {
        label: t('results.table.rowIncludesZeros'),
        cells: [
          {
            value: statisticsData.overallAverage,
          },
          {
            value: statisticsData.overallMedian,
          },
          {
            value: statisticsData.mode,
          },
          {
            value: statisticsData.middleHalfMean,
          },
          {
            value: statisticsData.lowestQuartileAverage,
          },
          {
            value: statisticsData.highestQuartileAverage,
          },
          {
            value: Number.NaN,
          },
        ],
      },
      secondRow: {
        label: t('results.table.rowExcludesZeros'),
        cells: [
          {
            value: statisticsData.overallAverageNoZeros,
          },
          {
            value: statisticsData.overallMedianNoZeros,
          },
          {
            value: statisticsData.modeNoZeros,
          },
          {
            value: statisticsData.middleHalfMeanNoZeros,
          },
          {
            value: statisticsData.lowestQuartileAverageNoZeros,
          },
          {
            value: statisticsData.highestQuartileAverageNoZeros,
          },
        ],
      },
    };

    return (
      <Div id="searchReport" sx={styles.wrapper} ref={reference}>
        <Stack sx={styles.header}>
          <Div sx={styles.logo}>
            <Logo />
          </Div>
          <Stack direction="column" alignItems="flex-end">
            <Typography sx={styles.title}>{t('searchReport.title')}</Typography>
            <Stack direction="row" alignItems="center">
              <Typography variant="h3" sx={styles.label}>
                {t('searchReport.dateOfSearch')}
              </Typography>
              <Typography variant="h3" sx={styles.value}>
                {formattedDate}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Div>
          <SearchCriteria
            data={settingsData}
            searchParameters={searchParameters}
          />
          <Stack direction="row" alignItems="center" my={3}>
            <Stack direction="row" alignItems="center" mr={5}>
              <Typography variant="h3" sx={styles.label}>
                {t('searchReport.numberOfClaims')}
              </Typography>
              <Typography variant="h3" sx={styles.value}>
                {statisticsData.total}
              </Typography>
            </Stack>
          </Stack>
          <StatisticsAlert data={statisticsData} isLoading={false} />
          <DataPopover>
            <Grid p={1}>
              <Table>
                <ResultsTableHeader cells={headerCells} />
                <TableBody>
                  {Object.values(results).map((result) => (
                    <ResultRow
                      key={result.label}
                      label={result.label}
                      cells={result.cells}
                    />
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </DataPopover>
          <ResultsChart data={statisticsData} />
        </Div>
        <Div sx={styles.notes}>
          <Div>
            <Typography sx={styles.notesPlaceholder} component="span">
              {t('searchReport.notes.label')}
            </Typography>
            <Trans
              t={t}
              i18nKey="searchReport.notes.text"
              components={{ mailLink: <Link sx={styles.notesLink} /> }}
              values={{ mail: 'mtburnett@burnettrci.com' }}
            />
          </Div>
        </Div>
      </Div>
    );
  },
);
