import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Stack, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { ActiveCasesTable } from '@bvi/dashboard/feature/cases-table/ui/active-cases-table';
import { ClosedCasesTable } from '@bvi/dashboard/feature/cases-table/ui/closed-cases-table';

import { CasesListTab } from './constants';
import { styles } from './styles';

export const CasesTabs = () => {
  const { t } = useTranslation();

  const [parameters, setSearchParameter] = useSearchParams({
    tab: CasesListTab.ACTIVE,
  });

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: CasesListTab,
  ) => {
    setSearchParameter({ tab: newValue });
  };

  const activeTab = parameters.get('tab') ?? CasesListTab.ACTIVE;

  return (
    <Stack>
      <TabContext value={activeTab}>
        <Box sx={styles.tabs}>
          <TabList onChange={handleChange}>
            <Tab
              label={t('cases.list.active.title')}
              value={CasesListTab.ACTIVE}
            />
            <Tab
              label={t('cases.list.closed.title')}
              value={CasesListTab.CLOSED}
            />
          </TabList>
        </Box>
        <TabPanel value={CasesListTab.ACTIVE} sx={styles.tabPanel}>
          <ActiveCasesTable />
        </TabPanel>
        <TabPanel value={CasesListTab.CLOSED} sx={styles.tabPanel}>
          <ClosedCasesTable />
        </TabPanel>
      </TabContext>
    </Stack>
  );
};
