import * as z from 'zod';

import { IBasePaginationQuery } from '@bvi/api-interfaces/entity/base';
import { PropertyKey } from '@bvi/api-interfaces/entity/case-property';
import { IPaginationOptions } from '@bvi/api-interfaces/response/base';
import {
  ICaseStatisticsData,
  ICountryData,
  IRegionData,
} from '@bvi/api-interfaces/response/case';
import { AutocompleteValue, IAutocompleteValue } from '@bvi/common-components';

import { searchFormPropertySchemas } from './schema';

export interface ITerritoryValue extends AutocompleteValue {
  country: ICountryData;
  region: IRegionData;
}

export type OnOptionsLoad = (
  entity: PropertyKey,
  query: {
    page: number;
    search?: string;
    ids?: Array<number>;
    countryIds?: ISearchFormDataSchema['country'];
    regionIds?: ISearchFormDataSchema['region'];
  },
) => Promise<{
  data: {
    payload: {
      data: Array<IAutocompleteValue>;
      meta: IPaginationOptions;
    };
  };
}>;

const schema = z.object(searchFormPropertySchemas);

export type ISearchFormDataSchema = z.infer<typeof schema>;

export type ISearchQuery = Record<keyof ISearchFormDataSchema, string>;

export interface IGetSettingsQuery {
  pagination: Partial<
    Record<
      PropertyKey,
      IBasePaginationQuery & {
        ids?: string;
        countryIds?: string;
        regionIds?: string;
      }
    >
  >;
  includes: Array<PropertyKey>;
}

export type IRequiredNonZeroStatistics = {
  [K in keyof Pick<
    ICaseStatisticsData,
    | 'overallMinimumPublic'
    | 'overallMaximumPublic'
    | 'overallMedian'
    | 'lowestQuartileAverage'
    | 'highestQuartileAverage'
    | 'bottomConfidentialCasesCount'
    | 'topConfidentialCasesCount'
  >]: Exclude<ICaseStatisticsData[K], null>;
};

export interface ISearchContext {
  onSubmit: (data: ISearchFormDataSchema) => void;
  route: string;
  allowToSearchConfidential?: boolean;
}

export type IRequiredStatistics = {
  [K in keyof ICaseStatisticsData]: Exclude<ICaseStatisticsData[K], null>;
};
