export const styles = {
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'primary.main',
    padding: '8px 32px',
  },
  item: {
    ml: 8,
  },
};
