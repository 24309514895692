import { CircularProgress, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useUserCaseQuery } from '@bvi/dashboard/entities/case/api-slice';
import { selectUserCase } from '@bvi/dashboard/entities/case/selectors';
import { CasesEditForm } from '@bvi/dashboard/widgets/cases-form/ui/edit-form';

export const EditCasePage = () => {
  const { id } = useParams();
  const userCaseId = Number(id);
  const { isLoading } = useUserCaseQuery({ id: userCaseId });

  const userCase = useSelector(selectUserCase(userCaseId));

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!userCase) {
    return <div>Case not found</div>;
  }

  return (
    <Stack direction="column">
      <CasesEditForm userCase={userCase} />
    </Stack>
  );
};
