import { Skeleton, TableCell, TableRow } from '@mui/material';
import times from 'lodash/times';
import { FC } from 'react';

import { ITableRowComponentProperties } from './types';

export const TableRowSkeleton: FC<ITableRowComponentProperties> = ({
  columns,
  ...properties
}) => {
  return (
    <TableRow>
      {times(columns, (index) => (
        <TableCell key={index}>
          <Skeleton {...properties} />
        </TableCell>
      ))}
    </TableRow>
  );
};
