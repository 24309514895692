import { createSelector } from '@reduxjs/toolkit';

import { IUserCasesQuery } from '@bvi/api-interfaces/request/case';
import {
  IUserCaseNoteData,
  IUserCaseNotesDataResponse,
  IUserCaseWithCaseData,
} from '@bvi/api-interfaces/response/case';

import { casesApi } from './api-slice';

export const selectCasesListResult = (query: IUserCasesQuery) =>
  casesApi.endpoints.casesList.select(query);

export const selectCaseNotes = (data?: IUserCaseNotesDataResponse) => {
  if (!data?.success) {
    return [] as Array<IUserCaseNoteData>;
  }

  return data.payload.data;
};

export const selectCasesListMeta = (query: IUserCasesQuery) =>
  createSelector(selectCasesListResult(query), ({ data }) => {
    if (!data?.success) {
      return {};
    }

    return data.payload.meta;
  });

export const selectCasesTotalCount = (query: IUserCasesQuery) =>
  createSelector(selectCasesListMeta(query), (meta) => {
    return meta.total ?? 0;
  });

export const selectCasesList = (query: IUserCasesQuery) =>
  createSelector(selectCasesListResult(query), ({ data }) => {
    if (!data?.success) {
      return [];
    }

    return data.payload.data;
  });

export const selectUserCase = (id: number) =>
  createSelector(casesApi.endpoints.userCase.select({ id }), ({ data }) => {
    if (!data?.success) {
      return {} as IUserCaseWithCaseData;
    }

    return data.payload.data;
  });
