export enum SignUpFormStep {
  credential = 'credential',
  personal = 'personal',
  company = 'company',
}

export interface ISignupFormData {
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  phone: string;
  companyName: string;
  companySizeId: number;
  role: string;
}
