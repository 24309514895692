import * as Sentry from '@sentry/react';
import { Suspense } from 'react';

const refresh = () => window.location.reload();

const ErrorFallback = () => {
  const span = Sentry.getActiveSpan();

  return (
    <span>
      An error has occurred. Id: {span?.spanId}
      <br />
      <button onClick={refresh}>Refresh</button>
    </span>
  );
};

export const withSentry = (component: () => React.ReactNode) => () => (
  <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
    <Suspense>{component()}</Suspense>
  </Sentry.ErrorBoundary>
);
