import { LicenseInfo } from '@mui/x-license-pro';
import { Fragment, StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';

import { initApp } from './app';
import {
  ENVIRONMENT,
  IS_DEV_MODE,
  MUI_LICENSE_KEY,
  SENTRY_DSN,
  SENTRY_TRACES_SAMPLE_RATE,
} from './config';
import { initSentry } from './shared/lib/sentry';

const renderApp = () => {
  LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);
  initSentry(SENTRY_DSN, ENVIRONMENT, SENTRY_TRACES_SAMPLE_RATE);

  const AppWrapper = IS_DEV_MODE ? StrictMode : Fragment;
  const AppComponent = initApp();

  const root = ReactDOM.createRoot(
    document.querySelector('#root') as HTMLElement,
  );

  root.render(<AppWrapper>{AppComponent}</AppWrapper>);
};

renderApp();
