import { theme } from '@bvi/mui-theme';

export const styles = {
  wrapper: {
    paddingLeft: 3,
    paddingRight: 3,
    paddingTop: 1,
    paddingBottom: 5,
  },
  subtitle: {
    color: theme.palette.grey[300],
  },
  levelCheckbox: {
    marginRight: '8px',
  },
  submit: {
    marginTop: 2,
  },
};
