import { ComponentsOverrides, ComponentsProps } from '@mui/material';
import { Theme } from '@mui/system';

interface IMuiPaperType {
  defaultProps: ComponentsProps['MuiPaper'];
  styleOverrides: ComponentsOverrides<Theme>['MuiPaper'];
}

export const MuiPaper: IMuiPaperType = {
  defaultProps: {
    variant: 'outlined',
  },
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: theme.palette['background'].paper,
      borderRadius: 10,
      borderColor: theme.palette['grey'][100],
    }),
  },
};
