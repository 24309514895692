export const styles = {
  formWrapper: {
    width: '400px',
  },
  input: {
    my: 2,
    '&:last-of-type': {
      mb: 0,
    },
  },
  title: {
    my: 4,
  },
  registerWrapper: {
    mt: 3,
  },
  forgotPasswordButton: {
    my: 1,
  },
  registerNowLink: {
    fontWeight: 700,
    textDecoration: 'none',
    ml: 1,
  },
};
