import { ComponentsOverrides, ComponentsProps } from '@mui/material';

import { palette } from '../constants/palette';

interface IMuiTabs {
  defaultProps: ComponentsProps['MuiTabs'];
  styleOverrides: ComponentsOverrides['MuiTabs'];
}

export const MuiTabs = {
  defaultProps: {},
  styleOverrides: {
    root: {
      borderBottom: `1px solid ${palette.grey[100]}`,
    },
  },
} satisfies IMuiTabs;
