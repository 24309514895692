import { Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PropertyKey } from '@bvi/api-interfaces/entity/case-property';
import { ICreateCaseRequest } from '@bvi/api-interfaces/request/case';
import { isServerError } from '@bvi/axios-query';
import {
  CasesForm,
  IOnSettingsLoadQuery,
  OnSettingsLoad,
} from '@bvi/cases-form';
import { BackButton, Breadcrumbs } from '@bvi/common-components';
import { useCreateCaseMutation } from '@bvi/dashboard/entities/case/api-slice';
import { useLazyGetSettingsQuery } from '@bvi/dashboard/entities/search/api-slice';
import { DashboardRoutesPaths } from '@bvi/dashboard/shared/constants/routes';
import { NotistackMessageVariants, useNotistack } from '@bvi/notistack';

import { useNavigateAfterSubmit } from '../../lib/navigate-after-submit';

export const CasesCreateForm: React.FC = () => {
  const navigate = useNavigateAfterSubmit();
  const { showNotificationMessage } = useNotistack();
  const [createCaseRequest] = useCreateCaseMutation();
  const [getSettings] = useLazyGetSettingsQuery();
  const { t } = useTranslation('');

  const onSubmitRequest = async (
    data: ICreateCaseRequest,
    shouldSearch?: boolean,
  ) => {
    try {
      const result = await createCaseRequest(data).unwrap();
      const caseId = result.success ? result.payload.data.id : null;

      if (caseId) {
        navigate(caseId, data, shouldSearch);
      }
    } catch (error) {
      if (isServerError(error)) {
        showNotificationMessage(
          { type: error.type },
          NotistackMessageVariants.ERROR,
        );
      }
    }
  };

  const onLoadMoreSettings = async (
    entity: PropertyKey,
    query: IOnSettingsLoadQuery,
  ) => {
    const result = await getSettings({
      includes: [entity],
      pagination: {
        [entity]: {
          page: query.page,
          limit: 100,
          countryIds: query.country?.id,
          regionIds: query.region?.id,
          ids: query.ids?.join(','),
          ...(query.search && {
            names: query.search,
          }),
        },
      },
    });

    const data = result.data?.success ? result.data.payload.data : {};

    return {
      data: {
        payload: {
          data: data[entity]?.items ?? [],
          meta: data[entity]?.meta,
        },
      },
    };
  };

  const title = t('cases.form.title');
  const breadcrumbs = [
    {
      label: t('cases.form.breadcrumbs.cases'),
      link: DashboardRoutesPaths.CASE,
    },
    {
      label: title,
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Breadcrumbs items={breadcrumbs} />
      </Grid>
      <Grid item xs={12} mb={3}>
        <Stack direction="row" alignItems="center">
          <BackButton />
          <Typography variant="h1" ml={1}>
            {title}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <CasesForm
          onSubmitRequest={onSubmitRequest}
          onLoadMoreSettings={onLoadMoreSettings as OnSettingsLoad}
        />
      </Grid>
    </Grid>
  );
};
