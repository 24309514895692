import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Div, PaperModal } from '@bvi/common-components';
import { useGetCaseNotesQuery } from '@bvi/dashboard/entities/case/api-slice';
import { selectCaseNotes } from '@bvi/dashboard/entities/case/selectors';

import { CasesCreateNoteForm } from '../cases-create-note-form';

import { NoteItem } from './note-item';
import { styles } from './styles';

export const CasesDetailsNotes: React.FC = () => {
  const { id: caseId } = useParams();
  const { t } = useTranslation('', { keyPrefix: 'cases.details.notes' });
  const [isOpen, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { data, isFetching } = useGetCaseNotesQuery({ id: Number(caseId) });

  const notes = selectCaseNotes(data);
  const notesCount = notes.length;

  if (isFetching) {
    return <CircularProgress />;
  }

  return (
    <Div>
      <Div sx={styles.titleBlock}>
        <Stack direction="row" alignItems="center">
          <Typography variant="h4">{t('title')}</Typography>
          <Typography component="span" ml={1} color="grey.300">
            {notesCount}
          </Typography>
        </Stack>
        <Button variant="outlined" size="small" onClick={handleOpen}>
          {t('addButton')}
        </Button>
      </Div>
      {notes.map((note) => (
        <NoteItem note={note} key={note.id} />
      ))}

      <PaperModal open={isOpen} onClose={handleClose}>
        <CasesCreateNoteForm onCloseModal={handleClose} />
      </PaperModal>
    </Div>
  );
};
