import { ComponentsOverrides, ComponentsProps } from '@mui/material';
import { Theme } from '@mui/system';

interface IMuiTableCellType {
  defaultProps: ComponentsProps['MuiTableCell'];
  styleOverrides: ComponentsOverrides<Theme>['MuiTableCell'];
}

export const MuiTableCell = {
  defaultProps: {},
  styleOverrides: {
    root: {
      borderBottom: 'none',
    },
    head: ({ theme }) => ({
      paddingTop: '7px',
      paddingBottom: '7px',
      backgroundColor: theme.palette['secondary'].main,
      color: theme.palette['primary'].main,
      fontWeight: 'bold',
    }),
    body: {
      fontSize: '16px',
      paddingTop: '11px',
      paddingBottom: '11px',
    },
  },
} satisfies IMuiTableCellType;
