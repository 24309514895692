import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { isServerError } from '@bvi/axios-query';
import { Div, Form } from '@bvi/common-components';
import { useCreateCaseNoteMutation } from '@bvi/dashboard/entities/case/api-slice';
import { NotistackMessageVariants, useNotistack } from '@bvi/notistack';

import {
  ICreateCaseNoteFormData,
  buildValidationSchema,
  createCaseNoteFormDefaultValues,
} from '../../lib/schema';

import { styles } from './styles';

type CasesCreateNoteFormProperties = {
  onCloseModal: () => void;
};

export const CasesCreateNoteForm: React.FC<CasesCreateNoteFormProperties> = (
  properties,
) => {
  const { id } = useParams();
  const { onCloseModal } = properties;
  const { t } = useTranslation();
  const [createCaseNote] = useCreateCaseNoteMutation();
  const { showNotificationMessage } = useNotistack();

  const onSubmitRequest = async (data: ICreateCaseNoteFormData) => {
    try {
      await createCaseNote({ id: Number(id), data }).unwrap();
      onCloseModal();
    } catch (error) {
      if (isServerError(error)) {
        showNotificationMessage(
          { type: error.type },
          NotistackMessageVariants.ERROR,
        );
      }
    }
  };

  const validationSchema = buildValidationSchema(t);
  const methods = useForm({
    resolver: zodResolver(validationSchema),
    defaultValues: createCaseNoteFormDefaultValues,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmitRequest)} sx={styles.form}>
      <Div>
        <Typography variant="h1" color="primary.main" mb={5}>
          {t('cases.details.notes.form.heading')}
        </Typography>
        <Div sx={styles.row}>
          <TextField
            {...register('title', { required: true })}
            variant="filled"
            label={t('cases.details.notes.form.title.label')}
            placeholder={t('cases.details.notes.form.title.placeholder')}
            error={Boolean(errors.title)}
            helperText={<>{errors.title?.message}</>}
            fullWidth
          />
        </Div>
        <Div sx={styles.row}>
          <TextField
            {...register('text', { required: true })}
            variant="filled"
            label={t('cases.details.notes.form.comments.label')}
            placeholder={t('cases.details.notes.form.comments.placeholder')}
            error={Boolean(errors.text)}
            helperText={<>{errors.text?.message}</>}
            fullWidth
            minRows={3}
            multiline
          />
        </Div>
        <Stack direction="row" justifyContent="space-between">
          <Button variant="outlined" sx={styles.button} onClick={onCloseModal}>
            {t('cases.details.notes.form.buttons.cancel')}
          </Button>
          <Button variant="contained" type="submit" sx={styles.button}>
            {t('cases.details.notes.form.buttons.submit')}
          </Button>
        </Stack>
      </Div>
    </Form>
  );
};
