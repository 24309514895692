import { useTranslation } from 'react-i18next';

import { IUserCaseWithCaseData } from '@bvi/api-interfaces/response/case';
import { DataGrid, DataPopover, Div } from '@bvi/common-components';
import {
  RESOLITION_DETAILS,
  useFormatByType,
  getCaseValue,
} from '@bvi/dashboard/feature/cases-details/lib';

type CasesDetailsResolutionProperties = {
  caseData: IUserCaseWithCaseData;
};

export const CasesDetailsResolution: React.FC<
  CasesDetailsResolutionProperties
> = (properties) => {
  const {
    caseData: { case: _case },
  } = properties;
  const { t } = useTranslation();
  const formatByType = useFormatByType();

  // TODO - resolve value as never if RESOLITION_DETAILS have valueType: boolean
  const resolitionDetails = RESOLITION_DETAILS.map((field) => {
    const value = getCaseValue(_case, field.valuePath) as never;

    const format = formatByType({
      type: field.valueType,
      tKey: field.tKey,
      boolValues: field.boolValues,
    });

    return {
      ...field,
      label: t(field.label),
      value: format(value),
    };
  });

  return (
    <Div>
      <DataPopover>
        <DataGrid
          items={resolitionDetails}
          containerProps={{ my: 1, py: 1, px: 3 }}
          itemsProps={{ py: 2 }}
        />
      </DataPopover>
    </Div>
  );
};
