import { theme } from '@bvi/mui-theme';

export const styles = {
  wrapper: {
    width: '400px',
  },
  checkIcon: {
    width: '150px',
    height: '150px',
    borderRadius: '50%',
    border: `4px solid ${theme.palette.primary.main}`,
    p: 5,
  },
  email: {
    fontSize: '24px',
    fontWeight: '700',
    color: theme.palette.primary.main,
    margin: '0',
  },
};
