import {
  Breadcrumbs,
  Button,
  Link,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ISavedSearchData,
  ISavedSearchEntryData,
} from '@bvi/api-interfaces/response/case';
import { BackButton, ConfirmActionButton } from '@bvi/common-components';
import { useDeleteSavedSearchEntryMutation } from '@bvi/dashboard/entities/saved-searches/api-slice';
import { RepeatSearchButton } from '@bvi/dashboard/feature/saved-searches/ui/repeat-search-button';
import { DashboardRoutesPaths } from '@bvi/dashboard/shared/constants/routes';
import { formatDate } from '@bvi/date-utils';
import { useNavigation } from '@bvi/navigation-utils';

export interface ISavedSearchDetailsHeaderProperties {
  savedSearch?: ISavedSearchData;
  savedSearchEntry?: ISavedSearchEntryData;
  isLoading?: boolean;
  handleDownloadPdf: () => void;
}

export const SavedSearchDetailsHeader: FC<
  ISavedSearchDetailsHeaderProperties
> = ({ savedSearch, savedSearchEntry, isLoading, handleDownloadPdf }) => {
  const { t } = useTranslation();

  const navigate = useNavigation();

  const [deleteSavedSearchEntry] = useDeleteSavedSearchEntryMutation();
  const handleDeleteEntry = async () => {
    if (savedSearch && savedSearchEntry) {
      await deleteSavedSearchEntry({
        searchId: savedSearch.id,
        entryId: savedSearchEntry.id,
      });
      navigate(DashboardRoutesPaths.SAVED_SEARCHES);
    }
  };

  const isShowLoading = Boolean(isLoading) || !savedSearch || !savedSearchEntry;

  return (
    <Stack>
      {isShowLoading ? (
        <Skeleton width="15em" />
      ) : (
        <Breadcrumbs separator="|">
          <Link href={DashboardRoutesPaths.SAVED_SEARCHES} underline="hover">
            <Typography fontWeight="lighter">
              {t('savedSearchDetails.title')}
            </Typography>
          </Link>
          <Typography fontWeight="lighter">{savedSearch.name}</Typography>
        </Breadcrumbs>
      )}

      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h1">
          {isShowLoading ? (
            <Skeleton width="20em" />
          ) : (
            <Stack direction="row" alignItems="center">
              <BackButton />
              {savedSearch.name}: {formatDate(savedSearchEntry.createdAt)}
            </Stack>
          )}
        </Typography>
        <Stack direction="row" spacing={2}>
          <RepeatSearchButton
            savedSearch={savedSearch}
            disabled={isShowLoading}
            variant="outlined"
          >
            {t('savedSearchDetails.buttons.searchAgain.caption')}
          </RepeatSearchButton>
          <Button variant="outlined" onClick={handleDownloadPdf}>
            {t('savedSearchDetails.buttons.printPdf.caption')}
          </Button>
          <ConfirmActionButton
            question={t('savedSearchDetails.buttons.deleteSearch.question')}
            confirm={t('dialog.confirm')}
            cancel={t('dialog.cancel')}
            onClick={handleDeleteEntry}
            disabled={isShowLoading}
            color="error"
            variant="outlined"
          >
            {t('savedSearchDetails.buttons.deleteSearch.caption')}
          </ConfirmActionButton>
        </Stack>
      </Stack>
    </Stack>
  );
};
