import { Box, Modal, Paper } from '@mui/material';
import { FC } from 'react';

import { styles } from './styles';
import { IPaperModalProperties } from './types';

export const PaperModal: FC<IPaperModalProperties> = ({
  children,
  ...properties
}) => {
  return (
    <Modal {...properties} disableRestoreFocus>
      <Box component={Paper} sx={styles.container}>
        {children}
      </Box>
    </Modal>
  );
};
