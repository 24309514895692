import { Grid } from '@mui/material';

type SearchLayoutProperties = {
  filters: React.ReactNode;
  results?: React.ReactNode;
  casesTable?: React.ReactNode;
};

export const SearchLayout: React.FC<SearchLayoutProperties> = (properties) => {
  const { filters, results, casesTable } = properties;

  return (
    <Grid container my={3} columnSpacing={4}>
      {filters}
      <Grid item xs={9} my={3}>
        {results}
        {casesTable}
      </Grid>
    </Grid>
  );
};
