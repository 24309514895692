import MuiCloseIcon from '@mui/icons-material/Close';
import { Button, Skeleton, Stack, Typography } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import { parse } from 'qs';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { ISettingsData } from '@bvi/api-interfaces/response/case-settings';
import { Div, close as CloseIcon, Image } from '@bvi/common-components';
import { useNavigation } from '@bvi/navigation-utils';

import {
  getTagListByFormData,
  ISearchFormDataSchema,
  useSearchContext,
} from '../../lib';
import { i18nInstance } from '../../locales';

import { styles } from './styles';

export interface ISearchTagsParameters {
  data: ISettingsData;
  isLoading?: boolean;
}

export const SearchTags: React.FC<ISearchTagsParameters> = (properties) => {
  const { data, isLoading } = properties;
  const { t } = useTranslation('', { i18n: i18nInstance });
  const navigate = useNavigation();
  const { route } = useSearchContext();

  const location = useLocation();
  const searchParameters = parse(location.search, {
    comma: true,
    ignoreQueryPrefix: true,
  }) as Record<keyof ISearchFormDataSchema, string>;
  const searchParametersWithoutPagination = omit(searchParameters, [
    'limit',
    'page',
    'sort',
  ]);

  const handleResetAll = () => {
    navigate(route, {});
  };

  const handleResetField = (fieldName: keyof ISearchFormDataSchema) => {
    const updatedSearchParameters = omit(searchParameters, [fieldName]);

    navigate(route, updatedSearchParameters);
  };

  const isFilterEmpty = isEmpty(searchParametersWithoutPagination);
  const tags = getTagListByFormData(searchParameters, data, t);

  return (
    <Div>
      {!isFilterEmpty && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h3">{t('filter.title')}</Typography>
          <Button size="small" onClick={handleResetAll} sx={styles.resetButton}>
            <MuiCloseIcon fontSize="small" sx={styles.resetButtonIcon} />
            {t('filter.resetFilterButton')}
          </Button>
        </Stack>
      )}
      <Div sx={styles.list}>
        {tags.map((tag) => {
          if (isNil(tag.value) || isEmpty(String(tag.value))) {
            return null;
          }

          const value = Array.isArray(tag.value)
            ? tag.value?.join(', ')
            : tag.value?.toString();

          return (
            <Div data-testid={`tag_${tag.key}`} key={tag.key} sx={styles.item}>
              <Typography component="span" sx={styles.label}>
                {tag.label}:
              </Typography>
              <Typography component="span" sx={styles.value}>
                {isLoading ? <Skeleton sx={styles.tagSkeleton} /> : value}
                <Image
                  src={CloseIcon}
                  sx={styles.closeIcon}
                  onClick={() =>
                    handleResetField(tag.key as keyof ISearchFormDataSchema)
                  }
                />
              </Typography>
            </Div>
          );
        })}
      </Div>
    </Div>
  );
};
