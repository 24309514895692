import { Stack } from '@mui/material';
import { useSelector } from 'react-redux';

import { useCasesListQuery } from '@bvi/dashboard/entities/case/api-slice';
import { selectCasesTotalCount } from '@bvi/dashboard/entities/case/selectors';
import { CasesHeader } from '@bvi/dashboard/widgets/cases/ui/cases-header';
import { CasesTabs } from '@bvi/dashboard/widgets/cases/ui/cases-tabs';
import { EmptyCasesBar } from '@bvi/dashboard/widgets/cases/ui/empty-cases-bar';

import { styles } from './styles';

const ALL_USER_CASES_QUERY_PARAMETERS = {
  page: 1,
  limit: 1,
};

export const CasesPage = () => {
  const { isLoading } = useCasesListQuery(ALL_USER_CASES_QUERY_PARAMETERS);

  const casesNumber = useSelector(
    selectCasesTotalCount(ALL_USER_CASES_QUERY_PARAMETERS),
  );

  const isEmpty = casesNumber === 0 && !isLoading;

  return (
    <Stack>
      <CasesHeader
        sx={styles.header}
        isLoading={isLoading}
        casesNumber={casesNumber}
      />
      {isEmpty ? <EmptyCasesBar /> : <CasesTabs />}
    </Stack>
  );
};
