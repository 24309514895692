import { TextField, TextFieldProps } from '@mui/material';
import { forwardRef, ChangeEvent } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { PatternFormat, PatternFormatProps } from 'react-number-format';

type ProneTextFieldProperties = TextFieldProps &
  PatternFormatProps & {
    field: string;
  };

export const PhoneTextField = forwardRef<
  HTMLInputElement,
  ProneTextFieldProperties
>((properties, reference) => {
  const { field: fieldName } = properties;

  const { control, setValue } = useFormContext();
  const { field } = useController({
    name: fieldName,
    control,
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setValue(fieldName, value, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  return (
    <PatternFormat
      {...properties}
      {...field}
      customInput={TextField}
      onChange={handleChange}
      getInputRef={reference}
    />
  );
});
