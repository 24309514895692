import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Div } from '@bvi/common-components';
import { useGetCompanySizesQuery } from '@bvi/dashboard/entities/clients/api-slice';
import { selectCompanySizes } from '@bvi/dashboard/entities/clients/selectors';

import { styles } from '../styles';

export const CompanyStep: React.FC = () => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const { data: companySizes } = useGetCompanySizesQuery(undefined, {
    selectFromResult: ({ data }) => ({ data: selectCompanySizes(data) }),
  });

  return (
    <Div>
      <TextField
        {...register('companyName')}
        variant="outlined"
        label={t('auth.signUp.form.companyName.label')}
        placeholder={t('auth.signUp.form.companyName.placeholder')}
        error={Boolean(errors.companyName)}
        helperText={<>{errors.companyName?.message}</>}
        sx={styles.input}
        fullWidth
      />
      <FormControl fullWidth sx={styles.input}>
        <InputLabel
          id="select-company-size"
          error={Boolean(errors.companySizeId)}
        >
          {t('auth.signUp.form.companySize.label')}
        </InputLabel>
        <Select
          {...register('companySizeId', { valueAsNumber: true })}
          label={t('auth.signUp.form.companySize.label')}
          labelId="select-company-size"
          placeholder={t('auth.signUp.form.companySize.placeholder')}
          error={Boolean(errors.companySizeId)}
          defaultValue={companySizes[0]?.id}
        >
          {companySizes.map((companySize) => (
            <MenuItem key={companySize.id} value={companySize.id}>
              {companySize.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        {...register('role')}
        variant="outlined"
        label={t('auth.signUp.form.role.label')}
        placeholder={t('auth.signUp.form.role.placeholder')}
        error={Boolean(errors.role)}
        helperText={<>{errors.role?.message}</>}
        sx={styles.input}
        fullWidth
      />
      <Button type="submit" variant="contained" fullWidth sx={styles.button}>
        {t('auth.signUp.form.submit')}
      </Button>
    </Div>
  );
};
