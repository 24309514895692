import { ComponentsOverrides, ComponentsProps } from '@mui/material';
import { Theme } from '@mui/system';

interface IMuiRadioType {
  defaultProps: ComponentsProps['MuiRadio'];
  styleOverrides: ComponentsOverrides<Theme>['MuiRadio'];
}

export const MuiRadio = {
  styleOverrides: {
    root: {
      '& svg': {
        width: '0.9em',
        height: '0.9em',
      },
      '&.Mui-checked': {
        '& svg:last-child': {
          transform: 'scale(0.9)',
        },
      },
    },
  },
  defaultProps: {
    disableRipple: true,
  },
} satisfies IMuiRadioType;
