import { Grid, TextField, Typography } from '@mui/material';
import dayjs from 'dayjs';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  VictimInfoAgeCategoryVariantKeys,
  VictimInfoGenderVariantKeys,
  VictimInfoKeys,
} from '@bvi/api-interfaces/entity/case-property';
import { Div, FormDatePicker, Select2 } from '@bvi/common-components';
import { useFormOptions } from '@bvi/form-utils';

import { ICaseFormData } from '../../../lib/types';
import { styles } from '../styles';

export const ClaimantInformation = () => {
  const { t } = useTranslation('translation');

  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<ICaseFormData>();

  const startedAbuseAt = watch(VictimInfoKeys.STARTED_ABUSE_AT);

  const genderOptions = useFormOptions(
    omit(VictimInfoGenderVariantKeys, 'UNKNOWN'),
    'gender',
  );

  const ageCategoryOptions = useFormOptions(
    VictimInfoAgeCategoryVariantKeys,
    'ageCategory',
    'ageCategoryDescription',
  );

  return (
    <Div>
      <Typography variant="body1" sx={styles.title}>
        {t('cases.form.claimantInformation.title')}
      </Typography>
      <Grid container spacing={2} my={1}>
        <Grid item xs={6}>
          <Select2
            name={VictimInfoKeys.GENDER}
            variant="filled"
            fullWidth
            items={genderOptions}
            label={t('cases.form.claimantInformation.gender.label')}
            placeholder={t('cases.form.claimantInformation.gender.placeholder')}
            helperText={<>{errors[VictimInfoKeys.GENDER]?.message}</>}
            error={Boolean(errors[VictimInfoKeys.GENDER])}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDatePicker
            field={VictimInfoKeys.BIRTH_DATE}
            label={t('cases.form.claimantInformation.birthDate.label')}
            fullWidth
            valueFormat="jsDate"
            variant="filled"
            placeholder={t(
              'cases.form.claimantInformation.birthDate.placeholder',
            )}
            helperText={<>{errors[VictimInfoKeys.BIRTH_DATE]?.message}</>}
            error={Boolean(errors[VictimInfoKeys.BIRTH_DATE])}
            maxDate={dayjs(new Date())}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} my={1}>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                {...register(VictimInfoKeys.AGE_AT_START_OF_ABUSE, {
                  setValueAs: (value) => (value ? Number(value) : null),
                })}
                variant="filled"
                type="number"
                inputProps={{ min: 0, max: 999 }}
                fullWidth
                label={t(
                  'cases.form.claimantInformation.ageAtStartOfAbuse.label',
                )}
                placeholder={t(
                  'cases.form.claimantInformation.ageAtStartOfAbuse.placeholder',
                )}
                error={Boolean(errors[VictimInfoKeys.AGE_AT_START_OF_ABUSE])}
                helperText={
                  <>{errors[VictimInfoKeys.AGE_AT_START_OF_ABUSE]?.message}</>
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                {...register(VictimInfoKeys.AGE_AT_END_OF_ABUSE, {
                  setValueAs: (value) => (value ? Number(value) : null),
                })}
                variant="filled"
                type="number"
                inputProps={{ min: 0, max: 999 }}
                fullWidth
                label={t(
                  'cases.form.claimantInformation.ageAtEndOfAbuse.label',
                )}
                placeholder={t(
                  'cases.form.claimantInformation.ageAtEndOfAbuse.placeholder',
                )}
                error={Boolean(errors[VictimInfoKeys.AGE_AT_END_OF_ABUSE])}
                helperText={
                  <>{errors[VictimInfoKeys.AGE_AT_END_OF_ABUSE]?.message}</>
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Select2
            name={VictimInfoKeys.AGE_CATEGORY}
            variant="filled"
            fullWidth
            items={ageCategoryOptions}
            label={t('cases.form.claimantInformation.ageCategory.label')}
            placeholder={t(
              'cases.form.claimantInformation.ageCategory.placeholder',
            )}
            helperText={<>{errors[VictimInfoKeys.AGE_CATEGORY]?.message}</>}
            error={Boolean(errors[VictimInfoKeys.AGE_CATEGORY])}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} my={1}>
        <Grid item xs={3}>
          <FormDatePicker
            field={VictimInfoKeys.STARTED_ABUSE_AT}
            fullWidth
            variant="filled"
            valueFormat="jsDate"
            label={t('cases.form.claimantInformation.startedAbuseAt.label')}
            placeholder={t(
              'cases.form.claimantInformation.startedAbuseAt.placeholder',
            )}
            error={Boolean(errors[VictimInfoKeys.STARTED_ABUSE_AT])}
            helperText={<>{errors[VictimInfoKeys.STARTED_ABUSE_AT]?.message}</>}
            maxDate={dayjs(new Date())}
          />
        </Grid>
        <Grid item xs={3}>
          <FormDatePicker
            field={VictimInfoKeys.END_OF_ABUSE_AT}
            fullWidth
            variant="filled"
            valueFormat="jsDate"
            placeholder={t(
              'cases.form.claimantInformation.endOfAbuseAt.placeholder',
            )}
            error={Boolean(errors[VictimInfoKeys.END_OF_ABUSE_AT])}
            helperText={<>{errors[VictimInfoKeys.END_OF_ABUSE_AT]?.message}</>}
            minDate={dayjs(startedAbuseAt)}
            maxDate={dayjs(new Date())}
            disabled={isNil(startedAbuseAt)}
          />
        </Grid>
      </Grid>
    </Div>
  );
};
