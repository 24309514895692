import { SxProperties } from '@bvi/mui-theme';

export const styles = {
  wrapper: {
    paddingLeft: 3,
    paddingRight: 3,
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
} satisfies SxProperties;
