export const styles = {
  logo: {
    width: '176px',
    marginY: 1,
  },
  whiteLogo: {
    width: '176px',
    margin: 0,
  },
};
