import { Stack } from '@mui/material';

import { CasesDetails } from '@bvi/dashboard/widgets/cases-details';

export const CasesDetailsPage = () => {
  return (
    <Stack direction="column">
      <CasesDetails />
    </Stack>
  );
};
