import {
  AbuseDetailsKeys,
  LegalDetailsKeys,
  PerpetratorInfoKeys,
  StaticPropertyKey,
  VictimInfoKeys,
} from '@bvi/api-interfaces/entity/case-property';

import { CasesDataType } from './types';

export const CASE_INFORMATION = [
  {
    label: 'cases.details.caseInformation.claimantName',
    valueType: CasesDataType.string,
    valuePath: ['claimantName'],
    arrayItemValuePath: '',
    tKey: '',
    boolValues: [],
    width: 2.4,
  },
  {
    label: 'cases.details.caseInformation.caseId',
    valueType: CasesDataType.string,
    valuePath: ['name'],
    arrayItemValuePath: '',
    tKey: '',
    boolValues: [],
    width: 2.4,
  },
  {
    label: 'cases.details.caseInformation.createdAt',
    valueType: CasesDataType.date,
    valuePath: ['createdAt'],
    arrayItemValuePath: '',
    tKey: '',
    boolValues: [],
    width: 2.4,
  },
  {
    label: 'cases.details.caseInformation.updatedAt',
    valueType: CasesDataType.date,
    valuePath: ['updatedAt'],
    arrayItemValuePath: '',
    tKey: '',
    boolValues: [],
    width: 2.4,
  },
  {
    label: 'cases.details.caseInformation.status',
    valueType: CasesDataType.translation,
    valuePath: ['status'],
    arrayItemValuePath: '',
    tKey: 'cases.common.status',
    boolValues: [],
    width: 2.4,
  },
] as const;

export const CLAIMANT_INFORMATION = [
  {
    label: 'cases.details.claimantInformation.gender',
    valueType: CasesDataType.translation,
    valuePath: ['properties', VictimInfoKeys.GENDER, 'value'],
    arrayItemValuePath: '',
    tKey: 'gender',
    boolValues: [],
    width: 2.4,
  },
  {
    label: 'cases.details.claimantInformation.birthdate',
    valueType: CasesDataType.date,
    valuePath: ['properties', VictimInfoKeys.BIRTH_DATE, 'value'],
    arrayItemValuePath: '',
    tKey: '',
    boolValues: [],
    width: 2.4,
  },
  {
    label: 'cases.details.claimantInformation.ageAtStartOfAbuse',
    valueType: CasesDataType.range,
    valuePath: [
      ['properties', VictimInfoKeys.AGE_AT_START_OF_ABUSE, 'value'],
      ['properties', VictimInfoKeys.AGE_AT_END_OF_ABUSE, 'value'],
    ],
    arrayItemValuePath: '',
    tKey: '',
    boolValues: [],
    width: 2.4,
  },
  {
    label: 'cases.details.claimantInformation.abusePeriod',
    valueType: CasesDataType.date,
    valuePath: ['properties', VictimInfoKeys.STARTED_ABUSE_AT, 'value'],
    arrayItemValuePath: '',
    tKey: '',
    boolValues: [],
    width: 2.4,
  },
  {
    label: 'cases.details.claimantInformation.ageCategory',
    valueType: CasesDataType.translation,
    valuePath: ['properties', VictimInfoKeys.AGE_CATEGORY, 'value'],
    arrayItemValuePath: '',
    tKey: 'ageCategory',
    boolValues: [],
    width: 2.4,
  },
] as const;

export const PERPETRATOR_INFORMATION = [
  {
    label: 'cases.details.perpetratorInformation.nameOfPerpetrator',
    valueType: CasesDataType.string,
    valuePath: ['properties', PerpetratorInfoKeys.NAME, 'value'],
    arrayItemValuePath: '',
    tKey: '',
    boolValues: [],
    width: 3,
  },
  {
    label: 'cases.details.perpetratorInformation.perpetratorsJob',
    valueType: CasesDataType.string,
    valuePath: [StaticPropertyKey.JOB_TITLE, 'name'],
    arrayItemValuePath: '',
    tKey: '',
    boolValues: [],
    width: 3,
  },
  {
    label: 'cases.details.perpetratorInformation.religiousOrder',
    valueType: CasesDataType.string,
    valuePath: [StaticPropertyKey.ORDER, 'name'],
    arrayItemValuePath: '',
    tKey: '',
    boolValues: [],
    width: 3,
  },
  {
    label: 'cases.details.perpetratorInformation.organization',
    valueType: CasesDataType.string,
    valuePath: [StaticPropertyKey.ORGANIZATION, 'name'],
    arrayItemValuePath: '',
    tKey: '',
    boolValues: [],
    width: 3,
  },
  {
    label: 'cases.details.perpetratorInformation.organizationType',
    valueType: CasesDataType.string,
    valuePath: [StaticPropertyKey.ORGANIZATION_TYPE, 'name'],
    arrayItemValuePath: '',
    tKey: '',
    boolValues: [],
    width: 3,
  },
  {
    label: 'cases.details.perpetratorInformation.subsidiary',
    valueType: CasesDataType.string,
    valuePath: [StaticPropertyKey.DIOCESE, 'name'],
    arrayItemValuePath: '',
    tKey: '',
    boolValues: [],
    width: 3,
  },
] as const;

export const TYPE_OF_ABUSE = [
  {
    label: 'cases.details.typeOfAbuse.detailsAvailable',
    valueType: CasesDataType.translation,
    valuePath: ['properties', AbuseDetailsKeys.DETAIL_AVAILABLE, 'value'],
    arrayItemValuePath: '',
    tKey: 'agreementValues',
    boolValues: [],
    width: 12,
  },
  {
    label: 'cases.details.typeOfAbuse.type',
    valueType: CasesDataType.string,
    valuePath: [],
    arrayItemValuePath: '',
    tKey: '',
    boolValues: [],
    width: 12,
  },
  {
    label: 'cases.details.typeOfAbuse.level1',
    valueType: CasesDataType.list,
    valuePath: ['properties', AbuseDetailsKeys.LEVEL_1, 'value'],
    arrayItemValuePath: '',
    tKey: 'abuseTypeItems',
    boolValues: [],
    width: 2.4,
  },
  {
    label: 'cases.details.typeOfAbuse.level2',
    valueType: CasesDataType.list,
    valuePath: ['properties', AbuseDetailsKeys.LEVEL_2, 'value'],
    arrayItemValuePath: '',
    tKey: 'abuseTypeItems',
    boolValues: [],
    width: 2.4,
  },
  {
    label: 'cases.details.typeOfAbuse.level3',
    valueType: CasesDataType.list,
    valuePath: ['properties', AbuseDetailsKeys.LEVEL_3, 'value'],
    arrayItemValuePath: '',
    tKey: 'abuseTypeItems',
    boolValues: [],
    width: 2.4,
  },
  {
    label: 'cases.details.typeOfAbuse.level4',
    valueType: CasesDataType.list,
    valuePath: ['properties', AbuseDetailsKeys.LEVEL_4, 'value'],
    arrayItemValuePath: '',
    tKey: 'abuseTypeItems',
    boolValues: [],
    width: 2.4,
  },
  {
    label: 'cases.details.typeOfAbuse.level5',
    valueType: CasesDataType.list,
    valuePath: ['properties', AbuseDetailsKeys.LEVEL_5, 'value'],
    arrayItemValuePath: '',
    tKey: 'abuseTypeItems',
    boolValues: [],
    width: 2.4,
  },
  {
    label: 'cases.details.typeOfAbuse.numberOfIncidents',
    valueType: CasesDataType.range,
    valuePath: [
      ['properties', AbuseDetailsKeys.NUMBER_OF_INCIDENTS_LOW, 'value'],
      ['properties', AbuseDetailsKeys.NUMBER_OF_INCIDENTS_HIGH, 'value'],
    ],
    arrayItemValuePath: '',
    tKey: '',
    boolValues: [],
    width: 2.4,
  },
  {
    label: 'cases.details.typeOfAbuse.numberOfPerpetrators',
    valueType: CasesDataType.range,
    valuePath: [
      ['properties', AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_LOW, 'value'],
      ['properties', AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_HIGH, 'value'],
    ],
    arrayItemValuePath: '',
    tKey: '',
    boolValues: [],
    width: 2.4,
  },
] as const;

export const LEGAL_DETAILS = [
  {
    label: 'cases.details.legalDetails.lawsuitFiled',
    valueType: CasesDataType.translation,
    valuePath: ['properties', LegalDetailsKeys.LAWSUIT_FILED, 'value'],
    arrayItemValuePath: '',
    tKey: 'agreementValues',
    boolValues: [],
    width: 2.5,
  },
  {
    label: 'cases.details.legalDetails.hasClaimantRetainedAnAttorney',
    valueType: CasesDataType.translation,
    valuePath: ['properties', LegalDetailsKeys.HAS_ATTORNEY, 'value'],
    arrayItemValuePath: '',
    tKey: 'agreementValues',
    boolValues: [],
    width: 6,
  },
  {
    label: 'cases.details.legalDetails.liabilityDefense',
    valueType: CasesDataType.array,
    valuePath: [StaticPropertyKey.LIABILITY_DEFENSES],
    arrayItemValuePath: 'name',
    tKey: '',
    boolValues: [],
    width: 4,
  },
  {
    label: 'cases.details.legalDetails.exceptions',
    valueType: CasesDataType.string,
    valuePath: [StaticPropertyKey.EXCEPTION, 'name'],
    arrayItemValuePath: '',
    tKey: '',
    boolValues: [],
    width: 4,
  },
  {
    label: 'cases.details.legalDetails.perpetrator',
    valueType: CasesDataType.translation,
    valuePath: ['properties', LegalDetailsKeys.PREPARATOR, 'value'],
    arrayItemValuePath: '',
    tKey: 'perpetrator',
    boolValues: [],
    width: 12,
  },
] as const;

export const RESOLITION_DETAILS = [
  {
    label: 'cases.details.resolutionDetails.date',
    valueType: CasesDataType.date,
    valuePath: ['resolution', 'resolutionDate'],
    arrayItemValuePath: '',
    tKey: '',
    boolValues: [],
    width: 2.4,
  },
  {
    label: 'cases.details.resolutionDetails.totalResolutionAmount',
    valueType: CasesDataType.currency,
    valuePath: ['resolution', 'total'],
    tKey: '',
    boolValues: [],
    width: 2.4,
  },
  {
    label: 'cases.details.resolutionDetails.numberOfClaimants',
    valueType: CasesDataType.string,
    valuePath: ['resolution', 'claimantsCount'],
    arrayItemValuePath: '',
    tKey: '',
    boolValues: [],
    width: 2.4,
  },
  {
    label: 'cases.details.resolutionDetails.amountPerClaimant',
    valueType: CasesDataType.currency,
    valuePath: ['resolution', 'perClaimant'],
    tKey: '',
    boolValues: [],
    width: 2.4,
  },
  {
    label: 'cases.details.resolutionDetails.totalDefenseCosts',
    valueType: CasesDataType.currency,
    valuePath: ['resolution', 'defenseCosts'],
    tKey: '',
    boolValues: [],
    width: 2.4,
  },
  {
    label: 'cases.details.resolutionDetails.typeOfResolution',
    valueType: CasesDataType.string,
    valuePath: ['resolution', 'type', 'name'],
    arrayItemValuePath: '',
    tKey: '',
    boolValues: [],
    width: 2.4,
  },
  {
    label: 'cases.details.resolutionDetails.privacyType',
    valueType: CasesDataType.boolean,
    valuePath: ['resolution', 'isPublic'],
    arrayItemValuePath: '',
    tKey: 'confidentiality',
    boolValues: ['public', 'confidential'],
    width: 2.4,
  },
] as const;
