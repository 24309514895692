import { Stack, Typography, Skeleton, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Span } from '@bvi/common-components';
import { DashboardRoutesPaths } from '@bvi/dashboard/shared/constants/routes';
import { SxProperties } from '@bvi/mui-theme';
import { useNavigation } from '@bvi/navigation-utils';

import { styles } from './styles';

export interface ICasesHeaderProperties {
  sx?: SxProperties;
  casesNumber?: number;
  isLoading?: boolean;
}

export const CasesHeader = ({
  sx,
  casesNumber,
  isLoading,
}: ICasesHeaderProperties) => {
  const { t } = useTranslation();
  const navigate = useNavigation();

  const showButton = casesNumber !== 0;

  const handleCreateCase = () => {
    navigate(DashboardRoutesPaths.CASES_CREATE);
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={sx}
    >
      <Typography variant="h1" my={3}>
        <Span>{t('cases.list.title')}</Span>
        {isLoading ? (
          <Skeleton sx={styles.skeleton} width="2em" />
        ) : (
          <Span data-testid="cases.list.count" sx={styles.count}>
            {casesNumber}
          </Span>
        )}
      </Typography>
      {showButton && (
        <Button variant="outlined" onClick={handleCreateCase}>
          {t('cases.list.create')}
        </Button>
      )}
    </Stack>
  );
};
