import { theme } from '@bvi/mui-theme';

export const styles = {
  block: {
    marginTop: 4,
    marginBottom: 4,
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: '10px',
    overflow: 'hidden',
  },
  header: {
    paddingTop: 1,
    paddingBottom: 1,
    paddingLeft: 3,
    paddingRight: 3,
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.common.black,
    fontWeight: '700',
    fontSize: '16px',
  },
  wrapper: {
    padding: 0,
  },
};
