import {
  Paper,
  Skeleton,
  Stack,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';

import { CaseStatus } from '@bvi/api-interfaces/entity/case';
import { IUserCaseWithCaseData } from '@bvi/api-interfaces/response/case';
import {
  LoadingTableBody,
  OverflowingText,
  PaginationTable,
  Span,
} from '@bvi/common-components';
import { useCasesListQuery } from '@bvi/dashboard/entities/case/api-slice';
import {
  selectCasesList,
  selectCasesTotalCount,
} from '@bvi/dashboard/entities/case/selectors';
import { DashboardRoutesPaths } from '@bvi/dashboard/shared/constants/routes';
import { useNavigation } from '@bvi/navigation-utils';

import { ManageCaseMenu } from './manage-case-menu';
import { styles } from './styles';

const ACTIVE_CASES_TABLE_COLUMNS = [
  {
    title: 'claimant',
    width: '20%',
    accessor: (userCase: IUserCaseWithCaseData) => userCase.case.claimantName,
  },
  {
    title: 'caseName',
    width: '20%',
    accessor: (userCase: IUserCaseWithCaseData) => userCase.case.name,
  },
  {
    title: 'description',
    width: '55%',
    accessor: (userCase: IUserCaseWithCaseData) => userCase.case.description,
  },
  {
    width: '5%',
    isSpecial: true,
  },
];

const ACTIVE_STATUSES = [CaseStatus.DRAFT];
const PAGE_LIMIT = 10;

export const ActiveCasesTable = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);

  const query = {
    page,
    limit: PAGE_LIMIT,
    statuses: ACTIVE_STATUSES,
  };

  const { isLoading } = useCasesListQuery(query);
  const navigate = useNavigation();

  const userCases = useSelector(selectCasesList(query));
  const casesNumber = useSelector(selectCasesTotalCount(query));

  const handleRowClick = (id: number) => {
    const path = generatePath(DashboardRoutesPaths.CASES_DETAILS, {
      id: String(id),
    });

    navigate(path);
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  return (
    <Stack>
      <Typography variant="caption" sx={styles.title}>
        <Span>{t('cases.list.active.caption')}</Span>
        {isLoading ? (
          <Skeleton sx={styles.skeleton} width="1em" />
        ) : (
          <Span data-testid="cases.list.active.count" sx={styles.count}>
            {casesNumber}
          </Span>
        )}
      </Typography>
      <TableContainer component={Paper}>
        <PaginationTable
          stickyHeader
          count={casesNumber}
          page={page}
          onPageChange={handlePageChange}
        >
          <TableHead>
            <TableRow>
              {ACTIVE_CASES_TABLE_COLUMNS.map((column) => (
                <TableCell key={nanoid()} align="left" width={column.width}>
                  {column.title && t(`cases.list.active.table.${column.title}`)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <LoadingTableBody
            rows={2}
            cols={ACTIVE_CASES_TABLE_COLUMNS.length + 1}
            isLoading={isLoading}
            rowSx={styles.row}
          >
            {userCases.map((userCase) => (
              <TableRow
                hover
                key={userCase.id}
                aria-label={`go-to-case-${userCase.id}-details`}
              >
                {ACTIVE_CASES_TABLE_COLUMNS.filter(
                  (column) => !column.isSpecial,
                ).map((column) => (
                  <TableCell
                    sx={styles.activeCell}
                    key={column.title}
                    onClick={() => handleRowClick(userCase.id)}
                  >
                    <OverflowingText text={column.accessor?.(userCase) ?? ''} />
                  </TableCell>
                ))}
                <TableCell>
                  <ManageCaseMenu case={userCase} />
                </TableCell>
              </TableRow>
            ))}
          </LoadingTableBody>
        </PaginationTable>
      </TableContainer>
    </Stack>
  );
};
