import { ComponentsOverrides, ComponentsProps } from '@mui/material';

import { palette } from '../constants';

interface IMuiOutlinedInput {
  defaultProps: ComponentsProps['MuiOutlinedInput'];
  styleOverrides: ComponentsOverrides['MuiOutlinedInput'];
}

export const MuiOutlinedInput = {
  defaultProps: {
    notched: false,
  },
  styleOverrides: {
    root: {
      color: palette['common'].black,
      borderRadius: 8,
      marginTop: 8,
      padding: 0,
    },
    input: {
      padding: 8,
    },
    notchedOutline: {
      border: '1px solid #DDDFE3',
    },
  },
} satisfies IMuiOutlinedInput;
