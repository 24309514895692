export const styles = {
  textField: {
    maxWidth: '137px',
    '& input': {
      borderRadius: '6px',
    },
    '& input[value]': {
      textTransform: 'lowercase',
    },
    '& input::placeholder': {
      textTransform: 'capitalize',
    },
  },
  textFieldWithClearButton: {
    '&:hover button': {
      display: 'block',
    },
  },
  openPickerButton: {
    mr: 0,
    p: '4px',
    ml: '-8px',
  },
  wrapper: {
    width: '100%',
  },
  fieldRoot: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  endAdornment: {
    position: 'absolute',
    height: '100%',
    maxHeight: '100%',
    right: '5px',
    top: 0,
  },
  clearButton: {
    width: '28px',
    height: '28px',
    display: 'none',
  },
  clearIcon: {
    width: '20px',
    height: '20px',
  },
};
