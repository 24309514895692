import { Button, Link, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Image, check } from '@bvi/common-components';
import { AuthRoutesPaths } from '@bvi/dashboard/shared/constants/routes';

import { styles } from './styles';

export const SuccessMessage = () => {
  const { t } = useTranslation();

  return (
    <Stack direction="column" alignItems="center" sx={styles.wrapper}>
      <Image src={check} sx={styles.checkIcon} />
      <Typography align="center" variant="h1" my={4}>
        {t('auth.restorePassword.success.title')}
      </Typography>
      <Button
        fullWidth
        variant="contained"
        LinkComponent={Link}
        href={AuthRoutesPaths.SIGN_IN}
        sx={styles.button}
      >
        {t('auth.restorePassword.success.button')}
      </Button>
    </Stack>
  );
};
