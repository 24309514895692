import { Button, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { check, Image } from '@bvi/common-components';
import { styles } from '@bvi/dashboard/feature/saved-searches/ui/search-saved-message/styles';
import { DashboardRoutesPaths } from '@bvi/dashboard/shared/constants/routes';
import { useNavigation } from '@bvi/navigation-utils';

interface ISearchSavedMessageParameters {
  onBackToSearch?: () => void;
}

export const SearchSavedMessage: FC<ISearchSavedMessageParameters> = ({
  onBackToSearch,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigation();

  const handleOpenSearches = () => {
    navigate(DashboardRoutesPaths.SAVED_SEARCHES);
  };

  return (
    <Stack
      direction="column"
      alignItems="center"
      spacing={2}
      sx={styles.container}
    >
      <Image src={check} sx={styles.check} />
      <Typography align="center" variant="h1" py={2}>
        {t('savedSearches.searchSavedMessage.title')}
      </Typography>
      <Button variant="contained" fullWidth onClick={onBackToSearch}>
        {t('savedSearches.searchSavedMessage.buttons.backToSearch')}
      </Button>
      <Button
        variant="outlined"
        fullWidth
        onClick={handleOpenSearches}
        autoFocus
      >
        {t('savedSearches.searchSavedMessage.buttons.openSavedSearches')}
      </Button>
    </Stack>
  );
};
