import { PartialDeep } from 'type-fest';

import {
  DeepOmitBaseProperties,
  IBasePaginationQuery,
  OmitBaseProperties,
  IBaseSortQuery,
} from '../entity/base';
import {
  CaseEvents,
  CaseStatus,
  ICase,
  ICountry,
  IRegion,
  IState,
  IUserCaseNote,
} from '../entity/case';
import {
  AbuseDetailsKeys,
  AbuseDetailsLevel1VariantKeys,
  AbuseDetailsLevel2VariantKeys,
  AbuseDetailsLevel3VariantKeys,
  AbuseDetailsLevel4VariantKeys,
  AbuseDetailsLevel5VariantKeys,
  BaseCaseEntityProperties,
  ICaseProperty,
  LegalDetailsKeys,
  LegalDetailsLiabilityDefenseVariantsKey,
  LegalDetailsPreparatorVariantsKey,
  PerpetratorInfoJobVariantKeys,
  PerpetratorInfoKeys,
  PerpetratorInfoReligionOrder,
  PerpetratorInfoSubsidiaryVariantKeys,
  ResolutionPublicityVariantsKey,
  StaticPropertyKey,
  StaticResolutionProperties,
  VictimInfoAgeCategoryVariantKeys,
  VictimInfoGenderVariantKeys,
  VictimInfoKeys,
  YesNoVariantsKey,
} from '../entity/case-property';
import { IResolution } from '../entity/resolution';

export interface ICreateCountryRequest
  extends Omit<OmitBaseProperties<ICountry>, 'regions' | 'states'> {}
export interface IUpdateCountryRequest extends Partial<ICreateCountryRequest> {}

export interface ICreateRegionRequest
  extends Omit<OmitBaseProperties<IRegion>, 'states'> {
  countryId: number;
}
export interface IUpdateRegionRequest extends Partial<ICreateRegionRequest> {}

export interface ICreateStateRequest extends OmitBaseProperties<IState> {
  regionId?: number;
}
export interface IUpdateStateRequest extends Partial<ICreateStateRequest> {}

export interface ICreateUserCaseNoteRequest
  extends OmitBaseProperties<IUserCaseNote> {}

export interface ICreateCaseRequest
  extends PartialDeep<
    Omit<
      OmitBaseProperties<ICase>,
      | 'region'
      | 'country'
      | 'state'
      | 'organization'
      | 'organizationType'
      | 'resolution'
      | 'order'
      | 'diocese'
      | 'jobTitle'
      | 'exception'
      | 'liabilityDefenses'
    >
  > {
  name?: ICase['name'];
  isResolved: ICase['isResolved'];
  properties?: ICase['properties'];
  description?: string;
  claimantName?: string;
  countryId?: number | null;
  regionId?: number | null;
  stateId?: number | null;
  churchId?: number | null;
  organizationId?: number | null;
  organizationTypeId?: number | null;
  orderId?: number | null;
  dioceseId?: number | null;
  jobTitleId?: number | null;
  exceptionId?: number | null;
  liabilityDefensesIds?: Array<number> | null;
  resolution?: ICreateResolutionRequest | null;
}

export interface ICreateUserCaseRequest
  extends Omit<ICreateCaseRequest, 'status'> {}

export interface IUpdateCaseRequest extends PartialDeep<ICreateCaseRequest> {
  resolution?: IUpdateResolutionRequest | null;
  status?: CaseStatus;
}

export interface IUpdateUserCaseRequest
  extends Omit<IUpdateCaseRequest, 'status'> {}

export interface ICreateCasePropertyRequest
  extends PartialDeep<OmitBaseProperties<ICaseProperty>> {
  key: ICaseProperty['key'];
  type: ICaseProperty['type'];
}
export interface IUpdateCasePropertyRequest
  extends Partial<ICreateCasePropertyRequest> {}

export interface ICreateResolutionPrice {
  amount: number;
  currencyId: number;
}
export interface ICreateResolutionRequest
  extends DeepOmitBaseProperties<
    Omit<
      IResolution,
      | 'type'
      | 'total'
      | 'perClaimant'
      | 'defenseCosts'
      | 'resolutionDate'
      | 'claimantsCount'
      | 'notes'
    >
  > {
  typeId?: number;
  resolutionDate?: IResolution['resolutionDate'];
  total?: ICreateResolutionPrice | null;
  perClaimant?: ICreateResolutionPrice | null;
  defenseCosts?: ICreateResolutionPrice | null;
  claimantsCount?: IResolution['claimantsCount'];
  notes?: IResolution['notes'] | null;
}
export interface IUpdateResolutionRequest
  extends Partial<ICreateResolutionRequest> {}

export enum CaseQueryInclude {
  CLIENTS = 'clients',
}

export interface ICaseListSearchQuery extends IBasePaginationQuery {
  status?: CaseStatus;
  search?: string;
  include?: string;
}

export interface IUserCasesQuery extends IBasePaginationQuery {
  statuses?: Array<CaseStatus>;
}

export interface IChangeUserCaseStatusRequest {
  event: CaseEvents;
}

export interface IGetCaseRequest {
  id: number;
}

export interface IGetCaseNotesRequest {
  id: number;
}

export interface IGetSavedSearchRequest {
  id: number;
}

export enum AdditionalFilterParameter {
  LEVEL = 'level',
  AMOUNT_LOW = 'amount-low',
  AMOUNT_HIGH = 'amount-high',
  RESOLUTION_DATE_LOW = 'resolution-date-low',
  RESOLUTION_DATE_HIGH = 'resolution-date-high',
  RESOLUTION_PUBLICITY = 'resolution-publicity',
  REFINE_AGE_CATEGORY = 'refine-age-category',
}

export const ALLOWED_AMOUNTS = [
  1, 10_000, 50_000, 100_000, 250_000, 500_000, 1_000_000, 5_000_000,
  10_000_000,
] as const;

export type AllowedAmounts = (typeof ALLOWED_AMOUNTS)[number];

export enum CaseStatisticsLevel {
  // Despite being digits, values are names, not numeric ids
  LEVEL_1 = '1',
  LEVEL_2 = '2',
  LEVEL_3 = '3',
  LEVEL_4 = '4',
  LEVEL_5 = '5',
  OTHER = 'other',
}

export interface IGetCaseStatisticsRequest
  extends Partial<Record<StaticPropertyKey, Array<number>>> {
  // Dynamic properties
  [VictimInfoKeys.AGE_AT_START_OF_ABUSE]?: number;
  [VictimInfoKeys.AGE_AT_END_OF_ABUSE]?: number;
  [VictimInfoKeys.AGE_CATEGORY]?: Array<VictimInfoAgeCategoryVariantKeys>;
  [VictimInfoKeys.BIRTH_DATE]?: Date;
  [VictimInfoKeys.END_OF_ABUSE_AT]?: Date;
  [VictimInfoKeys.STARTED_ABUSE_AT]?: Date;
  [VictimInfoKeys.GENDER]?: Array<VictimInfoGenderVariantKeys>;
  [PerpetratorInfoKeys.JOB]?: Array<PerpetratorInfoJobVariantKeys>;
  [PerpetratorInfoKeys.NAME]?: string;
  [PerpetratorInfoKeys.RELIGION_ORDER]?: Array<PerpetratorInfoReligionOrder>;
  [PerpetratorInfoKeys.SUBSIDIARY]?: Array<PerpetratorInfoSubsidiaryVariantKeys>;
  [AbuseDetailsKeys.DETAIL_AVAILABLE]?: Array<YesNoVariantsKey>;
  [AbuseDetailsKeys.LEVEL_1]?: Array<AbuseDetailsLevel1VariantKeys>;
  [AbuseDetailsKeys.LEVEL_2]?: Array<AbuseDetailsLevel2VariantKeys>;
  [AbuseDetailsKeys.LEVEL_3]?: Array<AbuseDetailsLevel3VariantKeys>;
  [AbuseDetailsKeys.LEVEL_4]?: Array<AbuseDetailsLevel4VariantKeys>;
  [AbuseDetailsKeys.LEVEL_5]?: Array<AbuseDetailsLevel5VariantKeys>;
  [AbuseDetailsKeys.LEVEL_1_UNSPECIFIED]?: Array<YesNoVariantsKey>;
  [AbuseDetailsKeys.LEVEL_2_UNSPECIFIED]?: Array<YesNoVariantsKey>;
  [AbuseDetailsKeys.LEVEL_3_UNSPECIFIED]?: Array<YesNoVariantsKey>;
  [AbuseDetailsKeys.LEVEL_4_UNSPECIFIED]?: Array<YesNoVariantsKey>;
  [AbuseDetailsKeys.LEVEL_5_UNSPECIFIED]?: Array<YesNoVariantsKey>;
  [AbuseDetailsKeys.NUMBER_OF_INCIDENTS_LOW]?: number;
  [AbuseDetailsKeys.NUMBER_OF_INCIDENTS_HIGH]?: number;
  [AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_LOW]?: number;
  [AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_HIGH]?: number;
  [AbuseDetailsKeys.OTHER]?: string;
  [LegalDetailsKeys.DAMAGES_COMMENT]?: string;
  [LegalDetailsKeys.HAS_ATTORNEY]?: Array<YesNoVariantsKey>;
  [LegalDetailsKeys.LAWSUIT_FILED]?: Array<YesNoVariantsKey>;
  [LegalDetailsKeys.LIABILITY_DEFENSE]?: Array<LegalDetailsLiabilityDefenseVariantsKey>;
  [LegalDetailsKeys.PREPARATOR]?: Array<LegalDetailsPreparatorVariantsKey>;
  [LegalDetailsKeys.OTHER_LIFE_STRESS]?: string;
  [LegalDetailsKeys.NOTES]?: string;

  // Additional properties
  [AdditionalFilterParameter.LEVEL]?: Array<CaseStatisticsLevel>;
  [AdditionalFilterParameter.AMOUNT_LOW]?: AllowedAmounts;
  [AdditionalFilterParameter.AMOUNT_HIGH]?: AllowedAmounts;
  [AdditionalFilterParameter.RESOLUTION_DATE_LOW]?: number;
  [AdditionalFilterParameter.RESOLUTION_DATE_HIGH]?: number;
  [AdditionalFilterParameter.RESOLUTION_PUBLICITY]?: Array<ResolutionPublicityVariantsKey>;
}

export type SortableFields =
  | StaticPropertyKey
  | BaseCaseEntityProperties
  | StaticResolutionProperties
  | LegalDetailsKeys
  | VictimInfoKeys
  | AbuseDetailsKeys
  | PerpetratorInfoKeys;

export type ICasesSort = IBaseSortQuery<SortableFields>;

export interface ISortableCaseByFilter {
  sort: ICasesSort;
}

export interface IGetCaseByFilerRequest
  extends IGetCaseStatisticsRequest,
    ISortableCaseByFilter,
    IBasePaginationQuery {}

export interface ICreateCaseNoteRequest {
  id: number;
  data: {
    title: string;
    text: string;
  };
}

export interface IFindSavedSearchesQuery {
  dates?: Array<Date>;
  userCaseIds?: Array<number>;
}

export interface ICreateSavedSearchRequest {
  name: string;
  parameters: IGetCaseStatisticsRequest;
  userCaseId?: number;
}

export interface ICreateSavedSearchEntryRequest {
  savedSearchId: number;
}

export interface ITransitUserCaseRequest {
  event: CaseEvents;
  declineReason?: string;
}
