import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Stack } from '@mui/material';
import { parse } from 'qs';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Div, Form, FormDatePicker } from '@bvi/common-components';
import {
  mapFilterSearchesFormDataToQuery,
  mapFilterSearchesQueryToFormData,
} from '@bvi/dashboard/feature/saved-searches/lib/mappers';
import {
  buildFilterSearchesValidationSchema,
  IFilterSearchesFormData,
  IFilterSearchesQuery,
} from '@bvi/dashboard/feature/saved-searches/lib/schema';
import { DashboardRoutesPaths } from '@bvi/dashboard/shared/constants/routes';
import { useNavigation } from '@bvi/navigation-utils';

import { styles } from './styles';

export interface ISavedSearchFilterParameters {
  isDisabled?: boolean;
}

export const SavedSearchFilter: FC<ISavedSearchFilterParameters> = ({
  isDisabled,
}) => {
  const { t } = useTranslation();

  const navigate = useNavigation();

  const location = useLocation();
  const searchParameters = parse(location.search, {
    ignoreQueryPrefix: true,
  }) as IFilterSearchesQuery;

  const validationSchema = buildFilterSearchesValidationSchema();
  const methods = useForm<IFilterSearchesFormData>({
    resolver: zodResolver(validationSchema),
    defaultValues: mapFilterSearchesQueryToFormData(searchParameters),
  });
  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = (data: IFilterSearchesFormData) => {
    navigate(
      DashboardRoutesPaths.SAVED_SEARCHES,
      mapFilterSearchesFormDataToQuery(data),
    );
  };

  return (
    <FormProvider {...methods}>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Stack
          direction="row"
          justifyContent="end"
          alignItems="end"
          spacing={3}
          my={3}
        >
          <Div sx={styles.datePickerWrapper}>
            <FormDatePicker
              field="date"
              valueFormat="jsDate"
              label={t('savedSearches.filterForm.fields.date.label')}
              placeholder={t(
                'savedSearches.filterForm.fields.date.placeholder',
              )}
              error={Boolean(errors.date)}
              disabled={isDisabled}
              clearable
            />
          </Div>
          <Button
            type="submit"
            variant="contained"
            size="small"
            disabled={isDisabled}
          >
            {t('savedSearches.buttons.applyFilter.caption')}
          </Button>
        </Stack>
      </Form>
    </FormProvider>
  );
};
