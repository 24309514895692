import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

import { IResolutionPrice } from '@bvi/api-interfaces/entity/resolution';
import { ICaseData } from '@bvi/api-interfaces/response/case';
import { Div } from '@bvi/common-components';
import { formatDate } from '@bvi/date-utils';

import { CasesDataType, FormatByTypeOptions, FormatterType } from './types';

export const formatCurrency = (value: IResolutionPrice) => {
  if (!value) {
    return '';
  }

  const {
    amount,
    currency: { symbol },
  } = value;
  const formattedValue = new Intl.NumberFormat('en-EN', {
    maximumFractionDigits: 0,
  }).format(Number(amount));

  return `${symbol}${formattedValue}`;
};

export const getCaseValue = (
  object: ICaseData,
  path: ReadonlyArray<string> | ReadonlyArray<ReadonlyArray<string>>,
) => {
  let value;
  const isArrayOfArrays = Array.isArray(path.at(0));

  if (isArrayOfArrays) {
    const [fromPath, toPath] = path;

    value = [get(object, fromPath, ''), get(object, toPath, '')];
  } else {
    value = get(object, path as Array<string>, '');
  }

  return value;
};

export const useFormatByType = () => {
  const { t } = useTranslation();

  return <T extends CasesDataType>({
    type,
    tKey,
    boolValues,
    arrayItemValuePath = '',
  }: FormatByTypeOptions<T>) => {
    const formatters: FormatterType = {
      [CasesDataType.string]: (value) => value,
      [CasesDataType.date]: (date) => (date ? formatDate(date) : ''),
      [CasesDataType.translation]: (value) =>
        t?.(`${tKey}.${value}`, { defaultValue: '' }),
      [CasesDataType.array]: (list) => {
        if (isEmpty(list)) {
          return '';
        }

        return list?.map((item) => {
          const itemValue = get(item, arrayItemValuePath);

          const label = t?.(`${tKey}.${itemValue}`, {
            defaultValue: itemValue,
          });

          return <Div key={itemValue}>{label}</Div>;
        });
      },
      [CasesDataType.list]: (list) => {
        if (isEmpty(list)) {
          return '';
        }

        return list?.map((item) => {
          const label = t?.(`${tKey}.${item}`, { defaultValue: item });

          return <Div key={item}>{label}</Div>;
        });
      },
      [CasesDataType.boolean]: (value) => {
        const [trueValue, falseValue] = boolValues;
        const currentBoolValue = value ? trueValue : falseValue;

        return t?.(`${tKey}.${currentBoolValue}`, {
          defaultValue: currentBoolValue,
        });
      },
      [CasesDataType.range]: ([from, to]) => `${from} - ${to}`,
      [CasesDataType.currency]: (value) => formatCurrency(value),
    };

    return formatters[type];
  };
};
