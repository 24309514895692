import * as React from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProperties,
} from 'react-router-dom';

export const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProperties, 'to'> & { href: RouterLinkProperties['to'] }
>((properties, reference) => {
  const { href, ...other } = properties;
  return <RouterLink ref={reference} to={href} {...other} />;
});
