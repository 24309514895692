import { z } from 'zod';

import {
  AbuseDetailsKeys,
  AbuseDetailsLevel1VariantKeys,
  AbuseDetailsLevel2VariantKeys,
  AbuseDetailsLevel3VariantKeys,
  AbuseDetailsLevel4VariantKeys,
  AbuseDetailsLevel5VariantKeys,
  LegalDetailsKeys,
  ResolutionPublicityVariantsKey,
  StaticPropertyKey,
  VictimInfoGenderVariantKeys,
  VictimInfoAgeCategoryVariantKeys,
  VictimInfoKeys,
  YesNoVariantsKey,
} from '@bvi/api-interfaces/entity/case-property';
import { AdditionalFilterParameter } from '@bvi/api-interfaces/request/case';

export const searchFormPropertySchemas = {
  [StaticPropertyKey.COUNTRY]: z.array(
    z.object({ id: z.number(), name: z.string() }),
  ),
  [StaticPropertyKey.REGION]: z.array(
    z.object({ id: z.number(), name: z.string() }),
  ),
  [StaticPropertyKey.STATE]: z.array(
    z.object({ id: z.number(), name: z.string() }),
  ),
  [VictimInfoKeys.AGE_CATEGORY]: z.array(
    z.nativeEnum(VictimInfoAgeCategoryVariantKeys),
  ),
  [AdditionalFilterParameter.REFINE_AGE_CATEGORY]: z.array(
    z.nativeEnum(VictimInfoAgeCategoryVariantKeys),
  ),
  [VictimInfoKeys.GENDER]: z.array(z.nativeEnum(VictimInfoGenderVariantKeys)),
  [StaticPropertyKey.ORGANIZATION]: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .nullable()
    .optional(),
  [StaticPropertyKey.DIOCESE]: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .nullable()
    .optional(),
  [StaticPropertyKey.ORGANIZATION_TYPE]: z.array(
    z.object({ id: z.number(), name: z.string() }),
  ),
  [VictimInfoKeys.AGE_AT_START_OF_ABUSE]: z.number().nullable(),
  [VictimInfoKeys.AGE_AT_END_OF_ABUSE]: z.number().nullable(),
  [AdditionalFilterParameter.RESOLUTION_DATE_LOW]: z
    .number()
    .nullable()
    .optional(),
  [AdditionalFilterParameter.RESOLUTION_DATE_HIGH]: z
    .number()
    .nullable()
    .optional(),
  [AbuseDetailsKeys.NUMBER_OF_INCIDENTS_LOW]: z.number().nullable(),
  [AbuseDetailsKeys.NUMBER_OF_INCIDENTS_HIGH]: z.number().nullable(),
  [AbuseDetailsKeys.LEVEL_1]: z
    .array(z.nativeEnum(AbuseDetailsLevel1VariantKeys))
    .nullable(),
  [AbuseDetailsKeys.LEVEL_2]: z
    .array(z.nativeEnum(AbuseDetailsLevel2VariantKeys))
    .nullable(),
  [AbuseDetailsKeys.LEVEL_3]: z
    .array(z.nativeEnum(AbuseDetailsLevel3VariantKeys))
    .nullable(),
  [AbuseDetailsKeys.LEVEL_4]: z
    .array(z.nativeEnum(AbuseDetailsLevel4VariantKeys))
    .nullable(),
  [AbuseDetailsKeys.LEVEL_5]: z
    .array(z.nativeEnum(AbuseDetailsLevel5VariantKeys))
    .nullable(),
  [StaticPropertyKey.JOB_TITLE]: z.array(
    z.object({ id: z.number(), name: z.string() }),
  ),
  [StaticPropertyKey.ORDER]: z.array(
    z.object({ id: z.number(), name: z.string() }),
  ),
  [StaticPropertyKey.LIABILITY_DEFENSES]: z.array(
    z.object({ id: z.number(), name: z.string() }),
  ),
  [AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_LOW]: z.number().nullable(),
  [AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_HIGH]: z.number().nullable(),
  [StaticPropertyKey.EXCEPTION]: z.array(
    z.object({ id: z.number(), name: z.string() }),
  ),
  [LegalDetailsKeys.PREPARATOR]: z.nativeEnum(YesNoVariantsKey).nullable(),
  [StaticPropertyKey.RESOLUTION_TYPE]: z.array(
    z.object({ id: z.number(), name: z.string() }),
  ),
  [LegalDetailsKeys.LAWSUIT_FILED]: z.nativeEnum(YesNoVariantsKey).nullable(),
  [LegalDetailsKeys.HAS_ATTORNEY]: z.nativeEnum(YesNoVariantsKey).nullable(),
  [AdditionalFilterParameter.AMOUNT_LOW]: z.string().nullable(),
  [AdditionalFilterParameter.AMOUNT_HIGH]: z.string().nullable(),
  [AdditionalFilterParameter.RESOLUTION_PUBLICITY]: z
    .array(z.nativeEnum(ResolutionPublicityVariantsKey))
    .nullable(),
};

export const searchFormDefaultValues = {
  [StaticPropertyKey.COUNTRY]: [],
  [StaticPropertyKey.REGION]: [],
  [StaticPropertyKey.STATE]: [],
  [VictimInfoKeys.AGE_CATEGORY]: [],
  [AdditionalFilterParameter.REFINE_AGE_CATEGORY]: [],
  [VictimInfoKeys.GENDER]: [],
  [StaticPropertyKey.ORGANIZATION]: null,
  [StaticPropertyKey.DIOCESE]: null,
  [StaticPropertyKey.ORGANIZATION_TYPE]: [],
  [VictimInfoKeys.AGE_AT_START_OF_ABUSE]: null,
  [VictimInfoKeys.AGE_AT_END_OF_ABUSE]: null,
  [AdditionalFilterParameter.RESOLUTION_DATE_LOW]: null,
  [AdditionalFilterParameter.RESOLUTION_DATE_HIGH]: null,
  [AbuseDetailsKeys.NUMBER_OF_INCIDENTS_LOW]: null,
  [AbuseDetailsKeys.NUMBER_OF_INCIDENTS_HIGH]: null,
  [AbuseDetailsKeys.LEVEL_1]: [],
  [AbuseDetailsKeys.LEVEL_2]: [],
  [AbuseDetailsKeys.LEVEL_3]: [],
  [AbuseDetailsKeys.LEVEL_4]: [],
  [AbuseDetailsKeys.LEVEL_5]: [],
  [StaticPropertyKey.JOB_TITLE]: [],
  [StaticPropertyKey.ORDER]: [],
  [AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_LOW]: null,
  [AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_HIGH]: null,
  [StaticPropertyKey.LIABILITY_DEFENSES]: [],
  [StaticPropertyKey.EXCEPTION]: [],
  [LegalDetailsKeys.PREPARATOR]: null,
  [StaticPropertyKey.RESOLUTION_TYPE]: [],
  [LegalDetailsKeys.LAWSUIT_FILED]: null,
  [LegalDetailsKeys.HAS_ATTORNEY]: null,
  [AdditionalFilterParameter.AMOUNT_LOW]: null,
  [AdditionalFilterParameter.AMOUNT_HIGH]: null,
  [AdditionalFilterParameter.RESOLUTION_PUBLICITY]: [],
};

export const buildValidationSchema = () => {
  return z.object(searchFormPropertySchemas);
};
