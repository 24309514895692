import dayjs from 'dayjs';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';

import {
  CaseInformation,
  StaticPropertyKey,
  VictimInfoKeys,
} from '@bvi/api-interfaces/entity/case-property';
import { IResolutionPrice } from '@bvi/api-interfaces/entity/resolution';
import { ICaseData } from '@bvi/api-interfaces/response/case';
import { Timestampify } from '@bvi/api-interfaces/utils';

import { defaultValues } from '../schema';

const mapCost = (cost?: Timestampify<IResolutionPrice> | null) => {
  if (!cost) {
    return {
      amount: 0,
      currency: null,
    };
  }

  return {
    amount: cost.amount,
    currency: cost.currency,
  };
};

const mapDate = (value: unknown) => {
  if (isNumber(value) || isString(value)) {
    return dayjs(value).toDate();
  }

  return null;
};

const mapResolution = (_case?: ICaseData) => {
  if (!_case?.resolution) {
    return defaultValues.resolution;
  }

  const _resolution = {
    ...defaultValues.resolution,
    ..._case.resolution,
  };

  return {
    ..._resolution,
    resolutionDate: mapDate(_resolution.resolutionDate),
    total: mapCost(_resolution.total),
    claimantsCount: _resolution.claimantsCount,
    perClaimant: mapCost(_resolution.perClaimant),
    defenseCosts: mapCost(_resolution.defenseCosts),
  };
};

export const mapCaseToFormData = (_case?: ICaseData) => {
  if (!_case) {
    return defaultValues;
  }

  const formattedProperties = Object.entries(_case.properties).map(
    ([key, { value }]) => {
      return [key, value];
    },
  );

  const properties = Object.fromEntries(formattedProperties);
  const baseProperties = {
    ...defaultValues,
    ...properties,
    [CaseInformation.CLAIMANT_COUNT]:
      properties[CaseInformation.CLAIMANT_COUNT],
    [CaseInformation.CLAIMANT_NAME]: _case.claimantName,
    fileName: _case.name,
    [StaticPropertyKey.COUNTRY]: _case.country,
    [StaticPropertyKey.REGION]: _case.region,
    [StaticPropertyKey.STATE]: _case.state,

    description: _case.description,
    perpetratorName: properties.name,
    isResolved: _case.isResolved,
    [StaticPropertyKey.GROUP]: _case[StaticPropertyKey.GROUP],
    [StaticPropertyKey.JOB_TITLE]: _case[StaticPropertyKey.JOB_TITLE],
    [StaticPropertyKey.ORDER]: _case[StaticPropertyKey.ORDER],
    [StaticPropertyKey.ORGANIZATION]: _case[StaticPropertyKey.ORGANIZATION],
    [StaticPropertyKey.DIOCESE]: _case[StaticPropertyKey.DIOCESE],
    [StaticPropertyKey.DAMAGE]: _case[StaticPropertyKey.DAMAGE],
    [StaticPropertyKey.LIABILITY_DEFENSES]: _case.liabilityDefenses,
    [StaticPropertyKey.EXCEPTION]: _case.exception,
    [StaticPropertyKey.ORGANIZATION_TYPE]:
      _case[StaticPropertyKey.ORGANIZATION_TYPE],
    [VictimInfoKeys.BIRTH_DATE]: mapDate(properties[VictimInfoKeys.BIRTH_DATE]),
    [VictimInfoKeys.STARTED_ABUSE_AT]: mapDate(
      properties[VictimInfoKeys.STARTED_ABUSE_AT],
    ),
    [VictimInfoKeys.END_OF_ABUSE_AT]: mapDate(
      properties[VictimInfoKeys.END_OF_ABUSE_AT],
    ),
  };

  return {
    ...baseProperties,
    resolution: mapResolution(_case),
  };
};
