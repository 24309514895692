import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';

export const withLocalization = (component: () => React.ReactNode) => () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {component()}
    </LocalizationProvider>
  );
};
