import { TableBody } from '@mui/material';
import times from 'lodash/times';
import { FC } from 'react';

import { TableRowSkeleton } from '../';

import { ILoadingTableBodyProperties } from './types';

export const LoadingTableBody: FC<ILoadingTableBodyProperties> = ({
  rows,
  cols,
  children,
  isLoading,
  rowSx,
}) => {
  return (
    <TableBody>
      {isLoading
        ? times(rows, (index) => (
            <TableRowSkeleton key={index} columns={cols} sx={rowSx} />
          ))
        : children}
    </TableBody>
  );
};
