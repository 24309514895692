import { Grid, TextField, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { StaticPropertyKey } from '@bvi/api-interfaces/entity/case-property';
import { Div } from '@bvi/common-components';

import { useCasesFormContext } from '../../../lib/context';
import { ICaseFormData } from '../../../lib/types';
import { CasesFormAutocomplete } from '../form-autocomplete';
import { styles } from '../styles';

export const PerpetratorInformation = () => {
  const { t } = useTranslation('translation');
  const { hiddenFields } = useCasesFormContext();

  const hidePerpetratorName = hiddenFields?.includes('perpetratorName');

  const {
    register,
    formState: { errors },
  } = useFormContext<ICaseFormData>();

  return (
    <Div>
      <Typography variant="body1" sx={styles.title}>
        {t('cases.form.perpetratorInformation.title')}
      </Typography>
      <Grid container spacing={2} mt={1}>
        {!hidePerpetratorName && (
          <Grid item xs={6}>
            <TextField
              {...register('perpetratorName')}
              variant="filled"
              fullWidth
              label={t('cases.form.perpetratorInformation.name.label')}
              placeholder={t(
                'cases.form.perpetratorInformation.name.placeholder',
              )}
              error={Boolean(errors.perpetratorName)}
              helperText={<>{errors.perpetratorName?.message}</>}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <CasesFormAutocomplete
            name="jobTitle"
            field={StaticPropertyKey.JOB_TITLE}
            variant="filled"
            label={t('cases.form.perpetratorInformation.job.label')}
            placeholder={t('cases.form.perpetratorInformation.job.placeholder')}
          />
        </Grid>
        <Grid item xs={6}>
          <CasesFormAutocomplete
            name="order"
            field={StaticPropertyKey.ORDER}
            variant="filled"
            label={t('cases.form.perpetratorInformation.religionOrder.label')}
            placeholder={t(
              'cases.form.perpetratorInformation.religionOrder.placeholder',
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <CasesFormAutocomplete
            name="organization"
            field={StaticPropertyKey.ORGANIZATION}
            variant="filled"
            label={t('cases.form.perpetratorInformation.organization.label')}
            placeholder={t(
              'cases.form.perpetratorInformation.organization.placeholder',
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <CasesFormAutocomplete
            name="diocese"
            field={StaticPropertyKey.DIOCESE}
            variant="filled"
            label={t('cases.form.perpetratorInformation.subsidiary.label')}
            placeholder={t(
              'cases.form.perpetratorInformation.subsidiary.placeholder',
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <CasesFormAutocomplete
            name="organizationType"
            field={StaticPropertyKey.ORGANIZATION_TYPE}
            variant="filled"
            label={t(
              'cases.form.perpetratorInformation.organizationType.label',
            )}
            placeholder={t(
              'cases.form.perpetratorInformation.organizationType.placeholder',
            )}
          />
        </Grid>
      </Grid>
    </Div>
  );
};
