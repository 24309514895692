import { Stack, Typography } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import { useTranslation } from 'react-i18next';

import { IUserCaseWithCaseData } from '@bvi/api-interfaces/response/case';
import { BackButton, Breadcrumbs, Div } from '@bvi/common-components';
import { DashboardRoutesPaths } from '@bvi/dashboard/shared/constants/routes';
import { formatDate } from '@bvi/date-utils';

import { HeaderActions } from './HeaderActions';
import { styles } from './styles';

type CasesDetailsHeaderProperties = {
  caseData: IUserCaseWithCaseData;
};

export const CasesDetailsHeader: React.FC<CasesDetailsHeaderProperties> = (
  properties,
) => {
  const {
    caseData: { id: caseId, case: _case },
  } = properties;
  const { t } = useTranslation();

  const createdDate = formatDate(_case.createdAt);
  const title =
    isNull(_case.name) || isEmpty(_case.name) ? createdDate : _case.name;
  const items = [
    {
      label: 'Cases',
      link: DashboardRoutesPaths.CASE,
    },
    {
      label: title,
    },
  ];

  const caseStatusLabel = t(`cases.common.status.${_case.status}`, {
    defaultValue: '',
  });

  return (
    <Div sx={styles.header}>
      <Breadcrumbs items={items} />
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          <BackButton />
          <Typography variant="h1">
            {title}{' '}
            <Typography variant="h1" component="span" color="grey.700">
              {caseStatusLabel}
            </Typography>
          </Typography>
        </Stack>
        <HeaderActions caseId={caseId} _case={_case} />
      </Stack>
    </Div>
  );
};
