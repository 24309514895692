import { Button, Grid, Typography } from '@mui/material';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { parse } from 'qs';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { isServerError } from '@bvi/axios-query';
import {
  ResultsChart,
  ResultsTable,
  StatisticsAlert,
  SearchTags,
  ISearchFormDataSchema,
  buildParametersForSettings,
  SearchReport,
  buildAgeCategoryQuery,
} from '@bvi/cases-search';
import { PaperModal } from '@bvi/common-components';
import { useLazyGetStatisticsQuery } from '@bvi/dashboard/entities/search/api-slice';
import {
  selectSettingsData,
  selectStatistic,
} from '@bvi/dashboard/entities/search/selectors';
import { SaveSearchForm } from '@bvi/dashboard/feature/saved-searches/ui/save-search-form';
import { SearchSavedMessage } from '@bvi/dashboard/feature/saved-searches/ui/search-saved-message';
import { DateFormat, formatDate } from '@bvi/date-utils';

import { useSearchNotification } from '../../lib/useSearchNotification';

export const SearchResult: React.FC = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'search.results',
  });

  const [isSearchSavedMessageOpen, setSearchSavedMessageOpen] = useState(false);
  const handleOpenSearchSavedMessage = () => setSearchSavedMessageOpen(true);
  const handleCloseSearchSavedMessage = () => setSearchSavedMessageOpen(false);

  const [isSaveSearchModalOpen, setSaveSearchModalOpen] = useState(false);
  const handleOpenSaveSearchModal = () => setSaveSearchModalOpen(true);
  const handleCloseSaveSearchModal = () => setSaveSearchModalOpen(false);
  const handleCloseSaveSearchModalOnSuccess = () => {
    handleCloseSaveSearchModal();
    handleOpenSearchSavedMessage();
  };

  const location = useLocation();
  const searchParameters = parse(location.search, {
    comma: true,
    ignoreQueryPrefix: true,
  }) as Record<keyof ISearchFormDataSchema, string>;

  const [getStatistics, { isFetching, data: statistics }] =
    useLazyGetStatisticsQuery();
  const { showSearchNotificationMessage } = useSearchNotification();

  const handleLoadStatistics = async () => {
    const ageCategoryQuery = buildAgeCategoryQuery(searchParameters);
    try {
      const updatedSearchParameters = {
        ...searchParameters,
        ...ageCategoryQuery,
      };

      await getStatistics(updatedSearchParameters, false).unwrap();
    } catch (error) {
      if (isServerError(error)) {
        showSearchNotificationMessage(error);
      }
    }
  };

  useEffect(() => {
    handleLoadStatistics();
  }, [location.search]);

  const statisticsData = selectStatistic(statistics);
  const settingsData = useSelector(
    selectSettingsData(buildParametersForSettings(searchParameters)),
  );

  const searchReportReference = useRef<HTMLDivElement>(null);
  const handleDownloadPdf = async () => {
    const document_ = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
    });

    const currentElement = searchReportReference.current;
    if (currentElement) {
      const canvas = await html2canvas(currentElement);
      const { offsetHeight } = currentElement;
      const imgData = canvas.toDataURL('image/jpeg');
      const fileName = `search-report_${formatDate(
        new Date(),
        DateFormat.defaultFullTime,
      )}`;

      const width = 448;
      const height = offsetHeight / 4;
      document_.addImage(imgData, 'jpeg', 0, 0, width, height);
      document_.save(fileName);
    }
  };

  return (
    <>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={2}>
          <Typography variant="h1">{t('title')}</Typography>
        </Grid>
        <Grid
          container
          item
          xs={10}
          columnSpacing={2}
          justifyContent="flex-end"
        >
          <Grid item>
            <Button variant="outlined" size="small" onClick={handleDownloadPdf}>
              {t('pdfButton')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              size="small"
              onClick={handleOpenSaveSearchModal}
            >
              {t('saveSearchButton')}
            </Button>
            <PaperModal
              open={isSaveSearchModalOpen}
              onClose={handleCloseSaveSearchModal}
            >
              <SaveSearchForm
                parameters={searchParameters}
                onSuccess={handleCloseSaveSearchModalOnSuccess}
                onCancel={handleCloseSaveSearchModal}
              />
            </PaperModal>
            <PaperModal
              open={isSearchSavedMessageOpen}
              onClose={handleCloseSearchSavedMessage}
            >
              <SearchSavedMessage
                onBackToSearch={handleCloseSearchSavedMessage}
              />
            </PaperModal>
          </Grid>
        </Grid>
      </Grid>
      <SearchTags data={settingsData} isLoading={isFetching} />
      <StatisticsAlert data={statisticsData} isLoading={isFetching} />
      <ResultsTable data={statisticsData} isLoading={isFetching} shouldSticky />
      <ResultsChart data={statisticsData} isLoading={isFetching} shouldSticky />
      <SearchReport
        ref={searchReportReference}
        statisticsData={statisticsData}
        settingsData={settingsData}
      />
    </>
  );
};
