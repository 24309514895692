import { theme } from '@bvi/mui-theme';

export const styles = {
  title: {
    mb: 3,
  },
  row: {
    minHeight: '40px',
  },
  activeCell: { cursor: 'pointer' },
  skeleton: {
    ml: '0.5em',
    display: 'inline-block',
  },
  count: { ml: '0.5em', color: theme.palette.grey['200'] },
};
