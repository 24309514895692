import { ICompanySizeListResponse } from '@bvi/api-interfaces/response/company-size';
import { routes } from '@bvi/dashboard/api-routes';
import { TagTypes, apiContainer } from '@bvi/dashboard/shared/api';

const clientsApi = apiContainer.injectEndpoints({
  endpoints: (builder) => ({
    getCompanySizes: builder.query<ICompanySizeListResponse, void>({
      query: () => ({
        url: routes.companies.getCompanySizes(),
        method: 'GET',
      }),
      providesTags: [TagTypes.COMPANY_SIZES],
    }),
  }),
});

export const { useGetCompanySizesQuery, usePrefetch } = clientsApi;
