import { createSelector } from '@reduxjs/toolkit';

import { IGetSavedSearchRequest } from '@bvi/api-interfaces/request/case';
import { getResponseData } from '@bvi/api-interfaces/response';
import {
  ISavedSearchData,
  ISavedSearchEntryResponse,
} from '@bvi/api-interfaces/response/case';
import { savedSearchesApi } from '@bvi/dashboard/entities/saved-searches/api-slice';
import { IFilterSearchesQuery } from '@bvi/dashboard/feature/saved-searches/lib/schema';

export const selectSavedSearchesResponse = (
  parameters?: IFilterSearchesQuery,
) => savedSearchesApi.endpoints.getSavedSearches.select(parameters);

export const selectSavedSearches = (parameters?: IFilterSearchesQuery) =>
  createSelector(selectSavedSearchesResponse(parameters), ({ data }) => {
    return data?.success ? data.payload.data : [];
  });

export const selectSavedSearchResponse = (parameters: IGetSavedSearchRequest) =>
  savedSearchesApi.endpoints.getSavedSearch.select(parameters);

export const selectSavedSearch = (parameters: IGetSavedSearchRequest) =>
  createSelector(selectSavedSearchResponse(parameters), ({ data }) => {
    if (!data?.success) {
      return {} as ISavedSearchData;
    }

    return data.payload.data;
  });

export const selectSavedSearchEntry = (
  entry: ISavedSearchData,
  entryId: number,
) => {
  return entry?.entries?.find((item) => item.id === Number(entryId));
};

export const selectSavedSearchEntryFromMutationResult = (
  result: { data: ISavedSearchEntryResponse } | { error: unknown },
) => {
  if ('data' in result) {
    return getResponseData(result.data);
  }

  throw result.error;
};
