import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ISearchFormDataSchema } from '@bvi/cases-search';
import { Autocomplete, Form } from '@bvi/common-components';
import { useLazyCasesListQuery } from '@bvi/dashboard/entities/case/api-slice';
import { useCreateSavedSearchMutation } from '@bvi/dashboard/entities/saved-searches/api-slice';
import { mapSaveSearchFormDataToBody } from '@bvi/dashboard/feature/saved-searches/lib/mappers';
import {
  buildSaveSearchValidationSchema,
  ISaveSearchFormData,
} from '@bvi/dashboard/feature/saved-searches/lib/schema';

export interface ISaveSearchFormParameters {
  parameters: Record<keyof ISearchFormDataSchema, string>;
  onSuccess?: () => void;
  onCancel?: () => void;
}

const PAGE_LIMIT = 20;

export const SaveSearchForm: FC<ISaveSearchFormParameters> = ({
  parameters,
  onSuccess,
  onCancel,
}) => {
  const { t } = useTranslation();

  const [createSavedSearch, { isLoading }] = useCreateSavedSearchMutation();
  const [getCasesList] = useLazyCasesListQuery();

  const validationSchema = buildSaveSearchValidationSchema();
  const methods = useForm<ISaveSearchFormData>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      parameters,
      userCase: null,
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const handleOptionsLoad = async (pagination: { page: number }) => {
    const { data } = await getCasesList({
      page: pagination.page,
      limit: PAGE_LIMIT,
    });

    if (data && data.success) {
      return {
        data: {
          payload: {
            data: data.payload.data.map((item) => ({
              id: item.id,
              name: item.case.name ?? '',
            })),
            meta: data.payload.meta,
          },
        },
      };
    }

    return {
      data: {
        payload: {
          data: [],
          meta: {},
        },
      },
    };
  };

  const onSubmit = async (data: ISaveSearchFormData) => {
    await createSavedSearch(mapSaveSearchFormDataToBody(data));
    onSuccess?.();
  };

  return (
    <FormProvider {...methods}>
      <Typography variant="h1" mb={2} color="primary">
        {t('savedSearches.saveSearchForm.title')}
      </Typography>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3} mt={3}>
          <Grid item xs={12} mb={2}>
            <TextField
              {...register('name')}
              variant="filled"
              fullWidth
              label={t('savedSearches.saveSearchForm.fields.name.label')}
              placeholder={t(
                'savedSearches.saveSearchForm.fields.name.placeholder',
              )}
            />
            {errors.name && (
              <FormHelperText error>{errors.name?.message}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} mb={2}>
            <Autocomplete
              name="userCase"
              onOptionsLoad={handleOptionsLoad}
              variant="filled"
              label={t('savedSearches.saveSearchForm.fields.userCase.label')}
              placeholder={t(
                'savedSearches.saveSearchForm.fields.userCase.placeholder',
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Button variant="outlined" fullWidth onClick={onCancel}>
              {t('dialog.cancel')}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <LoadingButton
              type="submit"
              variant="contained"
              fullWidth
              loading={isLoading}
              autoFocus
            >
              {t('savedSearches.buttons.saveSearch.caption')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Form>
    </FormProvider>
  );
};
