import { Skeleton } from '@mui/material';
import { parse } from 'qs';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { AdditionalFilterParameter } from '@bvi/api-interfaces/request/case';
import { ICaseStatisticsData } from '@bvi/api-interfaces/response/case';
import { Div } from '@bvi/common-components';
import { formatDate } from '@bvi/date-utils';

import { formatNumber } from '../../../lib/formatter';
import { ISearchFormDataSchema } from '../../../lib/types';
import { i18nInstance } from '../../../locales';
import { styles } from '../styles';

type TitleProperties = {
  data: ICaseStatisticsData;
  isLoading?: boolean;
};

export const Title: React.FC<TitleProperties> = (properties) => {
  const { data, isLoading } = properties;
  const { t } = useTranslation('', {
    i18n: i18nInstance,
    keyPrefix: 'results.table',
  });
  const location = useLocation();
  const searchParameters = parse(location.search, {
    comma: true,
    ignoreQueryPrefix: true,
  }) as Record<keyof ISearchFormDataSchema, string>;

  const renderTitle = () => {
    const {
      [AdditionalFilterParameter.RESOLUTION_DATE_LOW]: resolutionDateLow,
      [AdditionalFilterParameter.RESOLUTION_DATE_HIGH]: resolutionDateHigh,
    } = searchParameters;

    const isBothDatesExists = resolutionDateLow && resolutionDateHigh;
    const shouldRenderRangeTitle = isBothDatesExists || resolutionDateLow;
    const from = formatDate(Number(resolutionDateLow));
    const to = resolutionDateHigh
      ? formatDate(Number(resolutionDateHigh))
      : formatDate(Date.now());

    if (shouldRenderRangeTitle) {
      return t('titleWithRange', {
        from,
        to,
        interpolation: { escapeValue: false },
      });
    }

    if (resolutionDateHigh) {
      return t('titleWithThroughDate', {
        to,
        interpolation: { escapeValue: false },
      });
    }

    return t('title');
  };

  return (
    <Div sx={styles.titleWrapper}>
      <Div>{renderTitle()}</Div>
      {isLoading ? (
        <Skeleton sx={styles.titleSkeleton} />
      ) : (
        <Div>{t('amount', { amount: formatNumber(data.total) })}</Div>
      )}
    </Div>
  );
};
