import isObject from 'lodash/isObject';

import { IArrayPayload } from '@bvi/api-interfaces/response/base';

import { IServerError } from './types/error';

export const isServerError = <T>(error: unknown): error is IServerError<T> =>
  Boolean(error) && isObject(error) && 'type' in error;

export const isPaginationPayload = (
  payload: unknown,
): payload is IArrayPayload => {
  if (typeof payload !== 'object' || payload === null) {
    return false;
  }

  if (!('data' in payload)) {
    return false;
  }

  if (!('pagination' in payload)) {
    return false;
  }

  return Array.isArray(payload.data);
};
