import { ComponentsOverrides, ComponentsProps } from '@mui/material';
import { Theme } from '@mui/system';

import { palette } from '../constants';

interface IMuiInputLabel {
  defaultProps: ComponentsProps['MuiInputLabel'];
  styleOverrides: ComponentsOverrides<Theme>['MuiInputLabel'];
}

const transformedLabelValue = 'translate(14px, -12px) scale(1)';

export const MuiInputLabel = {
  defaultProps: {
    shrink: true,
    variant: 'outlined',
  },
  styleOverrides: {
    root: {},
    outlined: {
      color: palette['grey'][300],
      left: -12,
      fontWeight: '300',
      fontSize: 14,
      lineHeight: '16px',
      transform: transformedLabelValue,
    },
    filled: {
      color: palette['grey'][300],
      left: -12,
      fontWeight: '300',
      fontSize: 14,
      lineHeight: '16px',
      transform: transformedLabelValue,
    },
  },
} satisfies IMuiInputLabel;
