import { Button } from '@mui/material';
import first from 'lodash/first';
import omit from 'lodash/omit';
import { parse } from 'qs';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import {
  IServerError,
  RequestBaseError,
  isServerError,
} from '@bvi/axios-query';
import { ISearchFormDataSchema, i18nInstance } from '@bvi/cases-search';
import { DashboardRoutesPaths } from '@bvi/dashboard/shared/constants/routes';
import { useNavigation } from '@bvi/navigation-utils';
import { useNotistack, NotistackMessageVariants } from '@bvi/notistack';

interface IServerErrorWithValidation {
  validationErrors: Array<{
    property: string;
  }>;
}

const getErrorProperty = (error: IServerError<IServerErrorWithValidation>) => {
  const currentError = first(error.additional?.validationErrors);

  return first(currentError?.property.split('.'));
};

const mapServerError = (error: IServerError<IServerErrorWithValidation>) => {
  return error.type === RequestBaseError.INCORRECT_DATA
    ? RequestBaseError.INCORRECT_FILTER
    : error.type;
};

export const useSearchNotification = () => {
  const { t } = useTranslation('', {
    i18n: i18nInstance,
    keyPrefix: 'form',
  });
  const navigate = useNavigation();
  const location = useLocation();
  const searchParameters = parse(location.search, {
    comma: true,
    ignoreQueryPrefix: true,
  }) as Record<keyof ISearchFormDataSchema, string>;
  const { showNotificationMessage, closeSnackbar } = useNotistack();

  const handleResetIncorrectFilter = (field?: string) => {
    if (!field) {
      closeSnackbar();
      navigate(DashboardRoutesPaths.SEARCH);
      return;
    }

    const ommitedSearchParameters = omit(searchParameters, field);
    closeSnackbar();
    navigate(DashboardRoutesPaths.SEARCH, ommitedSearchParameters);
  };

  const showSearchNotificationMessage = (error: unknown) => {
    if (isServerError<IServerErrorWithValidation>(error)) {
      const errorType = mapServerError(error);
      const property = getErrorProperty(error);

      showNotificationMessage(
        {
          type: errorType,
          payload: {
            field: t(`${property}.label`, {
              defaultValue: '',
            }),
          },
        },
        NotistackMessageVariants.ERROR,
        () => (
          <Button
            variant="notificationButton"
            size="small"
            onClick={() => handleResetIncorrectFilter(property)}
          >
            {t('error.reset')}
          </Button>
        ),
      );
    }
  };

  return { showSearchNotificationMessage };
};
