import { createSelector } from '@reduxjs/toolkit';

import { IUserMe, UserStatus } from '@bvi/api-interfaces/entity/user';

import { authApi } from './api-slice';

export const selectCurrentUserResult = () => authApi.endpoints.me.select();

export const selectCurrentUser = () =>
  createSelector(selectCurrentUserResult(), ({ data }) => {
    if (!data?.success) {
      return {} as IUserMe;
    }

    return data.payload.data;
  });

export const isCurrentUserActive = () =>
  createSelector(
    selectCurrentUser(),
    (user) =>
      user.status === UserStatus.CONFIRMED && user.hasActiveSubscription,
  );

export const selectIsEmailAvailable = (email: string) =>
  createSelector(authApi.endpoints.checkEmail.select(email), ({ data }) => {
    if (!data?.success) {
      return;
    }

    return data.payload.data.isAvailable;
  });
