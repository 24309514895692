import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Image } from '../Image';
import ArrowIcon from '../icons/arrow-right.svg';

import { styles } from './styles';

export const BackButton = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <IconButton sx={styles.button} onClick={handleBack}>
      <Image src={ArrowIcon} sx={styles.icon} />
    </IconButton>
  );
};
