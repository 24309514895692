import { theme } from '@bvi/mui-theme';

export const styles = {
  wrapper: {
    width: '400px',
  },
  title: {
    my: 4,
  },
  stepsList: {
    display: 'flex',
    justifyContent: 'center',
    my: 4,
  },
  signInNowLink: {
    fontWeight: 700,
    underline: 'none',
    ml: 1,
  },
  backButton: {
    '&&': {
      color: theme.palette.common.white,
      borderColor: theme.palette.common.white,
      fontSize: '0.875rem',
    },
    '&:hover': {
      borderColor: theme.palette.common.white,
    },
  },
};
