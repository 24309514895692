export const routes = {
  adminCases: {
    create: () => `/admin/cases`,
    findAll: () => `/admin/cases`,
    findOne: (id) => `/admin/cases/${id}`,
    getSettings: () => `/admin/cases/settings`,
    getStatistics: () => `/admin/cases/statistics`,
    remove: (id) => `/admin/cases/${id}`,
    search: () => `/admin/cases/search`,
    update: (id) => `/admin/cases/${id}`,
  },
  adminClients: {
    createOne: () => `/admin/clients`,
    find: () => `/admin/clients`,
    updateById: (id) => `/admin/clients/${id}`,
  },
  adminCompanies: {
    findById: (id) => `/admin/companies/${id}`,
    updateById: (id) => `/admin/companies/${id}`,
  },
  adminSubscriptions: {
    getSubscriptionPlans: () => `/admin/subscriptions/plans`,
    updateById: (id) => `/admin/subscriptions/${id}`,
  },
  adminUserCases: {
    transit: (id) => `/admin/user-cases/${id}/transit`,
  },
  adminUsers: {
    updateById: (id) => `/admin/users/${id}`,
  },
  admins: {
    changePassword: () => `/admins/change-password`,
    me: () => `/admins/me`,
  },
  adminsAuth: {
    refreshToken: () => `/admins/auth/refresh-token`,
    signIn: () => `/admins/auth/sign-in`,
    signOut: () => `/admins/auth/sign-out`,
  },
  cases: {
    getSettings: () => `/cases/settings`,
    getStatistics: () => `/cases/statistics`,
  },
  clientCases: {
    findByCompanyId: (id) => `/admin/client-cases/by-company/${id}`,
  },
  companies: {
    getCompanySizes: () => `/companies/sizes`,
  },
  countries: {
    create: () => `/cases/countries`,
    findAll: () => `/cases/countries`,
    findOne: (id) => `/cases/countries/${id}`,
    remove: (id) => `/cases/countries/${id}`,
    update: (id) => `/cases/countries/${id}`,
  },
  dynamicCaseProperties: {
    create: () => `/cases/case-properties`,
    findAll: () => `/cases/case-properties`,
    findOne: (id) => `/cases/case-properties/${id}`,
    remove: (id) => `/cases/case-properties/${id}`,
    update: (id) => `/cases/case-properties/${id}`,
  },
  profile: {
    findAll: () => `/profiles`,
    findOne: (id) => `/profiles/${id}`,
    remove: (id) => `/profiles/${id}`,
    update: (id) => `/profiles/${id}`,
    updateSelf: () => `/profiles`,
  },
  regions: {
    create: () => `/cases/regions`,
    findAll: () => `/cases/regions`,
    findOne: (id) => `/cases/regions/${id}`,
    remove: (id) => `/cases/regions/${id}`,
    update: (id) => `/cases/regions/${id}`,
  },
  resolutions: {
    create: () => `/cases/resolutions`,
    findOne: (id) => `/cases/resolutions/${id}`,
    remove: (id) => `/cases/resolutions/${id}`,
    update: (id) => `/cases/resolutions/${id}`,
  },
  savedSearches: {
    create: () => `/saved-searches`,
    createEntry: (id) => `/saved-searches/${id}/entries`,
    delete: (id) => `/saved-searches/${id}`,
    deleteEntry: (searchId, entryId) =>
      `/saved-searches/${searchId}/entries/${entryId}`,
    find: () => `/saved-searches`,
    findById: (id) => `/saved-searches/${id}`,
  },
  savedSearchesAdmin: {
    findByCompanyId: (id) => `/admin/saved-searches/by-company/${id}`,
  },
  states: {
    create: () => `/cases/states`,
    findAll: () => `/cases/states`,
    findOne: (id) => `/cases/states/${id}`,
    remove: (id) => `/cases/states/${id}`,
    update: (id) => `/cases/states/${id}`,
  },
  userCases: {
    create: () => `/user-cases`,
    createNote: (id) => `/user-cases/${id}/notes`,
    delete: (id) => `/user-cases/${id}`,
    findAll: () => `/user-cases`,
    findOne: (id) => `/user-cases/${id}`,
    getNotes: (id) => `/user-cases/${id}/notes`,
    update: (id) => `/user-cases/${id}`,
    updateStatus: (id) => `/user-cases/${id}/status`,
  },
  users: {
    me: () => `/users/me`,
  },
  usersAuth: {
    isEmailAvailable: () => `/users/auth/is-email-available`,
    refreshToken: () => `/users/auth/refresh-token`,
    resetPassword: () => `/users/auth/reset-password`,
    sendResetPasswordEmail: () => `/users/auth/send-reset-password-email`,
    signIn: () => `/users/auth/sign-in`,
    signOut: () => `/users/auth/sign-out`,
    signUp: () => `/users/auth/sign-up`,
  },
};
