import { Grid, GridProps } from '@mui/material';

import { Div } from '../Div';

import { styles } from './styles';

type DataGridProperties = {
  items: Array<{
    label: string;
    value?: React.ReactNode;
    width?: number;
  }>;
  itemsProps?: GridProps;
  containerProps?: GridProps;
};

export const DataGrid: React.FC<DataGridProperties> = (properties) => {
  const { items, itemsProps, containerProps } = properties;

  return (
    <Grid container {...containerProps}>
      {items.map((item) => (
        <Grid item xs={item.width} key={item.label} {...itemsProps}>
          <Div sx={styles.cellLabel}>{item.label}</Div>
          <Div sx={styles.cellValue}>{item.value}</Div>
        </Grid>
      ))}
    </Grid>
  );
};
