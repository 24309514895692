import { Button, Paper, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Div } from '@bvi/common-components';

import {
  ISearchFormDataSchema,
  OnOptionsLoad,
  useSearchContext,
} from '../../lib';
import { i18nInstance } from '../../locales';

import { AdvancedSearch } from './AdvancedSearch';
import { InitialSearch } from './InitialSearch';
import { styles } from './styles';

type RefineFilterProperties = {
  onOptionsLoad: OnOptionsLoad;
  isFormLoading?: boolean;
};

export const RefineFilter: React.FC<RefineFilterProperties> = (properties) => {
  const { t } = useTranslation('', {
    i18n: i18nInstance,
    keyPrefix: '',
  });

  const { onOptionsLoad, isFormLoading } = properties;
  const { onSubmit } = useSearchContext();
  const { handleSubmit } = useFormContext<ISearchFormDataSchema>();

  return (
    <Paper sx={styles.wrapper}>
      <Typography variant="h3">{t('refineFilter.title')}</Typography>

      <Div>
        <InitialSearch
          onOptionsLoad={onOptionsLoad}
          isFormLoading={isFormLoading}
        />
        <AdvancedSearch
          onOptionsLoad={onOptionsLoad}
          isFormLoading={isFormLoading}
        />

        <Button
          variant="contained"
          size="small"
          fullWidth
          onClick={handleSubmit(onSubmit)}
          sx={styles.submit}
        >
          {t('initialFilter.submitButton')}
        </Button>
      </Div>
    </Paper>
  );
};
