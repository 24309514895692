import { TFunction } from 'i18next';
import { z } from 'zod';

export const createCaseNoteFormDefaultValues = {
  title: '',
  text: '',
};

export const buildValidationSchema = (t: TFunction) => {
  const createCaseNoteFormSchema = {
    title: z.string().nonempty(t('error.form.required')).max(255).default(''),
    text: z.string().nonempty(t('error.form.required')).max(1500).default(''),
  };

  return z.object(createCaseNoteFormSchema);
};

type Schema = ReturnType<typeof buildValidationSchema>;

export type ICreateCaseNoteFormData = z.infer<Schema>;
