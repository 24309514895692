export enum AuthErrorsType {
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  COULD_NOT_CREATE_USER = 'COULD_NOT_CREATE_USER',
  COULD_NOT_FIND_USER = 'COULD_NOT_FIND_USER',
  COULD_NOT_GENERATE_TOKEN = 'COULD_NOT_GENERATE_TOKEN',
  USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS',
  COULD_NOT_INVALIDATE_REFRESH_TOKEN = 'COULD_NOT_INVALIDATE_REFRESH_TOKEN',
  INVALID_ACCESS_TOKEN = 'INVALID_ACCESS_TOKEN',
  INVALID_PASSWORD_RECOVERY_CONFIRMATION_CODE = 'INVALID_PASSWORD_RECOVERY_CONFIRMATION_CODE',
  PASSWORD_RECOVERY_CONFIRMATION_CODE_EXPIRED = 'PASSWORD_RECOVERY_CONFIRMATION_CODE_EXPIRED',
  TOO_MANY_ATTEMPTS_FOR_PASSWORD_RECOVERY = 'TOO_MANY_ATTEMPTS_FOR_PASSWORD_RECOVERY',
}
