import { theme, SxProperties } from '@bvi/mui-theme';

export const styles = {
  headerCell: {
    color: theme.palette.grey[300],
    fontWeight: '300',
    fontSize: '16px',
    backgroundColor: 'transparent',
    padding: 2,
  },
  valueCell: {
    fontFamily: 'Lato',
    fontWeight: '700',
    padding: 2,
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleSkeleton: {
    minWidth: '100px',
  },
  stickyWrapper: {
    position: 'sticky',
    top: '0',
    backgroundColor: 'white',
    borderRadius: '10px',
    zIndex: 5,
  },
} satisfies SxProperties;
