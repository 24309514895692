import { TagDescription } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import isObject from 'lodash/isObject';

import { isPaginationPayload } from '@bvi/axios-query';

export enum TagTypes {
  CURRENT_USER = 'CURRENT_USER',
  COMPANY_SIZES = 'COMPANY_SIZES',

  COMPANY_USERS = 'COMPANY_USERS',

  USER_CASES_LIST = 'USER_CASES_LIST',
  USER_CASE = 'USER_CASE',

  CASE_NOTES = 'CASE_NOTES',

  CASE_STATISTICS = 'CASE_STATISTICS',
  CASE_SETTINGS = 'CASE_SETTINGS',

  SAVED_SEARCHES = 'SAVED_SEARCHES',
}
export const LIST_ID = 'LIST';

export const getListTag = (type: TagTypes): TagDescription<TagTypes> => ({
  type,
  id: LIST_ID,
});

export const getItemTag = (type: TagTypes, id: string | number) => ({
  type,
  id,
});

const isObjectWithId = (item: unknown): item is { id: string | number } =>
  Boolean(
    isObject(item) &&
      'id' in item &&
      ['string', 'number'].includes(typeof item.id),
  );

export const provideTagsList =
  <T extends TagTypes>(...tags: Array<T>) =>
  <R>(result?: R): Array<TagDescription<TagTypes>> => {
    const tagsDescription = tags.flatMap((item) => ({
      type: item,
      id: LIST_ID,
    }));

    const isSuccessResult =
      isObject(result) && 'success' in result && result.success === true;

    if (!isSuccessResult) {
      return tagsDescription;
    }

    if (!('payload' in result)) {
      return tagsDescription;
    }

    const { payload } = result;

    if (!isPaginationPayload(payload)) {
      return tagsDescription;
    }

    const dataTags = payload.data.flatMap((item) => {
      if (!isObjectWithId(item)) {
        return [];
      }

      return tags.map((tag) => ({
        type: tag,
        id: item.id,
      }));
    });

    return [...tagsDescription, ...dataTags];
  };

export const invalidateTagsList =
  <T extends TagTypes>(...tags: Array<T>) =>
  <R, E>(_result: R, error: E): Array<TagDescription<TagTypes>> =>
    error ? [] : tags.map((tag) => ({ type: tag, id: LIST_ID }));

export const getItemTags = (
  type: TagTypes,
  items: Array<{ id: string | number }>,
) =>
  items.map((item) => ({
    type,
    id: item.id,
  }));

export const invalidateTags =
  (...tags: Array<TagDescription<TagTypes>>) =>
  <R, E>(_result: R, error: E) =>
    error ? [] : tags;
