import { createApi } from '@reduxjs/toolkit/query/react';
import axios, { AxiosError } from 'axios';

import { AuthErrorsType } from '@bvi/api-interfaces/error/auth';
import { IErrorBaseResponse } from '@bvi/api-interfaces/response/base';
import { axiosBaseQuery } from '@bvi/axios-query';
import { routes } from '@bvi/dashboard/api-routes';
import { API_URL } from '@bvi/dashboard/config';

import { AuthRoutesPaths } from '../constants/routes';

import { TagTypes } from './tag-types';

export const apiContainer = createApi({
  reducerPath: 'api',
  tagTypes: Object.values(TagTypes),
  baseQuery: axiosBaseQuery({
    baseURL: API_URL,
    refreshTokenOptions: {
      shouldRefresh: (errorResponse: AxiosError<IErrorBaseResponse>) =>
        errorResponse.response?.data.error.type ===
        AuthErrorsType.INVALID_ACCESS_TOKEN,
    },
    async refreshTokenLogic(_error) {
      try {
        return await axios.post(API_URL + routes.usersAuth.refreshToken());
      } catch {
        const isAuthRoute = Object.values(AuthRoutesPaths)
          .map(String)
          .includes(window.location.pathname);

        if (!isAuthRoute) {
          window.location.href = AuthRoutesPaths.SIGN_IN;
        }
        return;
      }
    },
    onError: async (error) => {
      if (
        error.response?.status === 403 &&
        window.location.pathname !== AuthRoutesPaths.SIGN_IN
      ) {
        window.location.href = AuthRoutesPaths.SIGN_IN;
      }
    },
  }),
  endpoints: () => ({}),
});
