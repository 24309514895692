import { useContext, createContext } from 'react';

import { ISearchContext } from './types';

export const SearchContext = createContext<ISearchContext>(
  {} as ISearchContext,
);

export const useSearchContext = () => {
  const context = useContext(SearchContext);

  if (!context) {
    throw new Error(
      'useSearchContext must be used within a SearchContextProvider',
    );
  }

  return context;
};
