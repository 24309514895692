import { VictimInfoAgeCategoryVariantKeys } from '@bvi/api-interfaces/entity/case-property';

export const SEARCH_PARAMS_KEY = 'search-params';

export const parentAgeCategories = {
  [VictimInfoAgeCategoryVariantKeys.ADULT]:
    VictimInfoAgeCategoryVariantKeys.ADULT,
  [VictimInfoAgeCategoryVariantKeys.MINOR]:
    VictimInfoAgeCategoryVariantKeys.MINOR,
  [VictimInfoAgeCategoryVariantKeys.PREPUBESCENT]:
    VictimInfoAgeCategoryVariantKeys.PREPUBESCENT,
  [VictimInfoAgeCategoryVariantKeys.TEEN]:
    VictimInfoAgeCategoryVariantKeys.TEEN,
};

export const refineAgeCategories = {
  [VictimInfoAgeCategoryVariantKeys.MINOR_TO_ADULT]:
    VictimInfoAgeCategoryVariantKeys.MINOR_TO_ADULT,
  [VictimInfoAgeCategoryVariantKeys.PREPUBESCENT_TO_ADULT]:
    VictimInfoAgeCategoryVariantKeys.PREPUBESCENT_TO_ADULT,
  [VictimInfoAgeCategoryVariantKeys.PREPUBESCENT_TO_TEEN]:
    VictimInfoAgeCategoryVariantKeys.PREPUBESCENT_TO_TEEN,
  [VictimInfoAgeCategoryVariantKeys.TEEN_TO_ADULT]:
    VictimInfoAgeCategoryVariantKeys.TEEN_TO_ADULT,
};
