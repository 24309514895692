import { styled } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';

export const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  '.MuiDataGrid-columnHeader': {
    backgroundColor: theme.palette['secondary'].main,
    color: theme.palette['primary'].main,
  },
  '.MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bold',
  },
  '& .MuiDataGrid-main': {
    overflow: 'unset',
  },
  '& .MuiDataGrid-columnHeaders': {
    position: 'sticky',
    top: '293px',
    zIndex: 4,
  },
  '& .MuiDataGrid-virtualScroller': {
    marginTop: '0!important',
  },
}));
