import { SignUpFormStep } from '../types';

import { CompanyStep } from './company-step';
import { CredentialsStep } from './credentials-step';
import { PersonalStep } from './personal-step';

type SignupStepsProperties = {
  step: SignUpFormStep;
};

const components = {
  [SignUpFormStep.credential]: () => <CredentialsStep />,
  [SignUpFormStep.personal]: () => <PersonalStep />,
  [SignUpFormStep.company]: () => <CompanyStep />,
};

export const SignupSteps: React.FC<SignupStepsProperties> = (properties) => {
  const { step } = properties;

  return components[step] ? components[step]() : null;
};
