import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Image, check } from '@bvi/common-components';

import { styles } from './styles';

type SuccessMessageProperties = {
  email?: string;
};

export const SuccessMessage: React.FC<SuccessMessageProperties> = (
  properties,
) => {
  const { email } = properties;
  const { t } = useTranslation();

  return (
    <Stack direction="column" alignItems="center" sx={styles.wrapper}>
      <Image src={check} sx={styles.checkIcon} />
      <Typography align="center" variant="h1" my={3}>
        {t('auth.resetPassword.success.title')}
      </Typography>
      {email && (
        <Typography align="center" sx={styles.email}>
          {email}
        </Typography>
      )}
      <Typography align="center" variant="body1" my={2}>
        {t('auth.resetPassword.success.text')}
      </Typography>
    </Stack>
  );
};
