import { TabContext, TabList, TabPanel } from '@mui/lab';
import { CircularProgress, Stack, Tab } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Div } from '@bvi/common-components';
import { useUserCaseQuery } from '@bvi/dashboard/entities/case/api-slice';
import { selectUserCase } from '@bvi/dashboard/entities/case/selectors';
import { CasesDetailsHeader } from '@bvi/dashboard/feature/cases-details/ui/cases-details-header';
import { CasesDetailsInformation } from '@bvi/dashboard/feature/cases-details/ui/cases-details-information';
import { CasesDetailsNotes } from '@bvi/dashboard/feature/cases-details/ui/cases-details-notes';
import { CasesDetailsResolution } from '@bvi/dashboard/feature/cases-details/ui/cases-details-resolution';
import { CasesDetailsSearches } from '@bvi/dashboard/feature/cases-details/ui/cases-details-searches';

import { CasesDetailsTab } from './types';

export const CasesDetails = () => {
  const [value, setValue] = useState(CasesDetailsTab.INFO);
  const { t } = useTranslation();

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: CasesDetailsTab,
  ) => {
    setValue(newValue);
  };

  const { id } = useParams();
  const caseId = Number(id);
  const { isLoading } = useUserCaseQuery({ id: caseId });

  const _case = useSelector(selectUserCase(caseId));

  if (isLoading) {
    return (
      <Stack direction="row" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Div>
      <CasesDetailsHeader caseData={_case} />
      <TabContext value={value}>
        <Div>
          <TabList onChange={handleChange}>
            <Tab
              label={t(`cases.details.tabs.${CasesDetailsTab.INFO}`)}
              value={CasesDetailsTab.INFO}
            />
            <Tab
              label={t(`cases.details.tabs.${CasesDetailsTab.RESOLUTION}`)}
              value={CasesDetailsTab.RESOLUTION}
            />
            <Tab
              label={t(`cases.details.tabs.${CasesDetailsTab.SEARCHES}`)}
              value={CasesDetailsTab.SEARCHES}
            />
            <Tab
              label={t(`cases.details.tabs.${CasesDetailsTab.NOTES}`)}
              value={CasesDetailsTab.NOTES}
            />
          </TabList>
        </Div>
        <TabPanel value={CasesDetailsTab.INFO}>
          <CasesDetailsInformation caseData={_case} />
        </TabPanel>
        <TabPanel value={CasesDetailsTab.RESOLUTION}>
          <CasesDetailsResolution caseData={_case} />
        </TabPanel>
        <TabPanel value={CasesDetailsTab.SEARCHES}>
          <CasesDetailsSearches caseData={_case} />
        </TabPanel>
        <TabPanel value={CasesDetailsTab.NOTES}>
          <CasesDetailsNotes />
        </TabPanel>
      </TabContext>
    </Div>
  );
};
