import { useContext, createContext } from 'react';

import { ICasesFormProperties } from './types';

export const CasesFormContext = createContext<ICasesFormProperties>(
  {} as ICasesFormProperties,
);

export const useCasesFormContext = () => {
  const context = useContext(CasesFormContext);

  if (!context) {
    throw new Error(
      'useCasesFormContext must be used within a CasesFormContextProvider',
    );
  }

  return context;
};
