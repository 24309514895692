import { IBaseProperties } from '@bvi/api-interfaces/entity/base';
import { ICompany } from '@bvi/api-interfaces/entity/company';
import { IProfile } from '@bvi/api-interfaces/entity/profile';

export interface IUserBase extends IBaseProperties {
  email: string;
  status: UserStatus;
}

export interface IUserWithPassword extends IUserBase {
  password: string;
}

export interface IUserWithProfile extends IUserWithPassword {
  profile: IProfile;
}

export interface IUser extends IUserWithProfile {
  company?: ICompany;
}

export interface IUserMe extends IUser {
  hasActiveSubscription: boolean;
}

export enum UserStatus {
  WAITING_CONFIRMATION = 'waiting_confirmation',
  CONFIRMED = 'confirmed',
  DELETED = 'deleted',
}
