import { Button, Link, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { check, Image } from '@bvi/common-components';
import { AuthRoutesPaths } from '@bvi/dashboard/shared/constants/routes';

import { styles } from './styles';

export const SuccessMessage = () => {
  const { t } = useTranslation();

  return (
    <Stack direction="column" alignItems="center" sx={styles.wrapper}>
      <Image src={check} sx={styles.check} />
      <Typography align="center" variant="h1" my={4}>
        {t('auth.signUp.success.title')}
      </Typography>
      <Typography variant="body1" sx={styles.text}>
        {t('auth.signUp.success.text1')}
      </Typography>
      <Typography variant="body1" sx={styles.text}>
        {t('auth.signUp.success.text2')}
        <Link
          component="a"
          href={`mailto:${t('auth.signUp.success.email')}`}
          sx={styles.email}
        >
          {t('auth.signUp.success.email')}
        </Link>
      </Typography>
      <Button
        fullWidth
        variant="outlined"
        LinkComponent={Link}
        href={AuthRoutesPaths.SIGN_IN}
        sx={styles.button}
      >
        {t('auth.signUp.success.button')}
      </Button>
    </Stack>
  );
};
