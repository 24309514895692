import { TypographyOptions } from '@mui/material/styles/createTypography';

export const typography = {
  fontFamily: [
    'Lato',
    'Source Sans Pro',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
    '"Segoe UI"',
    'BlinkMacSystemFont',
    '-apple-system',
    'sans-serif',
    'Arial',
  ].join(','),
  h1: {
    fontWeight: 700,
    fontSize: 24,
    lineHeight: 1.3,
  },
  h3: {
    fontWeight: 700,
    fontSize: 18,
    marginTop: 16,
    marginBottom: 16,
  },
  h4: {
    fontSize: 16,
    fontWeight: 700,
    marginTop: 16,
    marginBottom: 16,
  },
  button: {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: 1.3,
  },
  caption: {
    fontWeight: 700,
    fontSize: 16,
  },
} satisfies TypographyOptions;
