import { theme } from '@bvi/mui-theme';

export const styles = {
  wrapper: {
    paddingTop: 1,
    paddingBottom: 3,
    px: 3,
  },
  title: {
    fontWeight: 700,
    mt: 2,
    mb: 1,
  },
  subtitle: {
    color: theme.palette.grey[300],
    fontWeight: '300',
    my: 2,
  },
  inputWrapper: {
    position: 'relative',
  },
  note: {
    color: 'text.secondary',
    fontWeight: '300',
    backgroundColor: 'background.default',
    p: '8px',
    fontSize: '14px',
    borderRadius: '4px',
  },
  radioGroup: {
    mt: 1,
  },
  switchDescription: {
    position: 'absolute',
    color: theme.palette.grey[300],
    fontSize: '12px',
  },
  switchInput: {
    ml: 4,
    mr: 1,
  },
};
