import {
  SearchLayout,
  SearchFilters,
  IGetSettingsQuery,
  SearchContext,
  mapFormDataToSubmit,
  ISearchFormDataSchema,
} from '@bvi/cases-search';
import { useLazyGetSettingsQuery } from '@bvi/dashboard/entities/search/api-slice';
import { SearchResult } from '@bvi/dashboard/feature/search/ui';
import { DashboardRoutesPaths } from '@bvi/dashboard/shared/constants/routes';
import { useNavigation } from '@bvi/navigation-utils';

const Filters = () => {
  const [getSettings] = useLazyGetSettingsQuery();
  const handleLoadSettings = async (data: IGetSettingsQuery) => {
    const result = await getSettings(data);

    return result.data?.success ? result.data.payload.data : {};
  };

  return <SearchFilters onLoadSettings={handleLoadSettings} />;
};

export const Search: React.FC = () => {
  const navigate = useNavigation();

  const handleSubmit = (data: ISearchFormDataSchema) => {
    const preparedData = mapFormDataToSubmit(data);

    navigate(DashboardRoutesPaths.SEARCH, preparedData);
  };

  return (
    <SearchContext.Provider
      value={{ onSubmit: handleSubmit, route: DashboardRoutesPaths.SEARCH }}
    >
      <SearchLayout filters={<Filters />} results={<SearchResult />} />
    </SearchContext.Provider>
  );
};
