import { Provider } from 'react-redux';

// eslint-disable-next-line boundaries/element-types
import { createStore } from '../store';

export const withStore = (component: () => React.ReactNode) => () => {
  const store = createStore();

  return <Provider store={store}>{component()}</Provider>;
};
