export enum RequestBaseError {
  FORBIDDEN = 'FORBIDDEN',
  UNAUTHORIZED = 'UNAUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
  INCORRECT_DATA = 'INCORRECT_DATA',
  INCORRECT_FILTER = 'INCORRECT_FILTER',
  UNKNOWN = 'UNKNOWN',
  ALREADY_DONE = 'ALREADY_DONE',
  RETRY_LATER = 'RETRY_LATER',
}

export interface IServerError<T = undefined> {
  type: RequestBaseError;
  additional?: T;
}
