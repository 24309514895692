import { SxProperties } from '@bvi/mui-theme';

export const styles = {
  list: {
    display: 'block',
  },
  item: {
    display: 'inline',
    marginRight: '15px',
  },
  label: {
    color: 'common.black',
    marginRight: '8px',
    fontWeight: '700',
    opacity: '0.6',
    whiteSpace: 'nowrap',
  },
  value: {
    color: 'common.black',
    fontWeight: '300',
  },
  closeIcon: {
    marginLeft: '4px',
    marginBottom: '3px',
    cursor: 'pointer',
    verticalAlign: 'middle',
  },
  resetButton: {
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
  },
  resetButtonIcon: {
    marginRight: '4px',
    marginTop: '1px',
  },
  tagSkeleton: {
    display: 'inline-block',
    marginRight: '15px',
    width: '100px',
    height: '20px',
  },
} satisfies SxProperties;
