import { CircularProgress } from '@mui/material';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { FC, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  ISearchQuery,
  ResultsChart,
  ResultsTable,
  SearchReport,
  buildParametersForSettings,
} from '@bvi/cases-search';
import { Div } from '@bvi/common-components';
import { useGetSavedSearchQuery } from '@bvi/dashboard/entities/saved-searches/api-slice';
import {
  selectSavedSearchEntry,
  selectSavedSearch,
} from '@bvi/dashboard/entities/saved-searches/selectors';
import { selectSettingsData } from '@bvi/dashboard/entities/search/selectors';
import { SavedSearchDetailsHeader } from '@bvi/dashboard/feature/saved-searches/ui/saved-search-details-header';
import { SavedSearchTags } from '@bvi/dashboard/feature/saved-searches/ui/saved-search-tags';
import { DateFormat, formatDate } from '@bvi/date-utils';

export const SavedSearchDetails: FC = () => {
  const { savedSearchId, entryId } = useParams();

  const query = {
    id: Number(savedSearchId),
  };

  const { isFetching } = useGetSavedSearchQuery(query);
  const searchEntry = useSelector(selectSavedSearch(query));
  const entry = selectSavedSearchEntry(searchEntry, Number(entryId));

  const shouldShowResults = searchEntry && entry;

  const searchParameters = Object.fromEntries(
    Object.entries(searchEntry?.parameters ?? {}).map(([key, value]) => [
      key,
      Array.isArray(value) ? value.join(',') : String(value),
    ]),
  ) as ISearchQuery;

  const settingsData = useSelector(
    selectSettingsData(buildParametersForSettings(searchParameters)),
  );

  const searchReportReference = useRef<HTMLDivElement>(null);
  const handleDownloadPdf = async () => {
    const document_ = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
    });

    const currentElement = searchReportReference.current;
    if (currentElement) {
      const canvas = await html2canvas(currentElement);
      const { offsetHeight } = currentElement;
      const imgData = canvas.toDataURL('image/jpeg');
      const fileName = `search-report_${formatDate(
        new Date(),
        DateFormat.defaultFullTime,
      )}`;

      const width = 448;
      const height = offsetHeight / 4;
      document_.addImage(imgData, 'jpeg', 0, 0, width, height);
      document_.save(fileName);
    }
  };

  if (isFetching) {
    return <CircularProgress />;
  }

  return (
    <Div>
      <>
        <SavedSearchDetailsHeader
          savedSearch={searchEntry}
          savedSearchEntry={entry}
          isLoading={isFetching}
          handleDownloadPdf={handleDownloadPdf}
        />
        {shouldShowResults && (
          <>
            <SavedSearchTags searchParameters={searchParameters} />
            <ResultsTable data={entry.statistics} />
            <ResultsChart data={entry.statistics} />
            <SearchReport
              ref={searchReportReference}
              statisticsData={entry.statistics}
              settingsData={settingsData}
              searchDate={entry.createdAt}
              searchParameters={searchParameters}
            />
          </>
        )}
      </>
    </Div>
  );
};
