export const styles = {
  button: {
    display: 'inline-block',
  },
  icon: {
    display: 'block',
    transform: 'rotate(180deg)',
    color: 'red',
  },
};
