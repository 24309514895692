import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';
import { useEffect, useState } from 'react';
import {
  UseFormProps,
  useForm,
  UseFormReturn,
  DefaultValues,
} from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import {
  StaticPropertyKey,
  PropertyKey,
} from '@bvi/api-interfaces/entity/case-property';
import { ISettingsData } from '@bvi/api-interfaces/response/case-settings';

import {
  IGetSettingsQuery,
  ISearchFormDataSchema,
  ISearchQuery,
} from './types';

type UseFormAsyncValuesProperties = UseFormProps<ISearchFormDataSchema> & {
  getSettings: (data: IGetSettingsQuery) => Promise<ISettingsData>;
  searchParameters: ISearchQuery;
  defaultValues: DefaultValues<ISearchFormDataSchema>;
};

type UseFormAsyncValuesReturnType = UseFormReturn<ISearchFormDataSchema> & {
  isFormLoading: boolean;
};

const singleFields = new Set<PropertyKey>([
  StaticPropertyKey.ORGANIZATION,
  StaticPropertyKey.DIOCESE,
]);

const multipleFields = new Set<PropertyKey>([
  StaticPropertyKey.COUNTRY,
  StaticPropertyKey.REGION,
  StaticPropertyKey.STATE,
  StaticPropertyKey.LIABILITY_DEFENSES,
  StaticPropertyKey.EXCEPTION,
  StaticPropertyKey.RESOLUTION_TYPE,
  StaticPropertyKey.ORGANIZATION,
  StaticPropertyKey.DIOCESE,
  StaticPropertyKey.ORGANIZATION_TYPE,
  StaticPropertyKey.JOB_TITLE,
  StaticPropertyKey.ORDER,
]);

const buildPaginationOptions = (fields: ISearchQuery) => {
  return mapValues(fields, (value) => ({
    ids: value,
    page: 1,
    limit: 100,
  }));
};

export const buildParametersForSettings = (search: ISearchQuery) => {
  const fieldValues = pickBy(search, (fieldValue, field) => {
    const fields = new Set([...singleFields, ...multipleFields]);
    return fields.has(field as PropertyKey) && !isEmpty(fieldValue);
  }) as ISearchQuery;

  return {
    includes: keys(fieldValues) as Array<PropertyKey>,
    pagination: buildPaginationOptions(fieldValues),
  };
};

export function useFormAsyncValues(
  properties: UseFormAsyncValuesProperties,
): UseFormAsyncValuesReturnType {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { defaultValues, getSettings, searchParameters } = properties;
  const formContext = useForm<ISearchFormDataSchema>(properties);
  const { reset } = formContext;

  const asyncLoadDefaultValues = async (_arguments: IGetSettingsQuery) => {
    setIsLoading(true);

    const result = await getSettings(_arguments);

    setIsLoading(false);

    const asyncDefaultValues = mapValues(result, (response, fieldName) => {
      const isSingleField = singleFields.has(fieldName as PropertyKey);

      return isSingleField ? response?.items[0] ?? null : response?.items;
    });

    reset({
      ...defaultValues,
      ...(asyncDefaultValues as Partial<ISearchFormDataSchema>),
    });
  };

  useEffect(() => {
    const settingsArguments = buildParametersForSettings(searchParameters);

    if (isEmpty(settingsArguments.includes)) {
      reset(defaultValues);
      return;
    }

    asyncLoadDefaultValues(settingsArguments);
  }, [location.search]);

  return {
    ...formContext,
    isFormLoading: isLoading,
  };
}
