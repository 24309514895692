import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
} from '@mui/material';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    mainColumn: true;
    smallButton: true;
  }
}

interface IMuiTypography {
  defaultProps: ComponentsProps['MuiTypography'];
  styleOverrides: ComponentsOverrides['MuiTypography'];
  variants: ComponentsVariants['MuiTypography'];
}

export const MuiTypography = {
  defaultProps: {},
  styleOverrides: {},
  variants: [
    {
      props: { variant: 'mainColumn' },
      style: ({ theme }) => ({
        fontWeight: 'bold',
        color: theme.palette.primary.main,
      }),
    },
    {
      props: { variant: 'smallButton' },
      style: {
        fontSize: 16,
        fontWeight: 'bold',
      },
    },
  ],
} satisfies IMuiTypography;
