import { MenuItem } from '@mui/material';
import { useState } from 'react';

import { ConfirmActionModal } from '../';

import { IConfirmActionMenuItemProperties } from './types';

export const ConfirmActionMenuItem: React.FC<
  React.PropsWithChildren<IConfirmActionMenuItemProperties>
> = (properties) => {
  const {
    question,
    confirm,
    cancel,
    onClick,
    component = MenuItem,
    componentProperties = {},
    children,
  } = properties;
  const [isOpen, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    await onClick?.(event);
    handleClose();
  };

  const ButtonItem = component;

  return (
    <>
      <ButtonItem onClick={handleOpen} {...componentProperties}>
        {children}
      </ButtonItem>
      <ConfirmActionModal
        question={question}
        confirm={confirm}
        cancel={cancel}
        open={isOpen}
        onSubmit={handleSubmit}
        onClose={handleClose}
      />
    </>
  );
};
