import { CircularProgress } from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { IUserCaseWithCaseData } from '@bvi/api-interfaces/response/case';
import { Div } from '@bvi/common-components';
import { useGetSavedSearchesQuery } from '@bvi/dashboard/entities/saved-searches/api-slice';
import { selectSavedSearches } from '@bvi/dashboard/entities/saved-searches/selectors';
import { styles } from '@bvi/dashboard/feature/cases-details/ui/cases-details-searches/styles';
import { SavedSearchesTable } from '@bvi/dashboard/feature/saved-searches/ui/saved-searches-table';

export interface ICasesDetailsSearchesParameters {
  caseData: IUserCaseWithCaseData;
}

export const CasesDetailsSearches: FC<ICasesDetailsSearchesParameters> = ({
  caseData,
}) => {
  const query = { userCaseIds: String(caseData.id) };
  const { isFetching } = useGetSavedSearchesQuery(query);
  const savedSearches = useSelector(selectSavedSearches(query));

  if (isFetching) {
    return <CircularProgress />;
  }

  return (
    <Div sx={styles.container}>
      <SavedSearchesTable savedSearches={savedSearches} />
    </Div>
  );
};
