import { Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ISavedSearchData } from '@bvi/api-interfaces/response/case';
import { RepeatSearchButton } from '@bvi/dashboard/feature/saved-searches/ui/repeat-search-button';

import { styles } from '../styles';

export interface IRepeatSavedSearchActionButtonParameters {
  savedSearch: ISavedSearchData;
}

export const RepeatSavedSearchActionButton: FC<
  IRepeatSavedSearchActionButtonParameters
> = ({ savedSearch }) => {
  const { t } = useTranslation();

  return (
    <RepeatSearchButton
      savedSearch={savedSearch}
      size="small"
      sx={styles.searchActionButton}
    >
      <Typography variant="smallButton">
        {t('savedSearches.buttons.repeatSearch.caption')}
      </Typography>
    </RepeatSearchButton>
  );
};
