import { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { usePrefetch } from '@bvi/dashboard/entities/clients/api-slice';
import {
  LoginPage,
  ResetPasswordPage,
  SignupPage,
  RestorePasswordPage,
} from '@bvi/dashboard/pages';
import { AuthRoutesPaths } from '@bvi/dashboard/shared/constants/routes';
import { AuthLayout } from '@bvi/dashboard/widgets/layouts';

export const AuthRoutes = () => {
  const prefetchCompanySizes = usePrefetch('getCompanySizes');

  useEffect(() => {
    prefetchCompanySizes();
  });

  return (
    <AuthLayout>
      <Routes>
        <Route path={AuthRoutesPaths.SIGN_IN} element={<LoginPage />} />
        <Route path={AuthRoutesPaths.SIGN_UP} element={<SignupPage />} />
        <Route
          path={AuthRoutesPaths.RESET_PASSWORD}
          element={<ResetPasswordPage />}
        />
        <Route
          path={AuthRoutesPaths.RESTORE_PASSWORD}
          element={<RestorePasswordPage />}
        />
        <Route path="*" element={<Navigate to={AuthRoutesPaths.SIGN_IN} />} />
      </Routes>
    </AuthLayout>
  );
};
