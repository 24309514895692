import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
} from '@mui/material';
import { Theme } from '@mui/system';

import { palette } from '../constants';

interface IMuiButtonType {
  defaultProps: ComponentsProps['MuiButton'];
  styleOverrides: ComponentsOverrides<Theme>['MuiButton'];
  variants: ComponentsVariants['MuiButton'];
}

export const MuiButton = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      padding: '16px 20px',
      borderRadius: 8,
      textTransform: 'none',
      height: 'fit-content',
    },
    sizeSmall: {
      padding: '10px 16px',
      fontSize: 18,
      lineHeight: '24px',
    },
    contained: ({ theme }) => ({
      color: theme.palette['common'].main,
    }),
  },
  variants: [
    {
      props: { variant: 'notificationButton' },
      style: {
        color: palette['common'].white,
        fontSize: '14px!important',
        border: '1px solid white',
        padding: 1,
        font: '1.2rem',
      },
    },
  ],
} satisfies IMuiButtonType;

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    notificationButton: true;
  }
}
