import { TFunction } from 'i18next';
import { z } from 'zod';

export const buildValidationSchema = (t: TFunction) => {
  const validationFields = {
    password: z
      .string()
      .min(1, t('error.form.required'))
      .min(
        8,
        t('error.form.short', {
          field: t('auth.restorePassword.form.password.label'),
          count: 8,
        }),
      )
      .default(''),
    confirmPassword: z
      .string()
      .min(1, t('error.form.required'))
      .min(
        8,
        t('error.form.short', {
          field: t('auth.restorePassword.form.password.label'),
          count: 8,
        }),
      )
      .default(''),
  };

  return z
    .object(validationFields)
    .refine((data) => data.password === data.confirmPassword, {
      message: t('error.form.password.mismatching'),
      path: ['confirmPassword'],
    });
};
