import { ComponentsOverrides, ComponentsProps } from '@mui/material';

import { LinkBehavior } from './LinkBehavior';

interface IMuiButtonBase {
  defaultProps: ComponentsProps['MuiButtonBase'];
  styleOverrides: ComponentsOverrides['MuiButtonBase'];
}

export const MuiButtonBase = {
  defaultProps: {
    LinkComponent: LinkBehavior,
  },
  styleOverrides: {},
} satisfies IMuiButtonBase;
