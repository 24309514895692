import { theme } from '@bvi/mui-theme';

export const styles = {
  container: {
    width: '400px',
    padding: '8px',
  },
  check: {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    border: `2px solid ${theme.palette.primary.main}`,
    p: 3,
  },
};
