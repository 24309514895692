import {
  FormHelperText,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Div } from '@bvi/common-components';

export interface IRangeItemProperties {
  fromField: string;
  toField: string;
  label: string;
  error?: boolean;
  helperText?: React.ReactNode;
}

export const RangeItem = (properties: IRangeItemProperties) => {
  const { fromField, toField, label, error, helperText } = properties;
  const { t } = useTranslation('translation');

  const { register } = useFormContext();

  return (
    <Div>
      <InputLabel>{label}</InputLabel>
      <Stack direction="row" gap={1} alignItems="center" mt={-1}>
        <TextField
          {...register(fromField, {
            setValueAs: (value) => (value ? Number(value) : null),
          })}
          variant="filled"
          fullWidth
          type="number"
          inputProps={{ min: 0 }}
          placeholder={t('cases.form.range.from')}
        />
        <Typography variant="body1" fontWeight={300}>
          –
        </Typography>
        <TextField
          {...register(toField, {
            setValueAs: (value) => (value ? Number(value) : null),
          })}
          variant="filled"
          fullWidth
          type="number"
          inputProps={{ min: 0 }}
          placeholder={t('cases.form.range.to')}
        />
      </Stack>
      {error && <FormHelperText error>{helperText}</FormHelperText>}
    </Div>
  );
};
