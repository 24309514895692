import { Paper, Typography } from '@mui/material';

import { IUserCaseNoteData } from '@bvi/api-interfaces/response/case';
import { DataPopover } from '@bvi/common-components';
import { formatDate } from '@bvi/date-utils';

import { styles } from './styles';

type NoteItemProperties = {
  note: IUserCaseNoteData;
};

export const NoteItem: React.FC<NoteItemProperties> = (properties) => {
  const { createdAt, title, text } = properties.note;

  const date = formatDate(createdAt);

  return (
    <DataPopover>
      <Paper sx={styles.wrapper}>
        <Typography color="grey.300" mb={2}>
          {date}
        </Typography>
        <Typography variant="h4" mb={2}>
          {title}
        </Typography>
        <Typography mt={2} fontWeight={300}>
          {text}
        </Typography>
      </Paper>
    </DataPopover>
  );
};
