import { Link, TableCell, TableRow, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { ISavedSearchEntryData } from '@bvi/api-interfaces/response/case';
import { useDeleteSavedSearchEntryMutation } from '@bvi/dashboard/entities/saved-searches/api-slice';
import { SavedSearchActionButton } from '@bvi/dashboard/feature/saved-searches/ui/saved-searches-table/saved-search-action-button';
import { styles } from '@bvi/dashboard/feature/saved-searches/ui/saved-searches-table/saved-search-entry-row/styles';
import { DashboardRoutesPaths } from '@bvi/dashboard/shared/constants/routes';
import { formatDate } from '@bvi/date-utils';

export interface ISavedSearchEntryProperties {
  savedSearchId: number;
  entry: ISavedSearchEntryData;
}

export const SavedSearchEntryRow: FC<ISavedSearchEntryProperties> = ({
  savedSearchId,
  entry,
}) => {
  const { t } = useTranslation();

  const [deleteSavedSearchEntry] = useDeleteSavedSearchEntryMutation();
  const handleDeleteEntry = () =>
    deleteSavedSearchEntry({ searchId: savedSearchId, entryId: entry.id });

  const detailsPagePath = generatePath(
    DashboardRoutesPaths.SAVED_SEARCH_DETAILS,
    { savedSearchId: String(savedSearchId), entryId: String(entry.id) },
  );

  const name = `${entry.user.profile.firstName} ${entry.user.profile.lastName}`;

  return (
    <TableRow>
      <TableCell sx={styles.createdAtCell}>
        <Link underline="hover" href={detailsPagePath}>
          <Typography variant="mainColumn">
            {formatDate(entry.createdAt)}
          </Typography>
        </Link>
      </TableCell>
      <TableCell>
        <Typography fontWeight="lighter">{name}</Typography>
      </TableCell>
      <TableCell />
      <TableCell />
      <TableCell align="right">
        <SavedSearchActionButton
          caption={t('savedSearches.buttons.deleteSearch.caption')}
          question={t('savedSearches.buttons.deleteSearch.question')}
          confirm={t('dialog.confirm')}
          cancel={t('dialog.cancel')}
          onClick={handleDeleteEntry}
        />
      </TableCell>
    </TableRow>
  );
};
