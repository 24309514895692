import CloseIcon from '@mui/icons-material/Close';
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  FormHelperText,
  MenuItem,
  SelectProps as MuiSelectProperties,
  Typography,
  InputAdornment,
  IconButton,
  Chip,
} from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import { forwardRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { styles } from './styles';

type SelectItem = { id: string | number; name: string };
type SelectProperties = Omit<MuiSelectProperties, 'onChange'> & {
  field: string;
  items?: Array<SelectItem>;
  placeholder?: string;
  helperText?: React.ReactNode;
  error?: boolean;
  label?: React.ReactNode;
  fullWidth?: boolean;
  resettable?: boolean;
};

export const Select = forwardRef<HTMLInputElement, SelectProperties>(
  (properties, reference) => {
    const {
      field: name,
      items = [],
      fullWidth = true,
      placeholder,
      helperText,
      error,
      label,
      resettable,
      multiple,
      ...restProperties
    } = properties;

    const { control, resetField, setValue } = useFormContext();
    const { field } = useController({
      name,
      control,
    });

    const value = field.value;
    const selectId = `select_${name}`;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const renderValueOrPlaceholder = (selectValue: any) => {
      const isValueExist = !isEmpty(selectValue);

      if (isValueExist) {
        if (multiple) {
          const itemsList = items.filter((item) =>
            selectValue.includes(item.id),
          );

          return itemsList.map((selectItem) => (
            <Chip
              key={selectItem.id}
              label={selectItem.name}
              sx={styles.chip}
              onMouseDown={(event) => {
                event.stopPropagation();
              }}
              onDelete={(event) => {
                event.preventDefault();
                setValue(
                  name,
                  selectValue.filter((item: string) => item !== selectItem.id),
                );
              }}
            />
          ));
        }

        const item = items.find(({ id }) => id === value);

        return <>{item?.name}</>;
      }

      return <Typography sx={styles.placeholder}>{placeholder}</Typography>;
    };

    const handleResetFieldValue = () => {
      resetField(name, {
        defaultValue: multiple ? [] : null,
      });
    };

    return (
      <FormControl fullWidth={fullWidth} sx={styles.wrapper}>
        {label && (
          <InputLabel id={selectId} error={Boolean(error)}>
            {label}
          </InputLabel>
        )}
        <MuiSelect
          {...field}
          {...restProperties}
          ref={reference}
          value={value}
          renderValue={renderValueOrPlaceholder}
          displayEmpty
          fullWidth
          labelId={selectId}
          error={error}
          multiple={multiple}
          endAdornment={
            resettable &&
            value && (
              <InputAdornment position="end" sx={styles.endAdornment}>
                <IconButton
                  size="small"
                  sx={styles.resetButton}
                  onClick={handleResetFieldValue}
                >
                  <CloseIcon sx={styles.resetIcon} />
                </IconButton>
              </InputAdornment>
            )
          }
          sx={styles.select}
        >
          <MenuItem value="" disabled>
            {placeholder}
          </MenuItem>
          {items.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </MuiSelect>
        {helperText && (
          <FormHelperText error={error}>{helperText}</FormHelperText>
        )}
      </FormControl>
    );
  },
);
