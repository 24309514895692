import { ComponentsOverrides, ComponentsProps } from '@mui/material';
import { Theme } from '@mui/system';

import { palette } from '../constants';

interface IMuiStep {
  defaultProps: ComponentsProps['MuiStep'];
  styleOverrides: ComponentsOverrides<Theme>['MuiStep'];
}

export const MuiStep = {
  styleOverrides: {
    root: {
      svg: {
        fill: 'transparent',
        width: '40px',
        height: '40px',
        border: `1px solid ${palette.grey[300]}`,
        borderRadius: '50%',
        '& .MuiStepIcon-text ': {
          fill: palette.grey[300],
        },
        '&.Mui-completed': {
          cursor: 'pointer',
        },
        '&.Mui-active, &.Mui-completed': {
          fill: palette.primary.main,
          borderColor: palette.primary.main,
          '& .MuiStepIcon-text ': {
            fill: palette.common.white,
          },
        },
      },
    },
  },
  defaultProps: {},
} satisfies IMuiStep;
