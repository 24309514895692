import { PaletteOptions } from '@mui/material';

export const palette = {
  primary: {
    main: '#17276E',
    light: '#1F3F9D',
  },
  success: {
    main: '#14A319',
  },
  error: {
    main: '#d32f2f',
  },
  secondary: {
    main: '#E5E8F3',
    dark: '#DDDFE3',
    light: '#FAFBFF',
  },
  common: {
    black: '#0A0A0A',
    white: '#FFFFFF',
    red: '#C32A2A',
  },
  text: {
    secondary: '#898E9A',
  },
  background: {
    default: '#FAFAFA',
    paper: '#FFFFFF',
  },
  grey: {
    50: '#F5F6FC',
    100: '#DDDFE3',
    200: '#BCBEC3',
    300: '#898E9A',
  },
} satisfies PaletteOptions;

declare module '@mui/material/styles' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface CommonColors {
    black: string;
    white: string;
    red: string;
  }
}
