import {
  IFilterSearchesFormData,
  IFilterSearchesQuery,
  ISaveSearchBody,
  ISaveSearchFormData,
} from '@bvi/dashboard/feature/saved-searches/lib/schema';

export const mapSaveSearchFormDataToBody = (
  formData: ISaveSearchFormData,
): ISaveSearchBody => {
  const result: ISaveSearchBody = {
    name: formData.name,
    parameters: formData.parameters,
  };

  if (formData.userCase) {
    result.userCaseId = formData.userCase.id;
  }

  return result;
};

export const mapFilterSearchesQueryToFormData = (
  query?: IFilterSearchesQuery,
): IFilterSearchesFormData => {
  return {
    date: query?.dates === undefined ? null : new Date(Number(query.dates)),
  };
};

export const mapFilterSearchesFormDataToQuery = (
  formData: IFilterSearchesFormData,
): IFilterSearchesQuery => {
  if (formData.date) {
    return {
      dates: String(formData.date.getTime()),
    };
  }

  return undefined;
};
