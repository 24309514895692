import { MoreVert } from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { CaseEvents } from '@bvi/api-interfaces/entity/case';
import { IUserCaseWithCaseData } from '@bvi/api-interfaces/response/case';
import { isServerError } from '@bvi/axios-query';
import { ConfirmActionMenuItem } from '@bvi/common-components';
import {
  useChangeStatusMutation,
  useRemoveCaseMutation,
} from '@bvi/dashboard/entities/case/api-slice';
import { mapCaseDataToSearch } from '@bvi/dashboard/feature/cases-details/lib/mapCaseDataToSearch';
import { DashboardRoutesPaths } from '@bvi/dashboard/shared/constants/routes';
import { useNavigation } from '@bvi/navigation-utils';
import { NotistackMessageVariants, useNotistack } from '@bvi/notistack';

import { styles } from './styles';

export interface IManageCaseMenuProperties {
  case: IUserCaseWithCaseData;
}

interface IMenuItem {
  title: string;
  sx?: Record<string, unknown>;
  action?: () => void;
}

export const ManageCaseMenu = ({
  case: userCase,
}: IManageCaseMenuProperties) => {
  const caseId = userCase.id;
  const { t } = useTranslation();
  const navigate = useNavigation();

  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const handleClickOnMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    setAnchorElement(event.currentTarget);
  };

  const { showNotificationMessage } = useNotistack();

  const [changeStatus, { isLoading: isChangeStatusPending }] =
    useChangeStatusMutation();
  const [removeCase, { isLoading: isRemoveCasePending }] =
    useRemoveCaseMutation();

  const isDisabled = isChangeStatusPending || isRemoveCasePending;

  const handleCloseMenu = () => {
    setAnchorElement(null);
  };

  const handleChangeStatus = async (event: CaseEvents) => {
    try {
      await changeStatus([caseId, { event }]).unwrap();
      handleCloseMenu();
    } catch (error) {
      if (isServerError(error)) {
        showNotificationMessage(
          { type: error.type },
          NotistackMessageVariants.ERROR,
        );
      }
    }
  };

  const handleRemoveCase = async () => {
    try {
      await removeCase(userCase.id).unwrap();
      handleCloseMenu();
    } catch (error) {
      if (isServerError(error)) {
        showNotificationMessage(
          { type: error.type },
          NotistackMessageVariants.ERROR,
        );
      }
    }
  };

  const handleSearch = () => {
    const preparedData = mapCaseDataToSearch(userCase.case);

    navigate(DashboardRoutesPaths.SEARCH, preparedData);
  };

  const MENU_ITEMS: Array<IMenuItem> = [
    {
      title: 'search',
      action: () => handleSearch(),
    },
    {
      title: 'close',
      action: () => {
        handleChangeStatus(CaseEvents.Close);
      },
    },
    {
      title: 'submit',
      action: () => {
        handleChangeStatus(CaseEvents.Submit);
      },
    },
    {
      title: 'edit',
      action: () => {
        navigate(
          generatePath(DashboardRoutesPaths.CASES_EDIT, {
            id: String(caseId),
          }),
        );
      },
    },
  ];

  return (
    <>
      <IconButton onClick={handleClickOnMenu}>
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={handleCloseMenu}
      >
        {MENU_ITEMS.map((item) => (
          <MenuItem
            key={item.title}
            sx={item.sx}
            onClick={item.action}
            disabled={isDisabled}
          >
            {t(`cases.list.active.menu.${item.title}`)}
          </MenuItem>
        ))}
        <ConfirmActionMenuItem
          question={t('cases.list.active.menu.delete.question')}
          confirm={t('dialog.confirm')}
          cancel={t('dialog.cancel')}
          componentProperties={{
            disabled: isRemoveCasePending,
            sx: styles.delete,
          }}
          onClick={handleRemoveCase}
        >
          {t('cases.list.active.menu.delete.caption')}
        </ConfirmActionMenuItem>
      </Menu>
    </>
  );
};
