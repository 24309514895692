import { Stack } from '@mui/material';

import { CasesCreateForm } from '@bvi/dashboard/widgets/cases-form/ui/create-form';

export const CreateCasePage = () => {
  return (
    <Stack direction="column">
      <CasesCreateForm />
    </Stack>
  );
};
