import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { FC, useRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { Image } from '../Image';
import { search } from '../icons';

import { styles } from './styles';

export interface ISeachTextFieldProperties
  extends Omit<TextFieldProps, 'type'> {
  field: string;
}

export const SearchTextField: FC<ISeachTextFieldProperties> = (properties) => {
  const { field: fieldName } = properties;

  const {
    formState: { errors },
    control,
  } = useFormContext();
  const inputReference = useRef<HTMLInputElement>();

  const { field } = useController({
    name: fieldName,
    control,
  });

  const moveCursorToInputEnd = () => {
    const { current } = inputReference;
    if (current) {
      current.focus();
      current.setSelectionRange(current.value.length, current.value.length);
    }
  };

  const hasError = Boolean(errors[fieldName]);

  return (
    <TextField
      {...field}
      inputRef={inputReference}
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="end"
            sx={styles.icon}
            onClick={moveCursorToInputEnd}
          >
            <Image src={search} alt="search icon" />
          </InputAdornment>
        ),
      }}
      error={hasError}
      {...properties}
    />
  );
};
