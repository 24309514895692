import get from 'lodash/get';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';
import set from 'lodash/set';
import { UnionToIntersection, Split } from 'type-fest';

import {
  CaseInformation,
  StaticPropertyKey,
} from '@bvi/api-interfaces/entity/case-property';

import { ICaseFormData } from '../types';

const mapCost = (amount?: number | null, currencyId?: number) => {
  if (!amount || !currencyId) {
    return null;
  }

  return {
    amount,
    currencyId,
  };
};

const relationFields = [
  'resolution.type',
  'resolution.total.currency',
  'resolution.defenseCosts.currency',
  'resolution.perClaimant.currency',
  StaticPropertyKey.COUNTRY,
  StaticPropertyKey.REGION,
  StaticPropertyKey.STATE,
  StaticPropertyKey.ORDER,
  StaticPropertyKey.ORGANIZATION,
  StaticPropertyKey.ORGANIZATION_TYPE,
  StaticPropertyKey.DIOCESE,
  StaticPropertyKey.JOB_TITLE,
  StaticPropertyKey.DAMAGE,
  StaticPropertyKey.EXCEPTION,
  StaticPropertyKey.GROUP,
] as const;

type RelationFields = typeof relationFields;
type RelationPaths = Split<RelationFields[number], '.'>;

type NestedObjectFromPath<Path extends Array<string>> = Path extends [
  infer Head,
  ...infer Rest,
]
  ? Head extends string
    ? Rest extends Array<string>
      ? NestedObjectFromPath<Rest> extends never
        ? { [K in `${Head}Id`]: number }
        : { [K in Head]: NestedObjectFromPath<Rest> }
      : never
    : never
  : never;

type NestedSimplify<T> = T extends object
  ? { [K in keyof T]: NestedSimplify<T[K]> }
  : T;

type Relations = NestedSimplify<
  UnionToIntersection<NestedObjectFromPath<RelationPaths>>
>;

const mapRelations = (data: ICaseFormData) => {
  const fields = pick(data, relationFields);
  const result = {} as Relations;
  for (const field of relationFields) {
    const value = get(fields, field);

    const path = field.split('.');
    path[path.length - 1] = `${path.at(-1)}Id`;

    if (value) {
      set(result, path.join('.'), value.id);
    } else if (path.length === 1) {
      // only for not resolution fields
      set(result, path[0], null);
    }
  }

  return result;
};

export const mapFormDataToSubmit = (formData: ICaseFormData) => {
  const omittedData = omit(formData, relationFields);
  const properties_ = omit(omittedData, [
    ...Object.values(StaticPropertyKey),
    'resolution',
  ]);

  const relationData = mapRelations(formData);

  const data = {
    ...omittedData,
    ...relationData,
    resolution: {
      ...omittedData.resolution,
      ...relationData.resolution,
    },
  };

  const {
    fileName,
    isResolved,
    description,
    [CaseInformation.CLAIMANT_NAME]: claimantName,
    resolution,
    perpetratorName,
  } = data;

  const clearProperties = omitBy(
    properties_,
    (property) => property === null || property === '',
  );

  const formattedProperties = Object.entries(clearProperties).map(
    ([key, value]) => {
      return [key, { value }];
    },
  );
  const properties = Object.fromEntries(formattedProperties);

  if (perpetratorName) {
    Object.assign(properties, {
      name: {
        value: perpetratorName,
      },
    });
  }

  const {
    countryId,
    regionId,
    stateId,
    organizationId,
    orderId,
    dioceseId,
    jobTitleId,
    exceptionId,
    damageId,
    groupId,
    organizationTypeId,
    resolution: resolutionRelations,
  } = relationData;

  const dataToSend = {
    name: fileName,
    isResolved,
    description,
    claimantName,
    countryId,
    regionId,
    stateId,
    orderId,
    dioceseId,
    jobTitleId,
    organizationId,
    organizationTypeId,
    exceptionId,
    groupId,
    liabilityDefensesIds: data[StaticPropertyKey.LIABILITY_DEFENSES]?.map(
      (item) => item.id,
    ),
    damageId,
    properties,
  };

  if (!isResolved) {
    return { ...dataToSend, resolution: null };
  }

  return {
    ...dataToSend,
    resolution: {
      ...resolution,
      ...resolutionRelations,
      typeId: resolutionRelations?.typeId ?? null,
      total: mapCost(
        omittedData.resolution?.total?.amount,
        resolutionRelations?.total?.currencyId,
      ),
      perClaimant: mapCost(
        omittedData.resolution?.perClaimant?.amount,
        resolutionRelations?.perClaimant?.currencyId,
      ),
      defenseCosts: mapCost(
        omittedData.resolution?.defenseCosts?.amount,
        resolutionRelations?.defenseCosts?.currencyId,
      ),
    },
  };
};
