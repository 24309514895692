import { theme } from '@bvi/mui-theme';

export const styles = {
  wrapper: {
    position: 'relative',
  },
  placeholder: {
    color: theme.palette.grey[300],
  },
  resetButton: {
    marginRight: '27px',
    width: '28px',
    height: '28px',
  },
  resetIcon: {
    width: '20px',
    height: '20px',
  },
  endAdornment: {
    position: 'absolute',
    height: '100%',
    maxHeight: '100%',
    right: 0,
    top: 0,
  },
  select: {
    backgroundColor: 'grey.50',

    '&.Mui-focused, &:hover': {
      '& .MuiButtonBase-root': {
        display: 'inline-flex',
      },
    },
    '&.Mui-focused': {
      backgroundColor: 'grey.50',
    },
  },
  chip: {
    height: '26px',
    marginRight: '3px',
  },
};
