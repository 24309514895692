import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { UserErrorsType } from '@bvi/api-interfaces/error/user';
import { isServerError } from '@bvi/axios-query';
import { Div, Form, Logo, PasswordTextField } from '@bvi/common-components';
import { useResetPasswordMutation } from '@bvi/dashboard/entities/user-auth/api-slice';
import { NotistackMessageVariants, useNotistack } from '@bvi/notistack';

import { buildValidationSchema } from './schema';
import { styles } from './styles';
import { SuccessMessage } from './success-messsage';
import { IRestorePasswordFormData } from './types';

export const RestorePasswordForm = () => {
  const { t } = useTranslation();
  const validationSchema = buildValidationSchema(t);
  const methods = useForm<IRestorePasswordFormData>({
    resolver: zodResolver(validationSchema),
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const [resetPassword, { isLoading, isSuccess, error }] =
    useResetPasswordMutation();

  const [searchParameters] = useSearchParams();

  const { showNotificationMessage } = useNotistack();

  const onSubmit = useCallback(
    async (data: IRestorePasswordFormData) => {
      const token = searchParameters.get('token');

      if (!token) {
        return showNotificationMessage(
          { type: UserErrorsType.RESET_PASSWORD_TOKEN_NOT_FOUND },
          NotistackMessageVariants.ERROR,
        );
      }

      await resetPassword({
        token,
        password: data.password,
      });
    },
    [searchParameters, resetPassword, showNotificationMessage],
  );

  useEffect(() => {
    if (isServerError(error)) {
      showNotificationMessage(
        { type: error.type },
        NotistackMessageVariants.ERROR,
      );
    }
  }, [error, showNotificationMessage]);

  if (isSuccess) {
    return <SuccessMessage />;
  }

  return (
    <Stack direction="column" alignItems="center">
      <Logo />
      <Div sx={styles.formWrapper}>
        <Typography variant="h1" sx={styles.title}>
          {t('auth.restorePassword.title')}
        </Typography>
        <Typography variant="body1" sx={styles.subtitle}>
          {t('auth.restorePassword.subtitle')}
        </Typography>
        <FormProvider {...methods}>
          <Form noValidate onSubmit={handleSubmit(onSubmit)}>
            <PasswordTextField
              field="password"
              variant="outlined"
              label={t('auth.restorePassword.form.password.label')}
              placeholder={t('auth.restorePassword.form.password.placeholder')}
              helperText={errors.password?.message}
              sx={styles.input}
              fullWidth
            />
            <PasswordTextField
              field="confirmPassword"
              variant="outlined"
              label={t('auth.restorePassword.form.confirmPassword.label')}
              placeholder={t(
                'auth.restorePassword.form.confirmPassword.placeholder',
              )}
              helperText={errors.confirmPassword?.message}
              sx={styles.input}
              fullWidth
            />
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isLoading}
              fullWidth
              sx={styles.submitButton}
            >
              {t('auth.restorePassword.form.submit')}
            </LoadingButton>
          </Form>
        </FormProvider>
      </Div>
    </Stack>
  );
};
