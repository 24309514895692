import { ComponentsProps } from '@mui/material';

// override theme - working, but types inside Mui Props is empty
interface IMuiTabPanel {
  defaultProps: ComponentsProps;
  styleOverrides: Record<string, unknown>;
}

export const MuiTabPanel = {
  defaultProps: {},
  styleOverrides: {
    root: {
      padding: '0',
    },
  },
} satisfies IMuiTabPanel;
