export enum UserErrorsType {
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS',
  CANNOT_CREATE_USER = 'CANNOT_CREATE_USER',
  CANNOT_UPDATE_USER = 'CANNOT_UPDATE_USER',
  USER_ALREADY_CONFIRMED = 'USER_ALREADY_CONFIRMED',
  EMAIL_IS_NOT_CONFIRMED = 'EMAIL_IS_NOT_CONFIRMED',
  CANNOT_GET_RESET_PASSWORD_TOKEN = 'CANNOT_GET_RESET_PASSWORD_TOKEN',
  CANNOT_CREATE_RESET_PASSWORD_TOKEN = 'CANNOT_CREATE_RESET_PASSWORD_TOKEN',
  RESET_PASSWORD_TOKEN_NOT_FOUND = 'RESET_PASSWORD_TOKEN_NOT_FOUND',
  CANNOT_SEND_RESET_PASSWORD_EMAIL = 'CANNOT_SEND_RESET_PASSWORD_EMAIL',
}
