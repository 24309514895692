import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  LegalDetailsKeys,
  LegalDetailsPreparatorVariantsKey,
  StaticPropertyKey,
  YesNoVariantsKey,
} from '@bvi/api-interfaces/entity/case-property';
import { Div } from '@bvi/common-components';
import { useFormOptions } from '@bvi/form-utils';

import { ICaseFormData } from '../../../lib/types';
import { CasesFormAutocomplete } from '../form-autocomplete';
import { styles } from '../styles';

export const LegalDetails = () => {
  const { t } = useTranslation('translation');

  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<ICaseFormData>();

  const preparatorCheckboxes = useFormOptions(
    LegalDetailsPreparatorVariantsKey,
    'perpetrator',
  );

  return (
    <Div>
      <Typography variant="body1" sx={styles.title}>
        {t('cases.form.legalDetails.title')}
      </Typography>
      <Grid container spacing={2} my={1}>
        <Grid item xs={4}>
          <FormControl>
            <InputLabel>
              {t('cases.form.legalDetails.lawsuitFiled.label')}
            </InputLabel>
            <Controller
              name={LegalDetailsKeys.LAWSUIT_FILED}
              control={control}
              render={({ field }) => (
                <RadioGroup {...field} row sx={styles.radioGroup}>
                  <FormControlLabel
                    value={YesNoVariantsKey.YES}
                    control={<Radio />}
                    label={t('agreementValues.yes')}
                  />
                  <FormControlLabel
                    value={YesNoVariantsKey.NO}
                    control={<Radio />}
                    label={t('agreementValues.no')}
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={8}>
          <FormControl fullWidth>
            <InputLabel>
              {t('cases.form.legalDetails.hasClaimantRetainedAnAttorney.label')}
            </InputLabel>
            <Controller
              name={LegalDetailsKeys.HAS_ATTORNEY}
              control={control}
              render={({ field }) => (
                <RadioGroup {...field} row sx={styles.radioGroup}>
                  <FormControlLabel
                    value={YesNoVariantsKey.YES}
                    control={<Radio />}
                    label={t('agreementValues.yes')}
                  />
                  <FormControlLabel
                    value={YesNoVariantsKey.NO}
                    control={<Radio />}
                    label={t('agreementValues.no')}
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2} my={1}>
        <Grid item xs={6}>
          <CasesFormAutocomplete
            name={StaticPropertyKey.LIABILITY_DEFENSES}
            field={StaticPropertyKey.LIABILITY_DEFENSES}
            variant="filled"
            label={t('cases.form.legalDetails.liabilityDefense.label')}
            placeholder={t(
              'cases.form.legalDetails.liabilityDefense.placeholder',
            )}
            multiple
          />
        </Grid>
        <Grid item xs={6}>
          <CasesFormAutocomplete
            name={StaticPropertyKey.EXCEPTION}
            field={StaticPropertyKey.EXCEPTION}
            variant="filled"
            label={t('cases.form.legalDetails.exceptions.label')}
            placeholder={t('cases.form.legalDetails.exceptions.placeholder')}
          />
        </Grid>
      </Grid>
      <Div
        sx={{
          mt: 4,
        }}
      >
        <InputLabel>
          {t('cases.form.legalDetails.perpetrator.label')}
        </InputLabel>
      </Div>
      <Grid container mt={0}>
        <Controller
          name={LegalDetailsKeys.PREPARATOR}
          control={control}
          render={({ field }) => (
            <RadioGroup {...field} row>
              {preparatorCheckboxes.map((preparator) => (
                <Grid item xs={6} key={preparator.id}>
                  <FormControlLabel
                    value={preparator.id}
                    control={<Radio />}
                    label={preparator.name}
                  />
                </Grid>
              ))}
            </RadioGroup>
          )}
        />
      </Grid>
      <Grid container spacing={2} my={1}>
        <Grid item xs={12}>
          <TextField
            {...register('description')}
            variant="filled"
            fullWidth
            multiline
            minRows={3}
            label={t('cases.form.legalDetails.description.label')}
            error={Boolean(errors.description)}
            helperText={<>{errors.description?.message}</>}
          />
        </Grid>
      </Grid>
    </Div>
  );
};
