import { createSelector } from '@reduxjs/toolkit';

import { BaseResponse } from '@bvi/api-interfaces/response/base';
import { ICaseStatisticsData } from '@bvi/api-interfaces/response/case';
import { ISettingsData } from '@bvi/api-interfaces/response/case-settings';
import { IGetSettingsQuery } from '@bvi/cases-search';

import { searchApi } from './api-slice';

export const selectStatistic = (data?: BaseResponse<ICaseStatisticsData>) => {
  if (!data?.success) {
    return {} as ICaseStatisticsData;
  }

  return data.payload.data;
};

export const selectSettings = (data?: BaseResponse<ISettingsData>) => {
  if (!data?.success) {
    return {} as ISettingsData;
  }

  return data.payload.data;
};

export const selectCasesStatisticsResult = (
  parameters: Record<string, unknown>,
) => searchApi.endpoints.getStatistics.select(parameters);

export const selectCasesSettingsResult = (parameters: IGetSettingsQuery) => {
  return searchApi.endpoints.getSettings.select(parameters);
};

export const selectStatisticsData = (parameters: Record<string, unknown>) =>
  createSelector(selectCasesStatisticsResult(parameters), ({ data }) => {
    if (!data?.success) {
      return {} as ICaseStatisticsData;
    }

    return data.payload.data;
  });

export const selectCasesCountries = (parameters: IGetSettingsQuery) =>
  createSelector(selectCasesSettingsResult(parameters), ({ data }) => {
    if (!data?.success) {
      return {} as ISettingsData['country'];
    }

    return data.payload.data.country;
  });

export const selectCasesRegions = (parameters: IGetSettingsQuery) =>
  createSelector(selectCasesSettingsResult(parameters), ({ data }) => {
    if (!data?.success) {
      return {} as ISettingsData['region'];
    }

    return data.payload.data.region;
  });

export const selectCasesStates = (parameters: IGetSettingsQuery) =>
  createSelector(selectCasesSettingsResult(parameters), ({ data }) => {
    if (!data?.success) {
      return {} as ISettingsData['state'];
    }

    return data.payload.data.state;
  });

export const selectSettingsData = (parameters: IGetSettingsQuery) =>
  createSelector(selectCasesSettingsResult(parameters), ({ data }) => {
    if (!data?.success) {
      return {} as ISettingsData;
    }

    return data.payload.data;
  });
