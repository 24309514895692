import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { Button, Link, Stack, TextField, Typography } from '@mui/material';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { isServerError } from '@bvi/axios-query';
import { Div, Form, Logo } from '@bvi/common-components';
import { useSendResetPasswordEmailMutation } from '@bvi/dashboard/entities/user-auth/api-slice';
import { AuthRoutesPaths } from '@bvi/dashboard/shared/constants/routes';
import { NotistackMessageVariants, useNotistack } from '@bvi/notistack';

import { buildValidationSchema } from './schema';
import { styles } from './styles';
import { SuccessMessage } from './success-messsage';
import { IResetPasswordFormData } from './types';

const ResetPasswordForm: React.FC = () => {
  const { t } = useTranslation();
  const validationSchema = buildValidationSchema(t);
  const methods = useForm<IResetPasswordFormData>({
    resolver: zodResolver(validationSchema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = methods;

  const [sendResetPasswordEmail, { isLoading, isSuccess, error }] =
    useSendResetPasswordEmailMutation();

  const { showNotificationMessage } = useNotistack();

  useEffect(() => {
    if (isServerError(error)) {
      showNotificationMessage(
        { type: error.type },
        NotistackMessageVariants.ERROR,
      );
    }
  }, [error, showNotificationMessage]);

  const onSubmit = async (data: IResetPasswordFormData) => {
    await sendResetPasswordEmail(data);
  };

  if (isSuccess) {
    const currentEmail = getValues('email');
    return (
      <div>
        <SuccessMessage email={currentEmail} />
      </div>
    );
  }

  return (
    <Stack direction="column" alignItems="center">
      <Logo />
      <Div sx={styles.formWrapper}>
        <Typography variant="h1" sx={styles.title}>
          {t('auth.resetPassword.title')}
        </Typography>
        <Typography variant="body1" sx={styles.subtitle}>
          {t('auth.resetPassword.subtitle')}
        </Typography>
        <FormProvider {...methods}>
          <Form noValidate onSubmit={handleSubmit(onSubmit)}>
            <TextField
              {...register('email')}
              variant="outlined"
              label={t('auth.resetPassword.form.email.label')}
              placeholder={t('auth.resetPassword.form.email.placeholder')}
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
              sx={styles.input}
              fullWidth
            />
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isLoading}
              fullWidth
            >
              {t('auth.resetPassword.form.submit')}
            </LoadingButton>
            <Button
              fullWidth
              variant="text"
              LinkComponent={Link}
              href={AuthRoutesPaths.SIGN_IN}
              sx={styles.backButton}
            >
              {t('auth.resetPassword.backButton')}
            </Button>
          </Form>
        </FormProvider>
      </Div>
    </Stack>
  );
};

export default ResetPasswordForm;
